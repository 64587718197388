import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import NavBar from './NavBar';
import SideBar from './SideBar';
import Footer from './Footer';
import { cn } from '../../../lib/utils';
import { usePermissionCheck } from '../../guards/PermissionGuard';

const Layout = ({ children }) => {
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const [breadcrumbs, setBreadcrumbs] = useState([]);
  const location = useLocation();
  const { checkPermission } = usePermissionCheck();

  useEffect(() => {
    checkPermission(location.pathname);
  }, [location.pathname]);

  // Check if current route is chat
  const isChat = location.pathname === '/chat';

  useEffect(() => {
    const breadcrumb = JSON.parse(localStorage.getItem('breadcrumb'));
    if (breadcrumb) {
      setBreadcrumbs([breadcrumb]);
    }
  }, []);

  const updateBreadcrumbs = (newCrumb) => {
    setBreadcrumbs([newCrumb]);
    if (newCrumb.label === "Dashboard") {
      newCrumb.label = "";
      newCrumb.path = "";
    }
    localStorage.setItem('breadcrumb', JSON.stringify(newCrumb));
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  return (
    <div className="flex flex-col min-h-screen">
      <NavBar 
        toggleSidebar={toggleSidebar} 
        breadcrumbs={breadcrumbs}
        updateBreadcrumbs={updateBreadcrumbs}
      />
      <div className="flex flex-1">
        <SideBar
          isOpen={isSidebarOpen}
          toggleSidebar={toggleSidebar}
          updateBreadcrumbs={updateBreadcrumbs}
        />
        <div 
          className={cn(
            "w-full lg:ml-[16rem] bg-slate-50 overflow-scroll",
            isChat ? "h-[calc(100vh-4rem)]" : "md:h-[89vh] max-sm:h-[100vh]"
          )}
        >
          {children}
        </div>
      </div>
      {!isChat && <Footer />}
    </div>
  );
};

export default Layout;