import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { fetchCurrencies, addPreventivi, fetchClients, fetchUm } from '../../../apis/PreventiviAdd';
import { toast } from "react-toastify";

import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { ChevronDown, ChevronUp } from 'lucide-react';

function DetailComponent() {
  const user = JSON.parse(localStorage.getItem("user"));
  const companyId = user?.companyId;
  const [currencies, setCurrencies] = useState([]);
  const [clients, setClients] = useState([]);
  const [ums, setUms] = useState([]);
  const [formData, setFormData] = useState({
    dataa: '',
    numero: '',
    stato: '',
    clienti: '',
    valuta: '',
    validita: '',
    ogguto: '',
    companyId: companyId,
    articoli: [
      {
        codice: '',
        nome: '',
        descrizione: '',
        um: '',
        qita: '',
        iva: '22',
        sconto: '',
        importonetto: '',
        importo: ''
      }
    ],
    prodottival: '',
    ivaval: '',
    totaleval: ''
  });
  const [openSection, setOpenSection] = useState(0);


  const navigate = useNavigate();

  useEffect(() => {
    const getData = async () => {
      try {
        const currencyData = await fetchCurrencies();
        setCurrencies(currencyData.valuta);

        const umData = await fetchUm();
        setUms(umData?.um || []);

        const clientsData = await fetchClients(companyId);
        setClients(clientsData?.data.clients || []);
      } catch (error) {
        console.error('There was an error fetching the data!', error);
      }
    };

    getData();
  }, [companyId]);

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData(prev => ({ ...prev, [name]: value }));
  };

  const handleArticoliChange = (index, field, value) => {
    setFormData(prev => {
      const newArticoli = [...prev.articoli];
      const articolo = { ...newArticoli[index], [field]: value };

      // Base calculations
      const quantity = parseFloat(articolo.qita) || 0;
      const unitPrice = parseFloat(articolo.importonetto) || 0;
      const discount = parseFloat(articolo.sconto) || 0;
      const iva = parseFloat(articolo.iva) || 0;

      // Base amount
      const baseAmount = quantity * unitPrice;
      const discountAmount = (baseAmount * discount) / 100;
      const amountAfterDiscount = baseAmount - discountAmount;

      // Calculate VAT amount directly
      const ivaAmount = (amountAfterDiscount * iva) / 100;
      const totalAmount = amountAfterDiscount + ivaAmount;

      articolo.importo = totalAmount.toFixed(2);
      newArticoli[index] = articolo;

      // Sum up totals
      const totals = newArticoli.reduce((acc, curr) => {
        const baseAmount = parseFloat(curr.qita || 0) * parseFloat(curr.importonetto || 0);
        const discount = (baseAmount * parseFloat(curr.sconto || 0)) / 100;
        const amountAfterDiscount = baseAmount - discount;
        const ivaAmount = (amountAfterDiscount * parseFloat(curr.iva || 0)) / 100;

        return {
          prodottival: acc.prodottival + amountAfterDiscount,
          ivaval: acc.ivaval + ivaAmount
        };
      }, { prodottival: 0, ivaval: 0 });

      return {
        ...prev,
        articoli: newArticoli,
        prodottival: totals.prodottival.toFixed(2),
        ivaval: totals.ivaval.toFixed(2),
        totaleval: (totals.prodottival + totals.ivaval).toFixed(2)
      };
    });
  };


  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await addPreventivi(formData);
      if (response.status === 200) {
        toast.success("Data saved successfully");
        navigate(-1);
      }
    } catch (error) {
      console.error('There was an error submitting the form!', error);
      toast.error("There was an error submitting the form!");
    }
  };

  const handleDuplicate = (index) => {
    setFormData(prev => ({
      ...prev,
      articoli: [...prev.articoli, { ...prev.articoli[index] }],
    }));
    setOpenSection(formData.articoli.length);
  };

  const handleDelete = (indexToDelete) => {
    if (formData.articoli.length <= 1) return; // Prevent deletion if only one item remains
    setFormData(prev => ({
      ...prev,
      articoli: prev.articoli.filter((_, index) => index !== indexToDelete)
    }));
  };

  const toggleSection = (section) => {
    setOpenSection(openSection === section ? null : section);
  };

  return (
    <form className="p-6 space-y-6" onSubmit={handleSubmit}>

      <Card className="bg-white">
        <CardHeader>
          <CardTitle>Dati del documento</CardTitle>
        </CardHeader>
        <CardContent className="space-y-4">
          <div className="grid grid-cols-3 gap-4">
            <div className="space-y-2">
              <Label htmlFor="dataa">Data</Label>
              <Input type="date" id="dataa" name="dataa" value={formData.dataa} onChange={handleChange} required />
            </div>
            <div className="space-y-2">
              <Label htmlFor="numero">Numero</Label>
              <Input
                id="numero"
                name="numero"
                value={formData.numero}
                readOnly
                disabled
                className="bg-gray-100"
              />
            </div>

            <div className="space-y-2">
              <Label htmlFor="stato">Stato</Label>
              <Select name="stato" value={formData.stato} onValueChange={(value) => handleChange({ target: { name: 'stato', value } })}>
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="Inviato">Inviato</SelectItem>
                  <SelectItem value="In corso">In corso</SelectItem>
                  <SelectItem value="Accettato">Accettato</SelectItem>
                  <SelectItem value="Rifiutato">Rifiutato</SelectItem>
                </SelectContent>
              </Select>
            </div>
          </div>
          <div className="grid grid-cols-3 gap-4">
            <div className="space-y-2">
              <Label htmlFor="clienti">Cliente</Label>
              <Select name="clienti" value={formData.clienti} onValueChange={(value) => handleChange({ target: { name: 'clienti', value } })} required>
                <SelectTrigger>
                  <SelectValue placeholder="Scegli Cliente" />
                </SelectTrigger>
                <SelectContent>
                  {clients.map(client => (
                    <SelectItem key={client._id} value={client._id}>
                      {client.typology === 'Private' ? client.fullName : client.companyName}
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>

            </div>
            <div className="space-y-2">
              <Label htmlFor="valuta">Valuta</Label>
              <Select name="valuta" value={formData.valuta} onValueChange={(value) => handleChange({ target: { name: 'valuta', value } })} required>
                <SelectTrigger>
                  <SelectValue placeholder="Seleziona Valuta" />
                </SelectTrigger>
                <SelectContent>
                  {currencies.map(currency => (
                    <SelectItem key={currency.valuta} value={currency.valuta}>{currency.valuta}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </div>
            <div className="space-y-2">
              <Label htmlFor="validita">Validità</Label>
              <Input type="date" id="validita" name="validita" value={formData.validita} onChange={handleChange} required />
            </div>
          </div>
          <div className="space-y-2">
            <Label htmlFor="ogguto">Oggetto</Label>
            <Textarea id="ogguto" name="ogguto" value={formData.ogguto} onChange={handleChange} required />
          </div>
        </CardContent>
      </Card>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-6 items-start">
        <Card className="bg-white md:col-span-2">
          <CardHeader>
            <CardTitle>Lista degli articoli</CardTitle>
          </CardHeader>
          <CardContent>
            {formData.articoli.map((articolo, index) => (
              <div key={index} className="mb-6 pb-6 border-b">
                <div
                  className="flex justify-between items-center cursor-pointer"
                  onClick={() => toggleSection(index)}
                >
                  <div className="flex items-center space-x-4">
                    <span className="font-medium">
                      {articolo.nome || articolo.codice || `Articolo ${index + 1}`}
                    </span>
                    <span className="text-sm text-gray-500">
                      {articolo.um ? `${articolo.qita} ${articolo.um}` : ''}
                    </span>
                  </div>
                  <div className="flex items-center space-x-4">
                    <span className="font-medium">€ {articolo.importo || '0.00'}</span>
                    {openSection === index ? (
                      <ChevronUp className="h-4 w-4" />
                    ) : (
                      <ChevronDown className="h-4 w-4" />
                    )}
                  </div>
                </div>

                {openSection === index && (
                  <div className="mt-4">
                    <div className="grid grid-cols-2 gap-4 mb-4">
                      <div className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor={`codice-${index}`}>Codice</Label>
                            <Input
                              id={`codice-${index}`}
                              value={articolo.codice}
                              onChange={(e) => handleArticoliChange(index, 'codice', e.target.value)}
                            />
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor={`nome-${index}`}>Nome</Label>
                            <Input
                              id={`nome-${index}`}
                              value={articolo.nome}
                              onChange={(e) => handleArticoliChange(index, 'nome', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="space-y-2">
                          <Label htmlFor={`descrizione-${index}`}>Descrizione</Label>
                          <Textarea
                            id={`descrizione-${index}`}
                            value={articolo.descrizione}
                            onChange={(e) => handleArticoliChange(index, 'descrizione', e.target.value)}
                          />
                        </div>
                      </div>

                      <div className="space-y-4">
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor={`um-${index}`}>U.M.</Label>
                            <Select
                              value={articolo.um}
                              onValueChange={(value) => handleArticoliChange(index, 'um', value)}
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Seleziona U.M." />
                              </SelectTrigger>
                              <SelectContent>
                                {ums && ums.map(unit => (
                                  <SelectItem key={unit.unit} value={unit.unit}>{unit.unit}</SelectItem>
                                ))}
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor={`qita-${index}`}>Q.ità</Label>
                            <Input
                              id={`qita-${index}`}
                              value={articolo.qita}
                              onChange={(e) => handleArticoliChange(index, 'qita', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor={`iva-${index}`}>IVA</Label>
                            <Select
                              value={articolo.iva}
                              onValueChange={(value) => handleArticoliChange(index, 'iva', value)}
                              required
                            >
                              <SelectTrigger>
                                <SelectValue placeholder="Seleziona IVA" />
                              </SelectTrigger>
                              <SelectContent>
                                <SelectItem value="22">22%</SelectItem>
                                <SelectItem value="21">21%</SelectItem>
                                <SelectItem value="20">20%</SelectItem>
                                <SelectItem value="10">10%</SelectItem>
                                <SelectItem value="4">4%</SelectItem>
                                <SelectItem value="0">0%</SelectItem>
                              </SelectContent>
                            </Select>
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor={`sconto-${index}`}>Sconto</Label>
                            <Input
                              id={`sconto-${index}`}
                              value={articolo.sconto}
                              onChange={(e) => handleArticoliChange(index, 'sconto', e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="grid grid-cols-2 gap-4">
                          <div className="space-y-2">
                            <Label htmlFor={`importonetto-${index}`}>Importo netto</Label>
                            <Input
                              id={`importonetto-${index}`}
                              value={articolo.importonetto}
                              onChange={(e) => handleArticoliChange(index, 'importonetto', e.target.value)}
                            />
                          </div>
                          <div className="space-y-2">
                            <Label htmlFor={`importo-${index}`}>Importo</Label>
                            <Input
                              id={`importo-${index}`}
                              value={articolo.importo}
                              onChange={(e) => handleArticoliChange(index, 'importo', e.target.value)}
                              readOnly
                            />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div className="flex justify-end space-x-2">
                      {formData.articoli.length > 1 && (
                        <Button
                          type="button"
                          onClick={() => handleDelete(index)}
                          variant="destructive"
                        >
                          Elimina
                        </Button>
                      )}
                      <Button
                        type="button"
                        onClick={() => handleDuplicate(index)}
                      >
                        Aggiungi
                      </Button>
                    </div>
                  </div>
                )}
              </div>
            ))}
          </CardContent>
        </Card>

        <div className="flex flex-col space-y-2">
          <Card className="bg-white">
            <CardHeader>
              <CardTitle>Riepilogo</CardTitle>
            </CardHeader>
            <CardContent className="space-y-2">
              <div className="flex justify-start gap-x-2">
                <span>Prodotti e/o Servizi</span>
                <span className="text-xl">€ {formData.prodottival || ''}</span>
              </div>
              <div className="flex justify-start gap-x-2">
                <span>IVA</span>
                <span className="text-xl">€ {formData.ivaval || ''}</span>
              </div>
              <div className="flex justify-start gap-x-2">
                <span className="font-bold">Totale</span>
                <span className="text-xl font-bold">€ {formData.totaleval || ''}</span>
              </div>
            </CardContent>
          </Card>
          <div className="flex justify-end w-full">
            <Button type="submit">Salva</Button>
          </div>
        </div>

      </div>

      <div className="flex justify-end">
        <input type="hidden" id="ProdottiSpaninput" name="prodottival" value={formData.prodottival} />
        <input type="hidden" id="ivaSpaninput" name="ivaval" value={formData.ivaval} />
        <input type="hidden" id="totaleSpaninput" name="totaleval" value={formData.totaleval} />

      </div>
    </form>
  );
}

export default DetailComponent;