import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import SupplierDetailsComponent from "../../../components/admin/personalData/supplier/supplierDetails"

const SupplierDetails = () => {
  return (
    <Layout><SupplierDetailsComponent /></Layout>
  )
}

export default SupplierDetails