import React, { useEffect, useState, useRef } from "react";
import { ChevronLeft, ChevronRight } from "lucide-react";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { format } from "date-fns";
import { Input } from "../../../../ui/input";
import { Button } from "../../../../ui/button";
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter } from "../../../../ui/dialog";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../../ui/select";
import { Label } from "../../../../ui/label";
import {
  getCompanyEmployeePlannings,
  updateCompanyEmployeePlannings,
  deleteCompanyEmployeePlannings,
  getCompanyPlans,
} from "../../../../../apis/Planning";
import SearchBar from "../../../sharedComponent/SearchBar";
import HelpSheet from "../../../sharedComponent/HelpSheet";
import EmployeeSelectionDialog from '../attendanceSheet/SelectEmployeeModal';
import { getEmployeeGroups } from "../../../../../apis/EmployeeGroups";

const Planning = () => {
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [planningData, setPlanningData] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isPlanningModalOpen, setPlanningModalOpen] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const [companyPlans, setCompanyPlans] = useState([]);
  const componentRef = useRef();
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [isSelectEmployeeDialogOpen, setSelectEmployeeDialogOpen] = useState(false);
  const [groups, setGroups] = useState([]);
  const hasInitialized = useRef(false);
  const [allEmployees, setAllEmployees] = useState([]);

  // Fetch groups once on component mount
  useEffect(() => {
    const fetchGroups = async () => {
      try {
        const response = await getEmployeeGroups(JSON.parse(localStorage.getItem("user")).companyId);
        setGroups(response.data.groups);
      } catch (error) {
        console.error('Error fetching groups:', error);
        toast.error('Unable to fetch employee groups');
      }
    };
    fetchGroups();
  }, []);

  // Fetch company plans once on component mount
  useEffect(() => {
    const fetchCompanyPlans = async () => {
      try {
        const response = await getCompanyPlans();
        if (response.status === 200) {
          setCompanyPlans(response.data.plans);
        }
      } catch (error) {
        console.error("Unable to fetch company plans", error);
        toast.error("Unable to fetch company plans");
      }
    };
    fetchCompanyPlans();
  }, []);

  // Fetch employee plannings when month or search query changes
  useEffect(() => {
    const getEmployeePlannings = async () => {
      try {
        const monthQuery = format(selectedMonth, "yyyy-MM");
        const response = await getCompanyEmployeePlannings(monthQuery, searchQuery);
        if (response.status === 200) {
          const employees = response.data?.employees || [];
          setAllEmployees(employees);
          
          // Initialize selected employees only once when data first arrives
          if (!hasInitialized.current && employees.length > 0) {
            setSelectedEmployees(employees.map(emp => emp._id));
            hasInitialized.current = true;
          }
          
          // Filter planning data based on selected employees
          const filtered = employees.filter(emp => 
            selectedEmployees.length === 0 || selectedEmployees.includes(emp._id)
          ).filter(emp =>
            searchQuery ? (
              emp.firstName.toLowerCase().includes(searchQuery.toLowerCase()) ||
              emp.lastName.toLowerCase().includes(searchQuery.toLowerCase())
            ) : true
          );
          setPlanningData(filtered);
        }
      } catch (error) {
        console.error("Unable to fetch planning data", error);
        toast.error("Unable to fetch planning data");
      }
    };

    getEmployeePlannings();
  }, [selectedMonth, searchQuery, selectedEmployees]);

  const handleEmployeeSelect = (newSelectedEmployees) => {
    setSelectedEmployees(newSelectedEmployees || []);
  };

  const handleMonthChange = (e) => {
    setSelectedMonth(new Date(e.target.value));
  };

  const incrementMonth = () => {
    setSelectedMonth(prev => new Date(prev.setMonth(prev.getMonth() + 1)));
  };

  const decrementMonth = () => {
    setSelectedMonth(prev => new Date(prev.setMonth(prev.getMonth() - 1)));
  };

  const getDaysInMonth = (year, month) => {
    return new Date(year, month + 1, 0).getDate();
  };

  const daysInMonth = getDaysInMonth(
    selectedMonth.getFullYear(),
    selectedMonth.getMonth()
  );

  const openPlanningModal = (employee, date) => {
    const formattedDate = new Date(
      selectedMonth.getFullYear(),
      selectedMonth.getMonth(),
      date
    ).toISOString().substring(0, 10);

    setSelectedEmployee(employee);
    setSelectedDate(formattedDate);
    setPlanningModalOpen(true);
  };

  const handlePlanningSubmit = async (planId) => {
    try {
      const response = await updateCompanyEmployeePlannings({
        employeeId: selectedEmployee._id,
        date: selectedDate,
        planId: planId,
      });
      if (response.status === 200) {
        toast.success("Planning updated successfully");
        // Update the local state to reflect the change
        setPlanningData(prevData =>
          prevData.map(emp =>
            emp._id === selectedEmployee._id
              ? {
                ...emp,
                plannings: [
                  ...emp.plannings.filter(p => p.date !== selectedDate),
                  { date: selectedDate, planId: companyPlans.find(p => p._id === planId) }
                ]
              }
              : emp
          )
        );
      }
    } catch (error) {
      console.error(error);
      toast.error("Unable to update planning");
    }
    setPlanningModalOpen(false);
  };

  const handlePlanningDelete = async (planningId) => {
    try {
      const response = await deleteCompanyEmployeePlannings(planningId);
      if (response.status === 200) {
        toast.success("Planning deleted successfully");
        const monthQuery = format(selectedMonth, "yyyy-MM");
        const response = await getCompanyEmployeePlannings(monthQuery, searchQuery);
        if (response.status === 200) {
          const filtered = response.data.employees.filter(emp => 
            selectedEmployees.length === 0 || selectedEmployees.includes(emp._id)
          );
          setPlanningData(filtered);
        }
        setPlanningModalOpen(false);
      }
    } catch (error) {
      console.error(error);
      toast.error("Unable to delete planning");
    }
  };

  const handleSearch = (query) => {
    setSearchQuery(query);
  };

  const getDayOfWeek = (date) => {
    const daysOfWeek = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    return daysOfWeek[date.getDay()];
  };

  return (
    <div className="mx-auto space-y-4">
      <ToastContainer />
      <div className="flex flex-col md:flex-row justify-between my-2">
        <div className="flex items-center gap-2 mb-4 md:mb-0">
          <Button variant="outline" size="icon" onClick={decrementMonth}>
            <ChevronLeft className="text-lg" />
          </Button>
          <Input
            type="month"
            value={format(selectedMonth, "yyyy-MM")}
            onChange={handleMonthChange}
            className="w-full"
          />
          <Button variant="outline" size="icon" onClick={incrementMonth}>
            <ChevronRight className="text-lg" />
          </Button>
        </div>
        <div className="flex items-center gap-2">
          <Button variant="outline" onClick={() => setSelectEmployeeDialogOpen(true)}>
            Select Employees
          </Button>
          <EmployeeSelectionDialog
            isOpen={isSelectEmployeeDialogOpen}
            onClose={() => setSelectEmployeeDialogOpen(false)}
            employees={allEmployees}
            groups={groups}
            selectedEmployees={selectedEmployees}
            onSelectionChange={handleEmployeeSelect}
            onConfirm={() => setSelectEmployeeDialogOpen(false)}
          />
          <SearchBar onSearch={handleSearch} />
          <HelpSheet route="/employees" />
        </div>
      </div>

      <div
        ref={componentRef}
        className="relative overflow-x-auto shadow-md sm:rounded-lg mx-auto"
      >
        <table className="w-full text-sm text-left text-gray-500 dark:text-gray-400 border-collapse table-fixed-first-column">
          <thead className="text-xs text-gray-700 bg-gray-300 dark:bg-gray-700 dark:text-gray-400">
            <tr className="text-center">
              <th
                scope="col"
                className="px-6 py-3 border border-gray-300 bg-gray-300 sticky left-0 z-10"
              >
                Name
              </th>
              {Array.from({ length: daysInMonth }, (_, i) => {
                const currentDate = new Date(
                  selectedMonth.getFullYear(),
                  selectedMonth.getMonth(),
                  i + 1
                );
                return (
                  <th
                    key={i}
                    scope="col"
                    className="px-3 py-3 border border-gray-300 min-w-[80px]"
                  >
                    {`${i + 1}-${getDayOfWeek(currentDate)}`}
                  </th>
                );
              })}
            </tr>
          </thead>
          <tbody>
            {planningData?.map((employee) => (
              <tr
                key={employee._id}
                className="odd:bg-white text-center odd:dark:bg-gray-900 even:bg-gray-50 even:dark:bg-gray-800 border-b dark:border-gray-700"
              >
                <td className="px-6 py-4 text-center border border-gray-300 font-semibold bg-white sticky left-0 z-10">
                  {employee.firstName}
                </td>
                {Array.from({ length: daysInMonth }, (_, i) => {
                  const currentDate = new Date(
                    selectedMonth.getFullYear(),
                    selectedMonth.getMonth(),
                    i + 1
                  );

                  const planningForDate = employee?.plannings?.find((plan) => {
                    const planDate = new Date(plan.date);
                    return (
                      planDate.getDate() === currentDate.getDate() &&
                      planDate.getMonth() === currentDate.getMonth() &&
                      planDate.getFullYear() === currentDate.getFullYear()
                    );
                  });

                  const planDetails = planningForDate?.planId;

                  return (
                    <td
                      key={i}
                      className="px-3 py-4 text-center border border-gray-300 cursor-pointer min-w-[80px]"
                      style={{
                        backgroundColor: planDetails?.color || "white",
                      }}
                      onClick={() => openPlanningModal(employee, i + 1)}
                    >
                      {planDetails ? (
                        <span className="text-white font-bold">
                          {planDetails.acronym}
                        </span>
                      ) : (
                        <span>{""}</span>
                      )}
                    </td>
                  );
                })}
              </tr>
            ))}
          </tbody>
        </table>
      </div>

      <Dialog open={isPlanningModalOpen} onOpenChange={setPlanningModalOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Planning for {selectedEmployee?.firstName}</DialogTitle>
          </DialogHeader>
          <PlanningModalContent
            employee={selectedEmployee}
            date={selectedDate}
            onSubmit={handlePlanningSubmit}
            onDelete={handlePlanningDelete}
            companyPlans={companyPlans}
            initialData={selectedEmployee?.plannings?.find((plan) => plan.date === selectedDate)}
          />
        </DialogContent>
      </Dialog>
    </div>
  );
};

const PlanningModalContent = ({
  employee,
  date,
  onSubmit,
  onDelete,
  companyPlans,
  initialData,
}) => {
  const [selectedPlan, setSelectedPlan] = useState(initialData?.planId?._id || "");

  const handleSubmit = () => {
    if (selectedPlan) {
      onSubmit(selectedPlan);
    }
  };

  const handleDelete = () => {
    if (initialData?._id) {
      onDelete(initialData._id);
    }
  };

  const selectedPlanDetails = companyPlans.find(p => p._id === selectedPlan);

  return (
    <div className="space-y-4">
      <p>Date: {date}</p>
      <div className="space-y-2">
        <Label htmlFor="planSelect">Select shift</Label>
        <Select value={selectedPlan} onValueChange={setSelectedPlan}>
          <SelectTrigger id="planSelect">
            <SelectValue placeholder="Select a shift" />
          </SelectTrigger>
          <SelectContent>
            {companyPlans.map((plan) => (
              <SelectItem key={plan._id} value={plan._id}>
                {`${plan.name} (${plan.acronym}) - ${plan.workSite}`}
              </SelectItem>
            ))}
          </SelectContent>
        </Select>
      </div>

      {selectedPlanDetails && (
        <div className="space-y-2">
          <h3 className="font-bold">Plan Details:</h3>
          <p>Name: {selectedPlanDetails.name}</p>
          <p>Acronym: {selectedPlanDetails.acronym}</p>
          <p>Work Site: {selectedPlanDetails.workSite}</p>
          <p>Time: {selectedPlanDetails.startTime} - {selectedPlanDetails.endTime}</p>
          {selectedPlanDetails.isContinuous ? (
            <p>Continuous Shift</p>
          ) : (
            <p>Break: {selectedPlanDetails.breakStartTime} - {selectedPlanDetails.breakEndTime}</p>
          )}
        </div>
      )}

      <DialogFooter>
        <Button onClick={handleSubmit} disabled={!selectedPlan}>
          {initialData ? 'Update' : 'Save'}
        </Button>
        {initialData && (
          <Button variant="destructive" onClick={handleDelete}>
            Delete
          </Button>
        )}
      </DialogFooter>
    </div>
  );
};

export default Planning;