import React, { useEffect, useState } from "react";
import { Tree, TreeNode } from "react-organizational-chart";
import NodeModal from "./NodeModal";
import DeleteConfirmationModal from "./DeleteConfirmationModal";
import { Plus, Edit, Download } from "lucide-react";
import { ROOT_NODE, employeesList, designationsList, levels, levelColors } from "./config";
import { getTree, updateNode, deleteNode, createNode } from "../../../../apis/structure";
import { toast, ToastContainer } from "react-toastify";
import { Button } from "../../../ui/button";
import { Card } from "../../../ui/card";
import { exportOrgChartToPDF } from "../../../../config/helper";

const OrgChart = () => {
  const [tree, setTree] = useState(null);
  const [modalOpen, setModalOpen] = useState(false);
  const [selectedNode, setSelectedNode] = useState(null);
  const [selectedLevel, setSelectedLevel] = useState(1);
  const [employees, setEmployees] = useState(employeesList);
  const [designations, setDesignations] = useState(designationsList);
  const [isEditing, setIsEditing] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const fetchTree = async () => {
    try {
      const response = await getTree();
      if (response.status === 200) {
        setTree(response.data.tree);
      }
    } catch (error) {
      console.error("Error fetching tree:", error);
      toast.error("Failed to fetch organizational tree");
    }
  };

  useEffect(() => {
    fetchTree();
  }, []);

  const handleAddNode = (parentNode, level) => {
    setSelectedNode(parentNode);
    setSelectedLevel(level);
    setIsEditing(false);
    setModalOpen(true);
  };

  const handleEditNode = (node, level) => {
    setSelectedNode(node);
    setSelectedLevel(level);
    setIsEditing(true);
    setModalOpen(true);
  };

  const handleSaveNode = async (node) => {
    try {
      const response = await createNode(node);
      if (response.status === 201) {
        setTree(response.data);
        setModalOpen(false);
        toast.success("Node created successfully");
        fetchTree();
      }
    } catch (error) {
      console.error("Error creating node:", error);
      toast.error("Error creating node");
    }
  };

  const handleUpdateNode = async (oldNode, newNode) => {
    try {
      const response = await updateNode(oldNode._id, newNode);
      if (response.status === 200) {
        setTree(response.data);
        setModalOpen(false);
        toast.success("Node updated successfully");
        fetchTree();
      }
    } catch (error) {
      console.error("Error updating node:", error);
      toast.error("Error updating node");
    }
  };

  const handleDeleteNode = async () => {
    try {
      const response = await deleteNode(selectedNode._id);
      if (response.status === 200) {
        setTree(response.data);
        setIsDeleteModalOpen(false);
        toast.success("Node deleted successfully");
        fetchTree();
      }
    } catch (error) {
      console.error("Error deleting node:", error);
      toast.error("Error deleting node");
    }
  };

  const handleExport = async () => {
    try {
      await exportOrgChartToPDF(tree);
    } catch (error) {
      console.error("Error exporting organization chart:", error);
      toast.error("Errore durante l'esportazione dell'organigramma");
    }
  };

  const renderTreeNodes = (nodes, level = 1) => {
    return nodes?.map((node, indx) => (
      <TreeNode
        key={indx}
        label={
          <Card className={`relative cursor-pointer p-2 w-full min-w-[70px] max-w-xs h-20 ${levelColors[level]} shadow-md m-auto group`}>
            <div className="flex justify-center items-center h-full w-full text-center">
              {node?.name}
              <Button
                size="sm"
                variant="ghost"
                className="absolute top-0 right-0 transform translate-x-2 -translate-y-2 z-10"
                onClick={(e) => {
                  e.stopPropagation();
                  handleAddNode(node, level + 1);
                }}
              >
                <Plus className="h-4 w-4" />
              </Button>
              <div className="absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-25 opacity-0 group-hover:opacity-100 transition duration-300">
                <Edit
                  className="text-white h-6 w-6 cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    handleEditNode(node, level);
                  }}
                />
              </div>
            </div>
          </Card>
        }
      >
        {node.children && renderTreeNodes(node.children, level + 1)}
      </TreeNode>
    ));
  };

  const handleAddNewEmployee = async () => {
    try {
      setSelectedNode(null);
      setSelectedLevel(1);
      setIsEditing(false);
      setModalOpen(true);
    } catch (error) {
      console.error("Error adding new employee:", error);
      toast.error("Error adding new employee");
    }
  };

  return (
    <div className="container mx-auto my-10 relative">
      <ToastContainer />
      <div className="absolute top-0 right-0 m-4 flex gap-2">
        {tree && (
          <Button
            variant="outline"
            className="flex items-center gap-2"
            onClick={handleExport}
          >
            Esporta
          </Button>
        )}
        {!tree && (
          <Button
            size="icon"
            className="rounded-full"
            onClick={handleAddNewEmployee}
          >
            <Plus className="h-4 w-4" />
          </Button>
        )}
      </div>
      <h1 className="text-2xl font-bold text-center mb-10">
        Organigramma aziendale
      </h1>
      <div className="flex justify-center">
        {tree && tree.designation ? (
          <Tree
            lineWidth="2px"
            lineColor="black"
            lineBorderRadius="10px"
            label={
              <Card className="relative p-2 w-full max-w-xs h-20 bg-white shadow-md m-auto group">
                <div className="flex justify-center items-center px-10 h-full w-full text-center">
                  {tree?.designation}
                  <Button
                    size="sm"
                    variant="ghost"
                    className="absolute top-0 right-0 transform translate-x-2 -translate-y-2 z-10"
                    onClick={(e) => {
                      e.stopPropagation();
                      handleAddNode(tree, 1);
                    }}
                  >
                    <Plus className="h-4 w-4" />
                  </Button>
                  <div className="absolute inset-0 flex items-center justify-center bg-gray-500 bg-opacity-25 opacity-0 group-hover:opacity-100 transition duration-300">
                    <Edit
                      className="text-white h-6 w-6 cursor-pointer"
                      onClick={(e) => {
                        e.stopPropagation();
                        handleEditNode(tree, 1);
                      }}
                    />
                  </div>
                </div>
              </Card>
            }
          >
            {renderTreeNodes(tree?.children)}
          </Tree>
        ) : (
          <div className="text-center">
            <p>
              No data available. Click the button to add the first employee.
            </p>
          </div>
        )}
      </div>
      <NodeModal
        isOpen={modalOpen}
        onClose={() => setModalOpen(false)}
        onSave={handleSaveNode}
        onUpdate={handleUpdateNode}
        selectedNode={selectedNode}
        employees={employees}
        designations={designations}
        levels={levels}
        level={selectedLevel}
        isEditing={isEditing}
        tree={tree}
        onDelete={() => {
          setModalOpen(false);
          setIsDeleteModalOpen(true);
        }}
      />
      <DeleteConfirmationModal
        isOpen={isDeleteModalOpen}
        onClose={() => setIsDeleteModalOpen(false)}
        onConfirm={handleDeleteNode}
        item={selectedNode}
      />
    </div>
  );
};

export default OrgChart;