import { createJsonTypeInstance, createMultipartInstance } from "./index";
const axios = createJsonTypeInstance();

export const getMedicalVisits = async (companyId, page = 1, limit = 10) => {
  try {
    const response = await axios.get(
      `/medical-visits/${companyId}?page=${page}&limit=${limit}`
    );
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const addMedicalVisit = async (data) => {
  try {
    const axiosInstance = createMultipartInstance();
    const visitData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "file" && data[key]) {
        visitData.append(key, data[key]);
      } else {
        visitData.append(key, data[key]);
      }
    });
    const response = await axiosInstance.post("/medical-visits", visitData);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const updateMedicalVisit = async (visitId, data) => {
  try {
    const axiosInstance = createMultipartInstance();
    const visitData = new FormData();
    Object.keys(data).forEach((key) => {
      if (key === "file" && data[key]) {
        visitData.append(key, data[key]);
      } else {
        visitData.append(key, data[key]);
      }
    });
    const response = await axiosInstance.patch(`/medical-visits/${visitId}`, visitData);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const deleteMedicalVisit = async (visitId) => {
  try {
    const response = await axios.delete(`/medical-visits/${visitId}`);
    return response;
  } catch (error) {
    throw error.response.data;
  }
};

export const getUpcomingExpirations = async (companyId, days = 30) => {
  try {
    const response = await axios.get(
      `/medical-visits/upcoming/${companyId}?days=${days}`
    );
    return response;
  } catch (error) {
    throw error.response;
  }
};