import React, { useRef } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle } from '../../ui/dialog';
import { Button } from '../../ui/button';
import { Download, Upload } from 'lucide-react';
import { toast } from 'react-toastify';
import { importMovimenti } from '../../../apis/Movimenti';

const ImportDialog = ({ open, onOpenChange, onImportComplete }) => {
  const fileInputRef = useRef(null);

  const handleFileChange = async (event) => {
    try {
      const file = event.target.files[0];
      if (!file) return;

      // Check file type
      const validTypes = [
        'application/vnd.ms-excel',
        'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        'text/csv'
      ];
      if (!validTypes.includes(file.type)) {
        toast.error('Per favore carica un file Excel o CSV');
        return;
      }

      // Check file size (max 5MB)
      if (file.size > 5 * 1024 * 1024) {
        toast.error('Il file non può superare i 5MB');
        return;
      }

      const user = JSON.parse(localStorage.getItem('user'));
      const formData = new FormData();
      formData.append('file', file);
      formData.append('companyId', user.companyId);

      await importMovimenti(formData);

      toast.success('File importato con successo');
      onOpenChange(false);
      if (onImportComplete) onImportComplete();

      // Reset file input
      event.target.value = '';
    } catch (error) {
      console.error('Error importing file:', error);
      toast.error(error.response?.data?.message || 'Errore durante l\'importazione del file');
    }
  };

  const handleDragOver = (e) => {
    e.preventDefault();
    e.stopPropagation();
  };

  const handleDrop = (e) => {
    e.preventDefault();
    e.stopPropagation();

    const file = e.dataTransfer.files[0];
    if (file) {
      const dataTransfer = new DataTransfer();
      dataTransfer.items.add(file);
      fileInputRef.current.files = dataTransfer.files;
      fileInputRef.current.dispatchEvent(new Event('change', { bubbles: true }));
    }
  };

  const downloadSample = () => {
    const sampleData = [
      ['Data', 'Nome', 'Importo', 'Riferimento', 'Entra/Uscita'],
      ['2024-01-01', 'Esempio Movimento', '1000.00', 'Fattura 123', 'Entra'],
      ['2024-01-02', 'Altro Movimento', '500.00', 'Ricevuta 456', 'Uscita']
    ];

    const csvContent = sampleData.map(row => row.join(',')).join('\n');
    const blob = new Blob([csvContent], { type: 'text/csv' });
    const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = url;
    link.download = 'template_importazione_movimenti.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
    window.URL.revokeObjectURL(url);
  };

  return (
    <Dialog open={open} onOpenChange={onOpenChange}>
      <DialogContent className="sm:max-w-md">
        <DialogHeader>
          <DialogTitle>Importa Movimenti</DialogTitle>
        </DialogHeader>
        <div className="space-y-4">
          <div
            className="border-2 border-dashed rounded-lg p-8 text-center cursor-pointer transition-colors hover:border-primary/50"
            onClick={() => fileInputRef.current?.click()}
            onDragOver={handleDragOver}
            onDrop={handleDrop}
          >
            <input
              ref={fileInputRef}
              type="file"
              accept=".xlsx,.xls,.csv"
              onChange={handleFileChange}
              className="hidden"
            />
            <Upload className="mx-auto h-12 w-12 text-gray-400" />
            <p className="mt-2 font-medium">
              Trascina qui il file o clicca per selezionarlo
            </p>
            <p className="mt-1 text-sm text-gray-500">
              Supporta file .xlsx, .xls, .csv (max 5MB)
            </p>
          </div>

          <Button
            onClick={downloadSample}
            variant="outline"
            className="w-full"
          >
            <Download className="mr-2 h-4 w-4" />
            Scarica template
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default ImportDialog;