import { BrowserRouter, Routes, Route, Navigate } from "react-router-dom";
import Login from "./screens/auth/Login";
import Register from "./screens/auth/Register";
import ForgetPassword from "./screens/auth/ForgetPassword";
import RequestSuccess from "./screens/auth/RequestSuccess";
import DashBoardScreen from "./screens/admin/Dashboard";
import CreateNewPassword from "./screens/auth/CreateNewPassword";
// import Information from "./screens/admin/agency/Information";
import Computimetric from "./screens/admin/computi-metrici/Computimetric";
import Profilio from "./screens/admin/profilio/Profilio";
import Utenti from "./screens/admin/utenti/Utenti";
import Flotta from "./screens/admin/flotta/Flotta";
import FlottaDetails from "./screens/admin/flotta/FlottaDetails";
import Cantieri from "./screens/admin/cantieri/Cantieri";
import Contabilita from "./screens/admin/cantieri/Contabilita";
import Documenti from "./screens/admin/cantieri/Documenti";
import Foto from "./screens/admin/cantieri/Foto";
import Informazioni from "./screens/admin/cantieri/Informazioni";
import Accounting from "./screens/admin/cantieri/Accounting";
import Produzione from "./screens/admin/cantieri/Produzione";
import Rapportini from "./screens/admin/cantieri/Rapportini";
import Ddt from "./screens/admin/ddt/Ddt";
import Ddt_add from "./screens/admin/ddt/Ddt_add";
import Ddt_edit from "./screens/admin/ddt/Ddt_edit";
import Preventivi from "./screens/admin/preventivi/Preventivi";
import Preventivi_add from "./screens/admin/preventivi/Preventivi_add";
import Preventivi_edit from "./screens/admin/preventivi/Preventivi_edit";
import Status_preventivi from "./screens/admin/preventivi/Status_preventivi";
import Magazzino from "./screens/admin/magazzino/Magazzino";
import Movimenti from "./screens/admin/movimenti/Movimenti";
import Luoghi_di_deposito from "./screens/admin/magazzino/Luoghi_di_deposito";
import Computrimetriciaddrec from "./screens/admin/computi-metrici/Compumetriciaddrec";
import EditInformation from "./screens/admin/agency/EditInformation";
import Calender from "./screens/admin/calender";
import Employees from "./screens/admin/personalData/Employees";
import AddEmployee from "./screens/admin/personalData/AddEmployee";
import ProtectedRoute from "./potectedRoutes";
import Agency from "./screens/admin/agency";
import Chat from "./screens/admin/chat/Chat";
import Assistenza from "./screens/admin/assistenza/Assistenza";
import Impostazioni from "./screens/admin/impostazioni/Impostazioni";
import ClientScreen from "./screens/admin/personalData/Client";
import NewClient from "./screens/admin/personalData/NewClient";
import SupplierScreen from "./screens/admin/personalData/Supplier"
import AddSupplier from "./screens/admin/personalData/addSupplier";
import Prezzari from "./screens/admin/prezzari_regioni/Prezzari_regioni";
import Capitoli from "./screens/admin/prezzari_regioni/Capitoli";
import Categoria from "./screens/admin/prezzari_regioni/Categoria";
import Description from "./screens/admin/prezzari_regioni/Description";
import Famiglia from "./screens/admin/prezzari_regioni/Famiglia";
import Regioni from "./screens/admin/prezzari_regioni/Regioni";
import ConstructionSiteLayout from "./components/admin/cantieri/ConstructionSiteLayout";
import SubscriptionPage from "./screens/auth/SubscriptionPage";
import SubscriptionSuccessPage from "./screens/auth/SubscriptionSuccess";
import SettingsPage from "./screens/admin/impostazioni/Settings"
import { SubscriptionProvider } from "./screens/auth/SubscriptionContext";
import SubscriptionProtectedRoute from "./screens/auth/SubscriptionProtectedRoute";
import LandingPage from "./screens/LandingPage";
import ComingSoonWrapper from './components/admin/sharedComponent/ComingSoonWrapper';
import RegisterSubscription from "./screens/RegisterSubscription";
import InvitedUserRegistration from "./screens/auth/InviteRegister";
import Notifications from "./screens/admin/notifications/Notification";
import CookieBanner from "./components/admin/sharedComponent/CookieBanner";
import ReactivateSubscription from "./screens/auth/ReactivateSubscription";
import Archive from "./screens/admin/archive/Archive";
import { PermissionsProvider } from "./contexts/PermissionsContext";
import PermissionProtectedRoute from "./components/guards/PermissionProtectedRoute";
import Installment from "./screens/admin/installment";
import RateizziDetailComponent from "./screens/admin/installment/RateizziDetail";
import SupplierDetails from "./screens/admin/personalData/SupplierDetails";

const AdminOnlyRoute = ({ children }) => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  if (user.isInvitedUser || user.role !== 'admin') {
    return <Navigate to="/dashboard" replace />;
  }

  return children;
};

function App() {
  return (
    <SubscriptionProvider>
      <BrowserRouter>
        <CookieBanner />
        <PermissionsProvider>
          <Routes>
            {/* Public routes - no protection needed */}
            <Route path="/login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="Forget-Password" element={<ForgetPassword />} />
            <Route path="Forget-Password-Request" element={<RequestSuccess />} />
            <Route path="create-password" element={<CreateNewPassword />} />
            <Route path="/invite-register" element={<InvitedUserRegistration />} />
            <Route path="/" element={<LandingPage />} />
            <Route path="/reactivate" element={<ReactivateSubscription />} />
            <Route path="/billing/:username" element={<RegisterSubscription />} />

            {/* Protected routes */}
            <Route path="dashboard" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="dashboard">
                  <DashBoardScreen />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="calendar" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="calendar">
                  <Calender />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="agency/:tab" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="agency">
                  <Agency />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            {/* <Route path="agency/patente a punti" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="agency">
                  <SubscriptionProtectedRoute requiredPlans={["trialing","standard", "professional", "enterprise"]}>
                    <Agency />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } /> */}

            <Route path="agency/edit-information/:id" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="agency">
                  <EditInformation />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            {/* Employee routes */}
            <Route path="employees/edit/:id" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="employees">
                  <AddEmployee />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="employees/:tab" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="employees">
                  <Employees />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="employees/add" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="employees">
                  <AddEmployee />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            {/* Client routes */}
            <Route path="/clients" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="clients">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <ClientScreen />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="/clients/add" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="clients">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <NewClient />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="/clients/edit" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="clients">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <NewClient />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            {/* Supplier routes */}
            <Route path="/suppliers" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="suppliers">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <SupplierScreen />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="/supplier/add" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="suppliers">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <AddSupplier />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="/supplier/edit" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="suppliers">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <AddSupplier />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />


            <Route path="/suppliers/details/:id" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="suppliers">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <SupplierDetails />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            {/* Archive route */}
            <Route path="archive" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="archive">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Archive />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            {/* Settings route */}
            <Route path="/settings" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="settings">
                  <SettingsPage />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            {/* Subscription routes */}
            <Route path="/subscription" element={
              <ProtectedRoute>
                <AdminOnlyRoute>
                  <SubscriptionPage />
                </AdminOnlyRoute>
              </ProtectedRoute>
            } />

            <Route path="/subscription/success" element={
              <ProtectedRoute>
                <AdminOnlyRoute>
                  <SubscriptionSuccessPage />
                </AdminOnlyRoute>
              </ProtectedRoute>
            } />

            {/* Computi metrici routes */}
            <Route path="computi-metrici" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="computi-metrici">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Computimetric />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="computrimetriciaddrec/:id" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="computi-metrici">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Computrimetriciaddrec />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            {/* Flotta routes */}
            <Route path="flotta" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="flotta">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Flotta />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="flotta_details/:id" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="flotta">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <FlottaDetails />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="profilio" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="profilio">
                  <Profilio />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="utenti" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="utenti">
                  <Utenti />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            {/* Magazzino routes */}
            <Route path="magazzino" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="magazzino">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Magazzino />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="luoghi_di_deposito" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="magazzino">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Luoghi_di_deposito />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="movimenti" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="movimenti">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Movimenti />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="installment" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="installment">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Installment />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="installment/:id" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="installment">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <RateizziDetailComponent />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="chat" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="chat">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Chat />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="assistenza" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="assistenza">
                  <Assistenza />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="impostazioni" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="impostazioni">
                  <Impostazioni />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="notifications" element={
              <ProtectedRoute>
                <Notifications />
              </ProtectedRoute>
            } />

            {/* DDT routes */}
            <Route path="ddt" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="ddt">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Ddt />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="ddt_add" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="ddt">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Ddt_add />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="ddt_edit/:id" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="ddt">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Ddt_edit />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            {/* Preventivi routes */}
            <Route path="preventivi" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="preventivi">
                  <Preventivi />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="preventivi_add" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="preventivi">
                  <Preventivi_add />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="preventivi_edit/:id" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="preventivi">
                  <Preventivi_edit />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="status_preventivi" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="preventivi">
                  <Status_preventivi />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            {/* Cantieri routes */}
            <Route path="cantieri" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="cantieri">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <Cantieri />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="cantieri/:id" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="cantieri">
                  <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                    <ConstructionSiteLayout />
                  </SubscriptionProtectedRoute>
                </PermissionProtectedRoute>
              </ProtectedRoute>
            }>
              <Route path="informazioni" element={
                <ProtectedRoute>
                  <PermissionProtectedRoute requiredRoute="cantieri">
                    <Informazioni />
                  </PermissionProtectedRoute>
                </ProtectedRoute>
              } />
              <Route path="contabilita" element={
                <ProtectedRoute>
                  <PermissionProtectedRoute requiredRoute="cantieri">
                    <SubscriptionProtectedRoute requiredPlans={["standard", "professional", "enterprise"]}>
                      <Contabilita />
                    </SubscriptionProtectedRoute>
                  </PermissionProtectedRoute>
                </ProtectedRoute>
              } />
              <Route path="accounting" element={
                <ProtectedRoute>
                  <PermissionProtectedRoute requiredRoute="cantieri">
                    <SubscriptionProtectedRoute requiredPlans={["professional", "enterprise"]}>
                      <Accounting />
                    </SubscriptionProtectedRoute>
                  </PermissionProtectedRoute>
                </ProtectedRoute>
              } />
              <Route path="documenti" element={
                <ProtectedRoute>
                  <PermissionProtectedRoute requiredRoute="cantieri">
                    <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                      <Documenti />
                    </SubscriptionProtectedRoute>
                  </PermissionProtectedRoute>
                </ProtectedRoute>
              } />
              <Route path="foto" element={
                <ProtectedRoute>
                  <PermissionProtectedRoute requiredRoute="cantieri">
                    <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                      <Foto />
                    </SubscriptionProtectedRoute>
                  </PermissionProtectedRoute>
                </ProtectedRoute>
              } />
              <Route path="produzione" element={
                <ProtectedRoute>
                  <PermissionProtectedRoute requiredRoute="cantieri">
                    <Produzione />
                  </PermissionProtectedRoute>
                </ProtectedRoute>
              } />
              <Route path="rapportini" element={
                <ProtectedRoute>
                  <PermissionProtectedRoute requiredRoute="cantieri">
                    <SubscriptionProtectedRoute requiredPlans={["basic", "standard", "professional", "enterprise"]}>
                      <Rapportini />
                    </SubscriptionProtectedRoute>
                  </PermissionProtectedRoute>
                </ProtectedRoute>
              } />
            </Route>

            {/* Prezzari routes */}
            <Route path="prezzari" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="prezzari">
                  <Prezzari />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="capitoli" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="prezzari">
                  <Capitoli />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="categoria" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="prezzari">
                  <Categoria />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="description" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="prezzari">
                  <Description />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="famiglia" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="prezzari">
                  <Famiglia />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

            <Route path="regioni" element={
              <ProtectedRoute>
                <PermissionProtectedRoute requiredRoute="prezzari">
                  <Regioni />
                </PermissionProtectedRoute>
              </ProtectedRoute>
            } />

          </Routes>
        </PermissionsProvider>
      </BrowserRouter>
    </SubscriptionProvider>
  );
}

export default App;