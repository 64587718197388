import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import RateizziDetailComponent from '../../../components/admin/installment/RateizziDetail'

const RateizziDetail = () => {
  return <>
  <Layout>
    <RateizziDetailComponent />
  </Layout>
  </>
}

export default RateizziDetail