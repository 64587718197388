// apis/EmployeeGroups.js
import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const createEmployeeGroup = async (data) => {
  try {
    const response = await axios.post('/employee-groups', data);
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getEmployeeGroups = async (companyId) => {
  try {
    const response = await axios.get(`/employee-groups/${companyId}`);
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const updateEmployeeGroup = async (groupId, data) => {
  try {
    const response = await axios.put(`/employee-groups/${groupId}`, data);
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const deleteEmployeeGroup = async (groupId) => {
  try {
    const response = await axios.delete(`/employee-groups/${groupId}`);
    return response;
  } catch (error) {
    throw error.response?.data || error;
  }
};