import React, { useState, useEffect } from 'react';
import { Button } from "../../../../ui/button";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogFooter,
} from "../../../../ui/dialog";
import { Input } from "../../../../ui/input";
import { Label } from "../../../../ui/label";
import { Check, PlusCircle, Users, Loader2 } from "lucide-react";
import { ScrollArea } from "../../../../ui/scroll-area";
import { toast } from "react-toastify";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../ui/tabs";
import { Card, CardContent, CardHeader, CardTitle } from "../../../../ui/card";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../../ui/dropdown-menu";
import { Alert, AlertDescription } from "../../../../ui/alert";
import { createEmployeeGroup, updateEmployeeGroup, deleteEmployeeGroup, getEmployeeGroups } from '../../../../../apis/EmployeeGroups';

const EmployeeGroupDialog = ({ 
  isOpen, 
  onClose, 
  employees = [], 
  onGroupCreated,
  editGroup = null 
}) => {
  const [name, setName] = useState('');
  const [selectedEmployees, setSelectedEmployees] = useState([]);
  const [description, setDescription] = useState('');
  const [searchQuery, setSearchQuery] = useState('');
  const [activeTab, setActiveTab] = useState('newGroup');
  const [selectedGroup, setSelectedGroup] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [error, setError] = useState(null);
  const user = JSON.parse(localStorage.getItem('user'));
  const [groups, setGroups] = useState([]);

  useEffect(() => {
    if (editGroup) {
      setName(editGroup.name || '');
      setSelectedEmployees(editGroup.employees?.map(emp => emp._id) || []);
      setDescription(editGroup.description || '');
      setActiveTab('existingGroups');
      setSelectedGroup(editGroup);
    } else {
      resetForm();
    }
  }, [editGroup, isOpen]);

  useEffect(() => {
    const fetchEmployeeGroups = async () => {
      try {
        const response = await getEmployeeGroups(user.companyId);
        if (response?.data?.groups) {
          setGroups(response.data.groups);
        }
      } catch (error) {
        toast.error('Error fetching groups');
      }
    };
  
    fetchEmployeeGroups();
  }, []);

  const resetForm = () => {
    setName('');
    setSelectedEmployees([]);
    setDescription('');
    setSearchQuery('');
    setSelectedGroup(null);
    setError(null);
  };

  const validateForm = () => {
    if (!name.trim()) {
      setError('Il nome del gruppo è obbligatorio');
      return false;
    }
    if (!user?.companyId) {
      setError('Errore: Impossibile determinare l\'azienda');
      return false;
    }
    return true;
  };

  const handleSubmit = async () => {
    try {
      setError(null);
      if (!validateForm()) return;
      
      setIsLoading(true);
      const groupData = {
        name: name.trim(),
        description,
        employees: selectedEmployees,
        companyId: user.companyId
      };

      if (selectedGroup) {
        await updateEmployeeGroup(selectedGroup._id, groupData);
        toast.success('Gruppo aggiornato con successo');
      } else {
        await createEmployeeGroup(groupData);
        toast.success('Gruppo creato con successo');
      }

      await onGroupCreated?.();
      handleClose();
    } catch (error) {
      console.error('Error saving group:', error);
      setError(error.message || 'Errore durante il salvataggio del gruppo');
      toast.error(error.message || 'Errore durante il salvataggio del gruppo');
    } finally {
      setIsLoading(false);
    }
  };

  const handleDeleteGroup = async (groupId, e) => {
    try {
      e.stopPropagation();
      if (!window.confirm('Sei sicuro di voler eliminare questo gruppo?')) return;
      
      setIsLoading(true);
      await deleteEmployeeGroup(groupId);
      toast.success('Gruppo eliminato con successo');
      await onGroupCreated?.();
      setSelectedGroup(null);
    } catch (error) {
      setError('Errore durante l\'eliminazione del gruppo');
      toast.error('Errore durante l\'eliminazione del gruppo');
    } finally {
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (isLoading) return;
    resetForm();
    setActiveTab('newGroup');
    onClose?.();
  };

  const toggleEmployee = (employeeId) => {
    setSelectedEmployees(prev => 
      prev.includes(employeeId)
        ? prev.filter(id => id !== employeeId)
        : [...prev, employeeId]
    );
  };

  const selectGroup = (group) => {
    setSelectedGroup(group);
    setName(group.name);
    setDescription(group.description || '');
    setSelectedEmployees(group.employees.map(emp => emp._id));
    setError(null);
  };

  const filteredEmployees = employees.filter(employee => 
    `${employee.firstName} ${employee.sureName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  return (
    <Dialog open={isOpen} onOpenChange={handleClose}>
      <DialogContent className="max-w-4xl">
        <DialogHeader>
          <DialogTitle>Gestione Gruppi</DialogTitle>
          <DialogDescription>
            Crea e gestisci i gruppi di dipendenti
          </DialogDescription>
        </DialogHeader>

        {error && (
          <Alert variant="destructive">
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        <Tabs defaultValue={activeTab} value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="newGroup" disabled={isLoading}>
              <PlusCircle className="mr-2 h-4 w-4" />
              Nuovo Gruppo
            </TabsTrigger>
            <TabsTrigger value="existingGroups" disabled={isLoading}>
              <Users className="mr-2 h-4 w-4" />
              Gruppi Esistenti
            </TabsTrigger>
          </TabsList>

          <TabsContent value="newGroup" className="space-y-4">
            <GroupForm
              name={name}
              setName={setName}
              description={description}
              setDescription={setDescription}
              searchQuery={searchQuery}
              setSearchQuery={setSearchQuery}
              filteredEmployees={filteredEmployees}
              selectedEmployees={selectedEmployees}
              toggleEmployee={toggleEmployee}
              isLoading={isLoading}
            />
          </TabsContent>

          <TabsContent value="existingGroups">
            <div className="grid grid-cols-2 gap-4">
              <GroupsList
                groups={groups}
                selectedGroup={selectedGroup}
                selectGroup={selectGroup}
                handleDeleteGroup={handleDeleteGroup}
                isLoading={isLoading}
              />
              
              {selectedGroup && (
                <GroupDetails
                  name={name}
                  setName={setName}
                  description={description}
                  setDescription={setDescription}
                  searchQuery={searchQuery}
                  setSearchQuery={setSearchQuery}
                  filteredEmployees={filteredEmployees}
                  selectedEmployees={selectedEmployees}
                  toggleEmployee={toggleEmployee}
                  isLoading={isLoading}
                />
              )}
            </div>
          </TabsContent>
        </Tabs>

        <DialogFooter>
          <Button variant="outline" onClick={handleClose} disabled={isLoading}>
            Annulla
          </Button>
          <Button 
            onClick={handleSubmit} 
            disabled={isLoading || !name.trim()}
          >
            {isLoading ? (
              <>
                <Loader2 className="mr-2 h-4 w-4 animate-spin" />
                {selectedGroup ? 'Aggiornamento...' : 'Creazione...'}
              </>
            ) : (
              selectedGroup ? 'Aggiorna Gruppo' : 'Crea Gruppo'
            )}
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

const GroupForm = ({
  name,
  setName,
  description,
  setDescription,
  searchQuery,
  setSearchQuery,
  filteredEmployees,
  selectedEmployees,
  toggleEmployee,
  isLoading
}) => (
  <div className="space-y-4">
    <div className="grid gap-2">
      <Label htmlFor="name">Nome gruppo</Label>
      <Input
        id="name"
        value={name}
        onChange={(e) => setName(e.target.value)}
        placeholder="Inserisci il nome del gruppo"
        disabled={isLoading}
      />
    </div>

    <div className="grid gap-2">
      <Label htmlFor="description">Descrizione</Label>
      <Input
        id="description"
        value={description}
        onChange={(e) => setDescription(e.target.value)}
        placeholder="Descrizione opzionale"
        disabled={isLoading}
      />
    </div>

    <EmployeeSelectionSection
      searchQuery={searchQuery}
      setSearchQuery={setSearchQuery}
      filteredEmployees={filteredEmployees}
      selectedEmployees={selectedEmployees}
      toggleEmployee={toggleEmployee}
      isLoading={isLoading}
    />
  </div>
);

const GroupsList = ({
  groups,
  selectedGroup,
  selectGroup,
  handleDeleteGroup,
  isLoading
}) => (
  <Card>
    <CardHeader>
      <CardTitle>Gruppi Esistenti</CardTitle>
    </CardHeader>
    <CardContent>
      <ScrollArea className="h-96">
        <div className="space-y-2">
          {groups.map((group) => (
            <div
              key={group._id}
              className={`p-2 rounded cursor-pointer flex justify-between items-center ${
                selectedGroup?._id === group._id ? 'bg-gray-100' : 'hover:bg-gray-50'
              }`}
              onClick={() => selectGroup(group)}
            >
              <div className="flex flex-col">
                <span className="font-medium">{group.name}</span>
                <span className="text-sm text-gray-500">
                  {group.employees?.length || 0} dipendenti
                </span>
              </div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button variant="ghost" size="sm" disabled={isLoading}>
                    •••
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent>
                  <DropdownMenuItem 
                    className="text-red-600"
                    onClick={(e) => handleDeleteGroup(group._id, e)}
                  >
                    Elimina gruppo
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          ))}
          {groups.length === 0 && (
            <div className="text-center text-gray-500 py-4">
              Nessun gruppo esistente
            </div>
          )}
        </div>
      </ScrollArea>
    </CardContent>
  </Card>
);

const GroupDetails = ({
  name,
  setName,
  description,
  setDescription,
  searchQuery,
  setSearchQuery,
  filteredEmployees,
  selectedEmployees,
  toggleEmployee,
  isLoading
}) => (
  <Card>
    <CardHeader>
      <CardTitle>Dettagli Gruppo</CardTitle>
    </CardHeader>
    <CardContent>
      <div className="space-y-4">
        <div className="grid gap-2">
          <Label>Nome</Label>
          <Input
            value={name}
            onChange={(e) => setName(e.target.value)}
            disabled={isLoading}
          />
        </div>
        <div className="grid gap-2">
          <Label>Descrizione</Label>
          <Input
            value={description}
            onChange={(e) => setDescription(e.target.value)}
            disabled={isLoading}
          />
        </div>
        <EmployeeSelectionSection
          searchQuery={searchQuery}
          setSearchQuery={setSearchQuery}
          filteredEmployees={filteredEmployees}
          selectedEmployees={selectedEmployees}
          toggleEmployee={toggleEmployee}
          isLoading={isLoading}
        />
      </div>
    </CardContent>
  </Card>
);

const EmployeeSelectionSection = ({
  searchQuery,
  setSearchQuery,
  filteredEmployees,
  selectedEmployees,
  toggleEmployee,
  isLoading
}) => (
  <div className="grid gap-2">
    <Label>Seleziona dipendenti</Label>
    <Input
      placeholder="Cerca dipendenti..."
      value={searchQuery}
      onChange={(e) => setSearchQuery(e.target.value)}
      className="mb-2"
      disabled={isLoading}
    />
    <ScrollArea className="h-48 rounded-md border p-2">
      <div className="space-y-2">
        {filteredEmployees.map((employee) => (
          <div
            key={employee._id}
            className="flex items-center space-x-2 p-2 cursor-pointer hover:bg-gray-100 rounded"
            onClick={() => !isLoading && toggleEmployee(employee._id)}
          >
            <div className={`w-4 h-4 border rounded flex items-center justify-center
              ${selectedEmployees.includes(employee._id) ? 'bg-blue-500 border-blue-500' : 'border-gray-300'}
              ${isLoading ? 'opacity-50' : ''}`}
            >
              {selectedEmployees.includes(employee._id) && (
                <Check className="h-3 w-3 text-white" />
              )}
            </div>
            <span>{employee.firstName} {employee.sureName}</span>
          </div>
        ))}
        {filteredEmployees.length === 0 && (
          <div className="text-center text-gray-500 py-4">
            Nessun dipendente trovato
          </div>
        )}
      </div>
    </ScrollArea>
  </div>
);

export default EmployeeGroupDialog;