import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import { useToast } from '../../components/ui/use-toast';
import { Button } from "../../components/ui/button";
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from "../../components/ui/card";
import { Alert, AlertDescription, AlertTitle } from "../../components/ui/alert";
import { Loader2 } from "lucide-react";
import Layout from '../../components/admin/sharedComponent/Layout';
import { fetchCurrentSubscription, initiateStripeCheckout } from '../../apis/SubscriptionApi';

const ReactivateSubscription = () => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);
  const [actionLoading, setActionLoading] = useState(false);
  const [error, setError] = useState(null);
  const navigate = useNavigate();
  const location = useLocation();
  const { toast } = useToast();

  useEffect(() => {
    const fetchSubscription = async () => {
      try {
        const data = await fetchCurrentSubscription();
        setSubscription(data);
      } catch (err) {
        setError('Impossibile recuperare i dettagli dell\'abbonamento');
        toast({
          title: "Errore",
          description: "Impossibile recuperare i dettagli dell'abbonamento",
          variant: "destructive",
        });
      } finally {
        setLoading(false);
      }
    };

    fetchSubscription();
  }, [toast]);

  const handleReactivate = async () => {
    if (!subscription?.stripePlanId) {
      toast({
        title: "Errore",
        description: "Piano non trovato. Contatta il supporto.",
        variant: "destructive",
      });
      return;
    }

    try {
      setActionLoading(true);
      const { checkoutUrl } = await initiateStripeCheckout(
        subscription.stripePlanId,
        { reactivate: true }
      );

      if (checkoutUrl) {
        window.location.href = checkoutUrl;
      } else {
        throw new Error('URL di checkout non ricevuto');
      }
    } catch (error) {
      console.error('Errore durante la riattivazione:', error);
      toast({
        title: "Errore",
        description: "Impossibile riattivare l'abbonamento. Riprova.",
        variant: "destructive",
      });
    } finally {
      setActionLoading(false);
    }
  };

  const goToSubscriptionPage = () => {
    navigate('/subscription');
  };

  if (loading) {
    return (
      <Layout>
        <div className="flex justify-center items-center min-h-screen">
          <Loader2 className="h-8 w-8 animate-spin" />
          <span className="ml-2">Caricamento...</span>
        </div>
      </Layout>
    );
  }

  if (error) {
    return (
      <Layout>
        <div className="p-6 max-w-xl mx-auto">
          <Alert variant="destructive">
            <AlertTitle>Errore</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
          <Button 
            onClick={goToSubscriptionPage} 
            className="mt-4"
          >
            Vai alla Pagina Abbonamenti
          </Button>
        </div>
      </Layout>
    );
  }

  if (!subscription?.cancelAtPeriodEnd && subscription?.status !== 'canceled') {
    return (
      <Layout>
        <div className="p-6 max-w-xl mx-auto">
          <Alert>
            <AlertTitle>Abbonamento Attivo</AlertTitle>
            <AlertDescription>
              Il tuo abbonamento è già attivo. Non è necessario riattivarlo.
            </AlertDescription>
          </Alert>
          <Button 
            onClick={goToSubscriptionPage} 
            className="mt-4"
          >
            Gestisci Abbonamento
          </Button>
        </div>
      </Layout>
    );
  }

  return (
    <Layout>
      <div className="p-6 max-w-xl mx-auto">
        <Card>
          <CardHeader>
            <CardTitle>Riattiva il Tuo Abbonamento</CardTitle>
            <CardDescription>
              Bentornato! Siamo felici che tu abbia deciso di riattivare il tuo abbonamento.
            </CardDescription>
          </CardHeader>
          <CardContent>
            <div className="space-y-4">
              <div>
                <h3 className="font-medium">Dettagli del Piano Precedente:</h3>
                <p>Piano: {subscription.planType}</p>
                {subscription.currentPeriodEnd && (
                  <p>Accesso fino al: {new Date(subscription.currentPeriodEnd).toLocaleDateString()}</p>
                )}
              </div>
              
              <Alert>
                <AlertDescription>
                  Riattivando l'abbonamento, potrai continuare a utilizzare tutti i servizi senza interruzioni.
                  La fatturazione riprenderà alla fine del periodo corrente.
                </AlertDescription>
              </Alert>
            </div>
          </CardContent>
          <CardFooter className="flex flex-col sm:flex-row gap-3">
            <Button
              onClick={handleReactivate}
              disabled={actionLoading}
              className="w-full sm:w-auto"
            >
              {actionLoading && <Loader2 className="mr-2 h-4 w-4 animate-spin" />}
              Riattiva Abbonamento
            </Button>
            <Button
              variant="outline"
              onClick={goToSubscriptionPage}
              className="w-full sm:w-auto"
            >
              Visualizza Altri Piani
            </Button>
          </CardFooter>
        </Card>
      </div>
    </Layout>
  );
};

export default ReactivateSubscription;