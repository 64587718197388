import React, { useState, useEffect } from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { motion } from 'framer-motion';
import { Button } from '../components/ui/button';
import { Card, CardContent, CardDescription, CardFooter, CardHeader, CardTitle } from '../components/ui/card';
import { ProjectorIcon, Zap, Laptop, ArrowRightIcon, Check, X } from 'lucide-react';
import logo from "../assest/logo2.png";
import Screenshot from "../assest/screenshot.png";
import Titolo from "../assest/titolo.png";
import NewsLetter from '../components/admin/sharedComponent/NewsLetter';
import LogoSection from '../components/admin/sharedComponent/logo-section';
import RestructFeaturesSection from '../components/admin/sharedComponent/RestructFeatureSection';
import FAQs from '../components/admin/sharedComponent/Faqs';
import { useSubscription } from '../screens/auth/SubscriptionContext';
import { fetchPlans, initiateStripeCheckout, changePlan } from '../apis/SubscriptionApi';
import UserDropdown from '../components/admin/sharedComponent/UserDropdown';
import { Alert, AlertDescription, AlertTitle } from '../components/ui/alert';
import { PolicyLink } from '../components/admin/sharedComponent/PolicyLink';

const stats = [
  { id: 1, name: 'Aziende che innovano con i nostri prodotti', value: '+100' },
  { id: 2, name: 'Tempo risparmiato per le operazioni aziendali', value: '-46%' },
  { id: 3, name: 'Media fatturato dei nostri clienti dopo il primo anno', value: '+37%' },
  { id: 4, name: 'Paesi in cui operiamo, e lo facciamo al meglio', value: '1' },
];

const features = [
  { name: "Spazio di archiviazione in cloud", tiers: ["1 gb", "3 gb", "10 gb", "Unlimited"] },
  { name: "Utenti", tiers: [1, 5, 50, 100] },
  { name: "Chat", tiers: [false, true, true, true] },
  { name: "Patente a punti", tiers: [false, true, true, true] },
  { name: "Calendario & Agenda", tiers: [true, true, true, true] },
  { name: "Dipendenti", tiers: [5, 20, 50, "Unlimited"] },
  { name: "Movimenti", tiers: ["100/mese", "300/mese", "Unlimited", "Unlimited"] },
  { name: "Gestione clienti", tiers: [50, 200, 500, "Unlimited"] },
  { name: "Gestione fornitori", tiers: [25, 100, 300, "Unlimited"] },
  { name: "Gestione flotta", tiers: [false, "10 Veicoli", "50 Veicoli", "Unlimited"] },
  { name: "Magazzino", tiers: ["1 magazzino\n100 prodotti", "3 magazzini\n500 prodotti", "5 magazzini\n1000 prodotti", "Unlimited"] },
  { name: "DDT", tiers: ["20/mese", "50/mese", "200/mese", "Unlimited"] },
  { name: "Computi metrici", tiers: [false, false, true, true] },
  { name: "Cantieri", tiers: [3, 10, 70, "Unlimited"] },
  { name: "Cantieri -> Contabilità", tiers: [true, true, true, true] },
  { name: "Cantieri -> Documenti", tiers: [false, true, true, true] },
  { name: "Cantieri -> Rapportini", tiers: [false, true, true, true] },
  { name: "Cantieri -> Giornale dei lavori", tiers: [false, false, true, true] },
  { name: "Cantieri -> Media", tiers: [false, "Foto", "Foto", "Foto & Video"] },
  { name: "Supporto", tiers: ["Standard", "Standard", "Dedicato", "Dedicato 24/7"] },
  { name: "Accesso esclusivo a nuove funzionalità", tiers: [false, false, false, true] },
];

const PricingTier = ({ plan, billingCycle, onSubscribe, isCurrentPlan, isPopular, userLoggedIn, userPlanType }) => {
  const price = plan.prices[billingCycle];
  const priceAmount = price ? (price.unit_amount / 100).toFixed(2) : 'N/A';

  return (
    <motion.div
      initial={{ opacity: 0, y: 20 }}
      animate={{ opacity: 1, y: 0 }}
      transition={{ duration: 0.5 }}
    >
      <Card className={`flex flex-col ${isPopular ? 'border-2 border-blue-500 relative' : ''} ${isCurrentPlan ? 'bg-blue-50' : ''}`}>
        {isPopular && (
          <div className="absolute top-0 right-0 bg-blue-500 text-white px-2 py-1 text-sm rounded-bl">
            Più popolare
          </div>
        )}
        <CardHeader>
          <CardTitle>{plan.name}</CardTitle>
          <CardDescription className='font-bold text-xl'>
            €{priceAmount}
            /{billingCycle === 'quarter' ? 'trimestre' : billingCycle === 'year' ? 'anno' : 'mese'}
          </CardDescription>
        </CardHeader>
        <CardContent className="flex-grow">
          <ul className="space-y-2">
            {Object.entries(plan.features || {}).map(([feature, value]) => (
              <li key={feature} className="flex items-center">
                {typeof value === 'boolean' ? (
                  value ? <Check className="h-5 w-5 text-green-500 mr-2" /> : <X className="h-5 w-5 text-red-500 mr-2" />
                ) : (
                  <Check className="h-5 w-5 text-green-500 mr-2" />
                )}
                <span>{`${feature}: ${value === true ? 'Sì' : value === false ? 'No' : value}`}</span>
              </li>
            ))}
          </ul>
        </CardContent>
        <CardFooter>
          {isCurrentPlan ? (
            <p className="text-green-600 font-semibold w-full text-center">Piano Attuale</p>
          ) : (
            <div className="flex flex-col gap-2 w-full text-center">
              {userLoggedIn ? (
                <Button onClick={() => onSubscribe(price?.id)} className="w-full" disabled={!price}>
                  {userPlanType === 'free' ? 'Sottoscrivi' : 'Upgrade'}
                </Button>
              ) : (
                <>
                  <Button onClick={() => onSubscribe(price?.id)} className="w-full" disabled={!price}>
                    Acquista ora
                  </Button>
                  <button onClick={() => onSubscribe(price?.id)} className="mt-2 w-full text-slate-900" disabled={!price}>
                    Inizia prova gratuita
                  </button>
                </>
              )}
            </div>
          )}
        </CardFooter>
      </Card>
    </motion.div>
  );
};

const FeatureCard = ({ icon: Icon, title, description }) => (
  <motion.div
    whileHover={{ scale: 1.05 }}
    transition={{ type: "spring", stiffness: 300 }}
  >
    <Card className="group relative bg-white dark:bg-gray-800 hover:z-[1] hover:shadow-2xl hover:shadow-gray-600/10 border-none">
      <CardContent className="relative space-y-8 py-12 p-8">
        <Icon className="w-12 h-12 text-blue-500" />
        <div className="space-y-2">
          <h5 className="text-xl font-medium text-gray-700 dark:text-white transition group-hover:text-primary">
            {title}
          </h5>
          <p className="text-sm text-gray-600 dark:text-gray-300">
            {description}
          </p>
        </div>
      </CardContent>
    </Card>
  </motion.div>
);

const LandingPage = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [plans, setPlans] = useState([]);
  const [billingCycle, setBillingCycle] = useState('month');
  const { subscription, setSubscription } = useSubscription();
  const navigate = useNavigate();
  const [user, setUser] = useState(null);
  const [successMessage, setSuccessMessage] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const storedUser = JSON.parse(localStorage.getItem('user'));
    if (storedUser) {
      setUser(storedUser);
      if (storedUser.subscription) {
        setSubscription(storedUser.subscription);
      }
    }
  }, [setSubscription]);

  useEffect(() => {
    const loadPlans = async () => {
      try {
        const fetchedPlans = await fetchPlans();

        // Define the desired order of plans
        const planOrder = ['basic', 'standard', 'professional', 'enterprise'];

        // Sort plans based on the defined order
        const sortedPlans = fetchedPlans.sort((a, b) => {
          const indexA = planOrder.findIndex(p => a.name.toLowerCase().includes(p));
          const indexB = planOrder.findIndex(p => b.name.toLowerCase().includes(p));
          return indexA - indexB;
        });

        setPlans(sortedPlans);
      } catch (err) {
        console.error('Error fetching plans:', err);
        setError('Errore nel caricamento dei piani. Riprova più tardi.');
      }
    };

    loadPlans();
  }, []);

  const handleSubscribe = async (priceId) => {
    if (!user) {
      navigate('/login');
      return;
    }

    try {
      if (subscription && subscription.planType !== 'free') {
        const response = await changePlan(priceId);
        setSuccessMessage('Piano aggiornato con successo. Le modifiche saranno effettive dal prossimo ciclo di fatturazione.');
        setSubscription(response.subscription);
        navigate('/subscription', { state: { success: true } });
      } else {
        const { checkoutUrl } = await initiateStripeCheckout(priceId);
        if (checkoutUrl) {
          window.location.href = checkoutUrl;
        } else {
          throw new Error('URL di checkout non ricevuto');
        }
      }
    } catch (error) {
      console.error('Error handling subscription:', error);
      setError('Errore nell\'elaborazione della richiesta. Riprova.');
    }
  };

  const logoutHandle = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("breadcrumb");
    window.location.href = "/login";
  };

  const AppFeatures = [
    {
      icon: ProjectorIcon,
      title: "Gestione dei progetti",
      description: "Organizza e coordina ogni fase del progetto con strumenti integrati, intuitivi e facili da usare, per una gestione senza sforzo."
    },
    {
      icon: Zap,
      title: "Velocità e precisione",
      description: "Statistiche in tempo reale e aggiornamenti immediatamente disponibili, lavora riducendo gli errori e aumentando l'efficienza."
    },
    {
      icon: Laptop,
      title: "Gestisci ovunque tu sia",
      description: "Accedi alla tua webapp da qualsiasi dispositivo e in qualsiasi momento, per una gestione flessibile e senza interruzioni."
    }
  ];

  const pricingTiers = [
    { title: "Piano Basic", price: { mensile: 30, trimestrale: 85, annuale: 324 }, index: 0, id: "price_base" },
    { title: "Piano Standard", price: { mensile: 60, trimestrale: 170, annuale: 648 }, index: 1, id: "price_standard" },
    { title: "Piano Professional", price: { mensile: 120, trimestrale: 340, annuale: 1296 }, index: 2, id: "price_professional" },
    { title: "Piano Enterprise", price: { mensile: 250, trimestrale: 710, annuale: 2700 }, index: 3, id: "price_enterprise" }
  ];

  return (
    <>
      <section className="bg-white dark:bg-gray-900">
        <nav className="container mx-auto lg:flex lg:justify-between max-sm:py-4 py-1 top-0 z-50 bg-white lg:items-center border-b">
          <div className="flex items-center justify-between">
            <Link to="/">
              <motion.div
                initial={{ opacity: 0 }}
                animate={{ opacity: 1 }}
                transition={{ duration: 0.5 }}
              >
                <img
                  className="h-7 w-auto"
                  src={logo}
                  alt="Logo"
                />
              </motion.div>
            </Link>

            <div className="flex lg:hidden">
              <button
                onClick={() => setIsOpen(!isOpen)}
                className="text-gray-500 dark:text-gray-200 hover:text-gray-600 dark:hover:text-gray-400 focus:outline-none"
                aria-label="toggle menu"
              >
                {!isOpen ? (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M4 8h16M4 16h16" />
                  </svg>
                ) : (
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    className="w-6 h-6"
                    fill="none"
                    viewBox="0 0 24 24"
                    stroke="currentColor"
                    strokeWidth={2}
                  >
                    <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                  </svg>
                )}
              </button>
            </div>
          </div>

          <div
            className={`${isOpen ? 'translate-x-0 opacity-100' : 'opacity-0 -translate-x-full'
              } absolute inset-x-0 flex justify-between z-20 w-full px-6 py-2 transition-all duration-300 ease-in-out bg-white shadow-md lg:bg-transparent lg:dark:bg-transparent lg:shadow-none dark:bg-gray-900 lg:relative lg:w-auto lg:opacity-100 lg:translate-x-0 lg:flex lg:items-center`}
          >
            <div className="flex flex-col space-y-4 lg:flex-row lg:space-y-0 lg:space-x-8 gap-x-4">
              <Link
                to="https://glds.it"
                target='_blank'
                className="text-gray-700 dark:text-gray-200 hover:text-blue-500 dark:hover:text-blue-400"
              >
                Contattaci
              </Link>
            </div>

            <div className="flex gap-x-4 ml-6 justify-center">
              {user ? (
                <UserDropdown user={user} logoutHandle={logoutHandle} />
              ) : (
                <>
                  <Link to="/login">
                    <Button variant='outline'>Login</Button>
                  </Link>
                  <Link to="/register">
                    <Button>Inizia</Button>
                  </Link>
                </>
              )}
            </div>
          </div>
        </nav>

        <div className="container px-6 py-16 mx-auto text-center">
          <motion.div
            initial={{ opacity: 0, y: 20 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ duration: 0.8 }}
            className="max-w-6xl mx-auto"
          >
            <h1 className="text-3xl font-bold text-gray-900 dark:text-white lg:text-5xl">
              Organizza, pianifica e costruisci:
              la tua impresa edile a portata di mano
            </h1>
            <p className="mt-6 text-gray-500 dark:text-gray-300">
              Gestisci i tuoi cantieri, pianifica i progetti e tieni sotto controllo ogni dettaglio del tuo business edile. Con la nostra piattaforma intuitiva, avrai tutto ciò di cui hai bisogno per organizzare, monitorare e ottimizzare il lavoro.
            </p>
            <motion.div
              className="flex gap-x-6 my-6 justify-center items-center"
              initial={{ opacity: 0 }}
              animate={{ opacity: 1 }}
              transition={{ delay: 0.3 }}
            >
              {user ? (
                <Link to="/dashboard">
                  <Button className='p-6 rounded-xl'>
                    Vai alla Dashboard
                  </Button>
                </Link>
              ) : (
                <Link to="/register">
                  <Button className='p-6 rounded-xl'>
                    Avvia ora la prova gratuita
                  </Button>
                </Link>
              )}
              <Button variant='link'>
                <Link to="https://glds.it" target='_blank'>
                  Contattaci
                </Link>
              </Button>
            </motion.div>
          </motion.div>

          <motion.div
            className="flex justify-center pt-20"
            initial={{ opacity: 0, scale: 0.95 }}
            animate={{ opacity: 1, scale: 1 }}
            transition={{ duration: 0.5, delay: 0.2 }}
          >
            <img
              className="object-cover w-full rounded-xl border"
              src={Screenshot}
              alt="Landing"
            />
          </motion.div>
        </div>

        <div className="pb-10">
          <div className="xl:container m-auto px-6 text-gray-500 md:px-12">
            <div className="mt-16 grid gap-4 sm:grid-cols-2 lg:grid-cols-3">
              {AppFeatures.map((feature, index) => (
                <motion.div
                  key={index}
                  initial={{ opacity: 0, y: 20 }}
                  animate={{ opacity: 1, y: 0 }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <FeatureCard {...feature} />
                </motion.div>
              ))}
            </div>
          </div>
        </div>

        <section className="bg-[#020134] text-white py-8">
          <div className="container mx-auto px-4">
            <div className="flex flex-col md:flex-row items-center justify-between">
              <motion.div
                className="w-full md:w-1/2 mb-8 md:mb-0"
                initial={{ opacity: 0, x: -20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <h2 className="text-3xl md:text-4xl font-bold mb-4">
                  Tutto organizzato e a portata di mano
                  <br />
                  <span className="text-blue-400">per una gestione più efficiente e senza stress.</span>
                </h2>
                <p className="text-gray-300 mb-8">
                  Dalla pianificazione iniziale fino alla fase di produzione, la nostra piattaforma ti
                  consente di gestire ogni singolo dettaglio del tuo cantiere in modo efficiente e
                  accurato, semplificando il lavoro quotidiano e migliorando la produttività
                  complessiva della tua impresa.
                </p>
                <div className="flex flex-col sm:flex-row gap-4">
                  <Button className="bg-blue-600 hover:bg-blue-700 text-white">
                    Avvia ora la prova gratuita
                    <ArrowRightIcon className="ml-2 h-4 w-4" />
                  </Button>
                  <Button variant="link" className="text-white border-white hover:bg-white hover:text-[#020134]">
                    Contattaci
                  </Button>
                </div>
              </motion.div>
              <motion.div
                className="w-full md:w-1/2 md:pl-8"
                initial={{ opacity: 0, x: 20 }}
                animate={{ opacity: 1, x: 0 }}
                transition={{ duration: 0.5 }}
              >
                <div className="aspect-video rounded-xl flex items-center justify-center text-gray-600">
                  <img src={Titolo} alt="web app image" className='object-cover rounded-xl' />
                </div>
              </motion.div>
            </div>
          </div>
        </section>

        <LogoSection />

        <motion.div
          className="bg-white py-24 sm:py-32"
          initial={{ opacity: 0 }}
          whileInView={{ opacity: 1 }}
          viewport={{ once: true }}
          transition={{ duration: 0.8 }}
        >
          <div className="mx-auto max-w-7xl px-6 lg:px-8">
            <dl className="grid grid-cols-1 gap-x-8 gap-y-16 text-center lg:grid-cols-4">
              {stats.map((stat, index) => (
                <motion.div
                  key={stat.id}
                  className="mx-auto flex max-w-xs flex-col gap-y-4"
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <dt className="text-base leading-7 text-gray-600">{stat.name}</dt>
                  <dd className="order-first text-3xl font-semibold tracking-tight text-gray-900 sm:text-5xl">
                    {stat.value}
                  </dd>
                </motion.div>
              ))}
            </dl>
            <div className="flex items-center justify-center p-8">
              <Link to="/register">
                <Button>
                  Avvia ora la prova gratuita
                </Button>
              </Link>
            </div>
          </div>
        </motion.div>

        <RestructFeaturesSection />

        {error && (
          <Alert variant="destructive" className="mb-4">
            <AlertTitle>Errore</AlertTitle>
            <AlertDescription>{error}</AlertDescription>
          </Alert>
        )}

        {successMessage && (
          <Alert className="mb-4">
            <AlertTitle>Successo</AlertTitle>
            <AlertDescription>{successMessage}</AlertDescription>
          </Alert>
        )}

        <section className="py-20 px-4">
          <div className="max-w-7xl mx-auto">
            <motion.div
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <h2 className="text-3xl font-bold text-center mb-8">
                Qualunque sia il tuo obiettivo, abbiamo il piano giusto per te.
              </h2>

              <div className="flex justify-center mb-8">
                {['month', 'quarter', 'year'].map((cycle) => (
                  <button
                    key={cycle}
                    className={`px-4 py-2 ${billingCycle === cycle ? 'bg-slate-900 text-white' : 'bg-gray-200'}`}
                    onClick={() => setBillingCycle(cycle)}
                  >
                    {cycle === 'month' ? 'Mensile' : cycle === 'quarter' ? 'Trimestrale' : 'Annuale'}
                  </button>
                ))}
              </div>
            </motion.div>

            {error && <div className="text-center text-red-500 mb-4">{error}</div>}

            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6 mb-12">
              {plans.map((plan, index) => (
                <motion.div
                  key={plan.id}
                  initial={{ opacity: 0, y: 20 }}
                  whileInView={{ opacity: 1, y: 0 }}
                  viewport={{ once: true }}
                  transition={{ duration: 0.5, delay: index * 0.1 }}
                >
                  <PricingTier
                    plan={plan}
                    billingCycle={billingCycle}
                    onSubscribe={handleSubscribe}
                    isCurrentPlan={user && subscription?.planType === plan.name.toLowerCase()}
                    isPopular={plan.name === "Piano Professionale"}
                    userLoggedIn={!!user}
                    userPlanType={subscription?.planType}
                  />
                </motion.div>
              ))}
            </div>

            <motion.div
              className="overflow-x-auto"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <div className="text-center text-gray-600 mb-4">
                Tutti i prezzi sono IVA inclusa
              </div>
              <table className="w-full">
                <thead>
                  <tr className="bg-gray-100">
                    <th className="text-left py-2 px-4 text-xl">Confronta i piani</th>
                    <th className="py-2 px-4 text-xl">Basic</th>
                    <th className="py-2 px-4 text-xl">Standard</th>
                    <th className="py-2 px-4 text-xl">Professional</th>
                    <th className="py-2 px-4 text-xl">Enterprise</th>
                  </tr>
                </thead>
                <tbody>
                  {features.map((feature, index) => (
                    <motion.tr
                      key={index}
                      className="border-b"
                      initial={{ opacity: 0 }}
                      whileInView={{ opacity: 1 }}
                      viewport={{ once: true }}
                      transition={{ duration: 0.2, delay: index * 0.02 }}
                    >
                      <td className="py-2">{feature.name}</td>
                      {feature.tiers.map((value, i) => (
                        <td key={i} className="text-center py-4">
                          {typeof value === 'boolean' ? (
                            value ? <Check className="inline h-5 w-5 text-green-500" /> : <X className="inline h-5 w-5 text-red-500" />
                          ) : (
                            value
                          )}
                        </td>
                      ))}
                    </motion.tr>
                  ))}
                </tbody>
              </table>
            </motion.div>
          </div>
        </section>

        <FAQs />

        <NewsLetter />

        <footer className="rounded-xl bg-gray-100">
          <div className="container m-auto space-y-8 px-6 py-16 text-gray-600">
            <motion.div
              className="flex flex-wrap items-center justify-between gap-4 border-b pb-8"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <img
                width="180"
                height="42"
                src={logo}
                alt="logo tailus"
              />
              <div className="flex gap-6">
                <a href="https://www.facebook.com/galdierosystems" target="_blank" rel="noopener noreferrer" aria-label="facebook" className="hover:text-cyan-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-facebook"
                    viewBox="0 0 16 16"
                  >
                    <path d="M16 8.049c0-4.446-3.582-8.05-8-8.05C3.58 0-.002 3.603-.002 8.05c0 4.017 2.926 7.347 6.75 7.951v-5.625h-2.03V8.05H6.75V6.275c0-2.017 1.195-3.131 3.022-3.131.876 0 1.791.157 1.791.157v1.98h-1.009c-.993 0-1.303.621-1.303 1.258v1.51h2.218l-.354 2.326H9.25V16c3.824-.604 6.75-3.934 6.75-7.951z" />
                  </svg>
                </a>
                <a href="https://www.instagram.com/galdiero.systems?igsh=MXBxZXd6ZTZ4d2R0eQ==" target="_blank" rel="noopener noreferrer" aria-label="instagram" className="hover:text-cyan-600">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="20"
                    height="20"
                    fill="currentColor"
                    className="bi bi-instagram"
                    viewBox="0 0 16 16"
                  >
                    <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z" />
                  </svg>
                </a>
              </div>
            </motion.div>
            <motion.div
              className="grid grid-cols-2 gap-6 sm:grid-cols-3"
              initial={{ opacity: 0, y: 20 }}
              whileInView={{ opacity: 1, y: 0 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <div>
                <h6 className="text-lg font-semibold text-cyan-900">Azienda</h6>
                <ul className="mt-4 list-inside space-y-4">
                  <li>
                    <Link to="/chisiamo" className="transition hover:text-cyan-600">Chi Siamo</Link>
                  </li>
                  <li>
                    <Link to="/blog" className="transition hover:text-cyan-600">Blog</Link>
                  </li>
                </ul>
              </div>
              <div>
                <h6 className="text-lg font-semibold text-cyan-900">Prodotti</h6>
                <ul className="mt-4 list-inside space-y-4">
                  <li>
                    <Link to="/restruct" className="transition hover:text-cyan-600">Restruct</Link>
                  </li>
                </ul>
              </div>
              <div>
                <h6 className="text-lg font-semibold text-cyan-900">Assistenza</h6>
                <ul className="mt-4 list-inside space-y-4">
                  <li>
                    <Link to="/support" className="transition hover:text-cyan-600">Supporto</Link>
                  </li>
                  <li>
                    <Link to="/forum" className="transition hover:text-cyan-600">Forum</Link>
                  </li>
                </ul>
              </div>
            </motion.div>
            <motion.div
              className="flex flex-col sm:flex-row justify-between rounded-md bg-gray-200 px-4 py-3 text-gray-600"
              initial={{ opacity: 0 }}
              whileInView={{ opacity: 1 }}
              viewport={{ once: true }}
              transition={{ duration: 0.5 }}
            >
              <span className="mb-2 sm:mb-0">© 2024 Galdiero Systems. Tutti i diritti riservati.</span>
              <div className="flex flex-row items-center gap-2 text-sm md:gap-6">
                <PolicyLink
                  href="https://www.iubenda.com/termini-e-condizioni/33611450"
                  title="Terms and Conditions"
                />
                <span className="hidden sm:block border-l-2 border-slate-400 h-6"></span>
                <PolicyLink
                  href="https://www.iubenda.com/privacy-policy/33611450"
                  title="Privacy Policy"
                />
                <span className="hidden sm:block border-l-2 border-slate-400 h-6"></span>
                <PolicyLink
                  href="https://www.iubenda.com/privacy-policy/33611450/cookie-policy"
                  title="Cookie Policy"
                />
              </div>
            </motion.div>
          </div>
        </footer>
      </section>
    </>
  );
};

export default LandingPage;