import { useState } from 'react'
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../components/ui/accordion"
import { Card, CardContent } from "../components/ui/card"
import preventiviVideo from '../assest/preventivi.mp4'

export default function VideoHero() {
  const [currentVideo, setCurrentVideo] = useState(preventiviVideo)

  const sectionData = [
    {
      id: 'preventivi',
      title: 'Preventivi',
      content: 'Gestisci facilmente i tuoi preventivi con il nostro strumento avanzato. Crea, modifica e invia preventivi professionali in pochi click.',
      hasVideo: true,
      src: preventiviVideo
    },
    {
      id: 'ddt',
      title: 'DDT',
      content: 'Gestione completa dei documenti di trasporto. Tieni traccia di tutte le tue spedizioni e consegne in modo efficiente.',
      hasVideo: false
    },
    {
      id: 'cantieri',
      title: 'Cantieri',
      content: 'Monitora e gestisci tutti i tuoi cantieri. Tieni sotto controllo avanzamento lavori, risorse e tempistiche.',
      hasVideo: false
    },
    {
      id: 'magazzino',
      title: 'Magazzino',
      content: 'Gestione completa del magazzino. Controlla scorte, movimenti e disponibilità dei materiali in tempo reale.',
      hasVideo: false
    },
    {
      id: 'movimenti',
      title: 'Movimenti',
      content: 'Traccia tutti i movimenti di materiali e risorse. Mantieni un registro dettagliato di Entratate e uscite.',
      hasVideo: false
    },
    {
      id: 'chat',
      title: 'Chat',
      content: 'Comunica in tempo reale con il tuo team. Mantieni tutti aggiornati e coordinati sui progetti in corso.',
      hasVideo: false
    },
  ]

  return (
    <section className="w-full py-12">
      <div className="container px-4 md:px-6">
        <div className="grid gap-6 lg:grid-cols-2 lg:gap-12 xl:grid-cols-2">
          <div className="flex flex-col justify-center space-y-4">
            <Card className="overflow-hidden">
              <CardContent className="p-0">
                {currentVideo === preventiviVideo && (
                  <video
                    key={currentVideo}
                    className="w-full h-full object-cover aspect-video"
                    autoPlay
                    muted
                    loop
                    playsInline
                  >
                    <source src={currentVideo} type="video/mp4" />
                    Il tuo browser non supporta il tag video.
                  </video>
                )}
                {currentVideo !== preventiviVideo && (
                  <div className="w-full h-full aspect-video bg-gray-100 flex items-center justify-center text-gray-500">
                    Nessun video disponibile per questa sezione
                  </div>
                )}
              </CardContent>
            </Card>
            <p className="text-sm text-gray-300">
              Clicca su una sezione a destra per saperne di più sulle nostre funzionalità.
            </p>
          </div>
          <div className="flex flex-col justify-center space-y-4">
            <Accordion type="single" collapsible className="w-full">
              {sectionData.map((item) => (
                <AccordionItem key={item.id} value={item.id}>
                  <AccordionTrigger
                    onClick={() => item.hasVideo && setCurrentVideo(item.src)}
                    className="text-lg font-semibold text-white"
                  >
                    {item.title}
                  </AccordionTrigger>
                  <AccordionContent>
                    <p className="text-gray-300">{item.content}</p>
                  </AccordionContent>
                </AccordionItem>
              ))}
            </Accordion>
          </div>
        </div>
      </div>
    </section>
  )
}


// import { useState } from 'react'
// import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "../components/ui/accordion"
// import { Card, CardContent } from "../components/ui/card"
// import preventiviVideo from '../assest/preventivi.mp4'
// import ddtVideo from '../assest/ddt.mp4'
// import cantieriVideo from '../assest/cantieri.mp4'
// import magazzinoVideo from '../assest/magazzino.mp4'
// import movimentiVideo from '../assest/movimenti.mp4'
// import chatVideo from '../assest/chat.mp4'

// export default function VideoHero() {
//   const [currentVideo, setCurrentVideo] = useState(preventiviVideo)

//   const sectionData = [
//     {
//       id: 'preventivi',
//       title: 'Preventivi',
//       content: 'Gestisci facilmente i tuoi preventivi con il nostro strumento avanzato. Crea, modifica e invia preventivi professionali in pochi click.',
//       src: preventiviVideo
//     },
//     {
//       id: 'ddt',
//       title: 'DDT',
//       content: 'Gestione completa dei documenti di trasporto. Tieni traccia di tutte le tue spedizioni e consegne in modo efficiente.',
//       src: ddtVideo
//     },
//     {
//       id: 'cantieri',
//       title: 'Cantieri',
//       content: 'Monitora e gestisci tutti i tuoi cantieri. Tieni sotto controllo avanzamento lavori, risorse e tempistiche.',
//       src: cantieriVideo
//     },
//     {
//       id: 'magazzino',
//       title: 'Magazzino',
//       content: 'Gestione completa del magazzino. Controlla scorte, movimenti e disponibilità dei materiali in tempo reale.',
//       src: magazzinoVideo
//     },
//     {
//       id: 'movimenti',
//       title: 'Movimenti',
//       content: 'Traccia tutti i movimenti di materiali e risorse. Mantieni un registro dettagliato di Entratate e uscite.',
//       src: movimentiVideo
//     },
//     {
//       id: 'chat',
//       title: 'Chat',
//       content: 'Comunica in tempo reale con il tuo team. Mantieni tutti aggiornati e coordinati sui progetti in corso.',
//       src: chatVideo
//     },
//   ]

//   const handleTabClick = (item) => {
//     try {
//       setCurrentVideo(item.src)
//     } catch (error) {
//       console.error('Error loading video:', error)
//     }
//   }

//   return (
//     <section className="w-full py-12">
//       <div className="container px-4 md:px-6">
//         <div className="grid gap-6 lg:grid-cols-2 lg:gap-12 xl:grid-cols-2">
//           <div className="flex flex-col justify-center space-y-4">
//             <Card className="overflow-hidden">
//               <CardContent className="p-0">
//                 <video
//                   key={currentVideo}
//                   className="w-full h-full object-cover aspect-video"
//                   autoPlay
//                   muted
//                   loop
//                   playsInline
//                 >
//                   <source src={currentVideo} type="video/mp4" />
//                   Il tuo browser non supporta il tag video.
//                 </video>
//               </CardContent>
//             </Card>
//             <p className="text-sm text-gray-300">
//               Clicca su una sezione a destra per vedere il video dimostrativo di ciascuna funzionalità.
//             </p>
//           </div>
//           <div className="flex flex-col justify-center space-y-4">
//             <Accordion type="single" collapsible className="w-full">
//               {sectionData.map((item) => (
//                 <AccordionItem key={item.id} value={item.id}>
//                   <AccordionTrigger
//                     onClick={() => handleTabClick(item)}
//                     className="text-lg font-semibold"
//                   >
//                     {item.title}
//                   </AccordionTrigger>
//                   <AccordionContent>
//                     <p className="text-gray-300">{item.content}</p>
//                   </AccordionContent>
//                 </AccordionItem>
//               ))}
//             </Accordion>
//           </div>
//         </div>
//       </div>
//     </section>
//   )
// }