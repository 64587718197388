import React, { useState, useEffect } from 'react';
import { Dialog, DialogContent, DialogHeader, DialogTitle, DialogFooter, DialogDescription } from "../../../../ui/dialog";
import { Button } from "../../../../ui/button";
import { Input } from "../../../../ui/input";
import { Label } from "../../../../ui/label";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "../../../../ui/tabs";
import { Checkbox } from "../../../../ui/checkbox";
import { ScrollArea } from "../../../../ui/scroll-area";
import { Search } from "lucide-react";
import { ChevronDown, ChevronRight } from "lucide-react";

const EmployeeSelectionDialog = ({
  isOpen,
  onClose,
  employees = [],
  groups = [],
  selectedEmployees,
  onSelectionChange,
  onConfirm
}) => {
  const [activeTab, setActiveTab] = useState("dipendenti");
  const [searchQuery, setSearchQuery] = useState("");
  const [selectedGroups, setSelectedGroups] = useState([]);
  const [localSelectedEmployees, setLocalSelectedEmployees] = useState(selectedEmployees);
  const [expandedGroups, setExpandedGroups] = useState({});

  // Reset state when dialog opens
  useEffect(() => {
    if (isOpen) {
      setLocalSelectedEmployees(selectedEmployees);
      updateGroupSelections(selectedEmployees);
      setSearchQuery("");
    }
  }, [isOpen, selectedEmployees]);

  // Update groups selection when employees change
  useEffect(() => {
    updateGroupSelections(localSelectedEmployees);
  }, [localSelectedEmployees]);

  const toggleGroupExpansion = (groupId) => {
    setExpandedGroups(prev => ({
      ...prev,
      [groupId]: !prev[groupId]
    }));
  };

  const filteredEmployees = employees.filter(employee =>
    `${employee.firstName} ${employee.lastName}`
      .toLowerCase()
      .includes(searchQuery.toLowerCase())
  );

  const updateGroupSelections = (selectedEmps) => {
    const newSelectedGroups = groups.filter(group => {
      const groupEmployeeIds = group.employees.map(emp => 
        typeof emp === 'string' ? emp : emp._id
      );
      return groupEmployeeIds.every(empId => selectedEmps.includes(empId));
    }).map(group => group._id);

    setSelectedGroups(newSelectedGroups);
  };

  const handleEmployeeToggle = (employeeId) => {
    // Simply toggle the employee selection
    setLocalSelectedEmployees(prev =>
      prev.includes(employeeId)
        ? prev.filter(id => id !== employeeId)
        : [...prev, employeeId]
    );
  };

  const handleGroupToggle = (groupId) => {
    const group = groups.find(g => g._id === groupId);
    if (!group) return;

    const groupEmployeeIds = group.employees.map(emp => 
      typeof emp === 'string' ? emp : emp._id
    );

    if (selectedGroups.includes(groupId)) {
      // Removing group - remove all group members from selection
      setLocalSelectedEmployees(prev => 
        prev.filter(id => !groupEmployeeIds.includes(id))
      );
      setSelectedGroups(prev => prev.filter(id => id !== groupId));
    } else {
      // Adding group - add all group members to selection
      setLocalSelectedEmployees(prev => {
        const newSelection = Array.from(new Set([...prev, ...groupEmployeeIds]));
        return newSelection;
      });
      setSelectedGroups(prev => [...prev, groupId]);
    }
  };

  const findEmployeeById = (id) => {
    return employees.find(emp => emp._id === id);
  };

  const getGroupMembers = (group) => {
    return group.employees.map(empId => 
      findEmployeeById(typeof empId === 'string' ? empId : empId._id)
    ).filter(Boolean);
  };

  const handleConfirm = () => {
    onSelectionChange(localSelectedEmployees);
    onConfirm();
  };

  return (
    <Dialog open={isOpen} onOpenChange={onClose}>
      <DialogContent className="sm:max-w-[525px]">
        <DialogHeader>
          <DialogTitle>Seleziona Dipendenti</DialogTitle>
          <DialogDescription>
            Scegli i dipendenti che desideri visualizzare nel foglio presenze.
          </DialogDescription>
        </DialogHeader>

        <Tabs value={activeTab} onValueChange={setActiveTab}>
          <TabsList className="grid w-full grid-cols-2">
            <TabsTrigger value="dipendenti">Dipendenti</TabsTrigger>
            <TabsTrigger value="gruppi">Gruppi</TabsTrigger>
          </TabsList>

          <div className="my-4">
            <div className="relative">
              <Search className="absolute left-2 top-2.5 h-4 w-4 text-muted-foreground" />
              <Input
                placeholder="Cerca..."
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                className="pl-8"
              />
            </div>
          </div>

          <TabsContent value="dipendenti">
            <ScrollArea className="h-[300px] pr-4">
              <div className="space-y-4">
                {filteredEmployees.map((employee) => {
                  const employeeGroups = groups.filter(group =>
                    group.employees.some(emp => 
                      (typeof emp === 'string' ? emp : emp._id) === employee._id
                    )
                  );

                  return (
                    <div key={employee._id} className="flex flex-col gap-1">
                      <div className="flex items-center space-x-2">
                        <Checkbox
                          id={employee._id}
                          checked={localSelectedEmployees.includes(employee._id)}
                          onCheckedChange={() => handleEmployeeToggle(employee._id)}
                        />
                        <Label htmlFor={employee._id} className="flex flex-col">
                          <span>{employee.firstName} {employee.lastName}</span>
                          {employeeGroups.length > 0 && (
                            <span className="text-xs text-muted-foreground">
                              {employeeGroups.map(g => g.name).join(', ')}
                            </span>
                          )}
                        </Label>
                      </div>
                    </div>
                  );
                })}
                {filteredEmployees.length === 0 && (
                  <div className="text-center text-gray-500 py-4">
                    Nessun dipendente trovato
                  </div>
                )}
              </div>
            </ScrollArea>
          </TabsContent>

          <TabsContent value="gruppi">
            <ScrollArea className="h-[300px] pr-4">
              <div className="space-y-4">
                {groups.map((group) => (
                  <div key={group._id} className="space-y-2">
                    <div className="flex items-center space-x-2">
                      <Checkbox
                        id={`group-${group._id}`}
                        checked={selectedGroups.includes(group._id)}
                        onCheckedChange={() => handleGroupToggle(group._id)}
                      />
                      <Label 
                        htmlFor={`group-${group._id}`} 
                        className="flex flex-col cursor-pointer"
                        onClick={() => toggleGroupExpansion(group._id)}
                      >
                        <div className="flex items-center space-x-2">
                          {expandedGroups[group._id] ? 
                            <ChevronDown className="h-4 w-4" /> : 
                            <ChevronRight className="h-4 w-4" />
                          }
                          <span>{group.name}</span>
                        </div>
                        <span className="text-xs text-muted-foreground">
                          {group.employees?.length || 0} dipendenti
                        </span>
                      </Label>
                    </div>
                    {expandedGroups[group._id] && (
                      <div className="ml-6 pl-2 border-l space-y-2">
                        {getGroupMembers(group).map(employee => (
                          <div key={employee._id} className="text-sm text-muted-foreground">
                            {employee.firstName} {employee.lastName}
                          </div>
                        ))}
                      </div>
                    )}
                  </div>
                ))}
                {groups.length === 0 && (
                  <div className="text-center text-gray-500 py-4">
                    Nessun gruppo disponibile
                  </div>
                )}
              </div>
            </ScrollArea>
          </TabsContent>
        </Tabs>

        <DialogFooter>
          <Button variant="outline" onClick={onClose}>
            Annulla
          </Button>
          <Button onClick={handleConfirm}>
            Applica
          </Button>
        </DialogFooter>
      </DialogContent>
    </Dialog>
  );
};

export default EmployeeSelectionDialog;