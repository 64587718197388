import React, { createContext, useState, useEffect, useContext } from 'react';
import { fetchCurrentSubscription } from '../../apis/SubscriptionApi';

const SubscriptionContext = createContext();

export const useSubscription = () => useContext(SubscriptionContext);

export const SubscriptionProvider = ({ children }) => {
  const [subscription, setSubscription] = useState(null);
  const [loading, setLoading] = useState(true);

  const isSubscriptionValid = (sub, user) => {
    if (!sub) {
      console.log('No subscription to validate');
      return false;
    }
    
    const currentPeriodEnd = new Date(sub.currentPeriodEnd);
    const now = new Date();

    const isValid = currentPeriodEnd > now && (
      sub.status === 'active' || 
      sub.status === 'trialing' || 
      sub.status === 'canceled' ||
      sub.status === 'canceled_active'
    );

    console.log('Subscription validity check:', {
      subscriptionId: sub._id,
      status: sub.status,
      currentPeriodEnd,
      now,
      isValid,
      userRole: user?.role,
      isInvitedUser: user?.isInvitedUser
    });

    return isValid;
  };

  useEffect(() => {
    const fetchSubscriptionData = async () => {
      try {
        const userStr = localStorage.getItem('user');
        if (!userStr) {
          setLoading(false);
          return;
        }

        const user = JSON.parse(userStr);
        console.log('User context:', user);

        const data = await fetchCurrentSubscription();
        console.log('Subscription API response:', data);

        let effectiveSubscription = null;

        if (user.isInvitedUser) {
          effectiveSubscription = data.adminSubscription;
          console.log('Invited user using admin subscription:', effectiveSubscription);
        } else {
          effectiveSubscription = data.subscription;
          console.log('Regular user using own subscription:', effectiveSubscription);
        }

        // Fall back to user object subscription if needed
        if (!effectiveSubscription && user.subscription) {
          console.log('Falling back to user object subscription');
          effectiveSubscription = user.subscription;
        }

        if (effectiveSubscription && isSubscriptionValid(effectiveSubscription, user)) {
          console.log('Setting valid subscription:', effectiveSubscription);
          setSubscription(effectiveSubscription);
        } else {
          console.log('No valid subscription found');
          setSubscription(null);
        }
      } catch (error) {
        console.error('Subscription fetch error:', error);
        // Fall back to user object subscription
        const user = JSON.parse(localStorage.getItem('user') || '{}');
        if (user.subscription && isSubscriptionValid(user.subscription, user)) {
          setSubscription(user.subscription);
        } else {
          setSubscription(null);
        }
      } finally {
        setLoading(false);
      }
    };

    fetchSubscriptionData();
  }, []);

  return (
    <SubscriptionContext.Provider 
      value={{ 
        subscription,
        loading,
        setSubscription,
        isSubscriptionValid: (sub) => {
          const user = JSON.parse(localStorage.getItem('user') || '{}');
          return isSubscriptionValid(sub, user);
        }
      }}
    >
      {children}
    </SubscriptionContext.Provider>
  );
};

export default SubscriptionProvider;