// apis/RateizziService.js
import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const createRateizzi = async (data) => {
  try {
    const response = await axios.post('/installment', data);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getRateizzi = async () => {
  try {
    const response = await axios.get('/installment');
    // Return the data directly since the backend returns the array
    return response.data || [];
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const getSingleRateizzi = async (id) => {
  try {
    const response = await axios.get(`/installment/${id}`);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const updateRateizzi = async (id, data) => {
  try {
    const response = await axios.patch(`/installment/${id}`, data);
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const deleteRateizzi = async (id, password) => {
  try {
    const response = await axios.delete(`/installment/${id}`, { data: { password } });
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};

export const updateInstallmentStatus = async (rateizziId, installmentId, data) => {
  try {
    const response = await axios.patch(
      `/installment/${rateizziId}/installments/${installmentId}`, 
      data
    );
    return response.data;
  } catch (error) {
    throw error.response?.data || error;
  }
};