import { exportToPDF, exportToExcel } from '../../../../config/helper';
import { format } from 'date-fns';
import { it } from 'date-fns/locale';

export const prepareWorkJournalData = (entries) => {
  // Transform entries into a format suitable for export
  return entries.map(entry => ({
    data: format(new Date(entry.date), 'dd-MM-yyyy'),
    temperatura: `${entry.temperature}°C`,
    meteo: entry.weatherConditions || '',
    annotazioni: entry.annotations || '',
    operaiMezzi: entry.entries
      ?.map(e => `${e.name} (${e.company}) - Q.tà: ${e.quantity}`)
      .join('\n') || ''
  }));
};

export const exportWorkJournalToExcel = async (entries, siteName) => {
  const data = prepareWorkJournalData(entries);
  const fileName = `Giornale_dei_lavori_${siteName}_${format(new Date(), 'dd-MM-yyyy')}`;
  exportToExcel(data, fileName);
};

export const exportWorkJournalToPDFFile = async (entries, siteName, siteInfo) => {
  const data = prepareWorkJournalData(entries);
  
  // Define columns for PDF
  const columns = [
    { header: 'Data', key: 'data', width: 0.8 },
    { header: 'Temperatura', key: 'temperatura', width: 0.8 },
    { header: 'Meteo', key: 'meteo', width: 0.8 },
    { header: 'Annotazioni', key: 'annotazioni', width: 2 },
    { header: 'Operai, Mezzi e Attrezzature', key: 'operaiMezzi', width: 2 }
  ];

  // Calculate statistics
  const stats = {
    left: [
      { label: 'Nome cantiere', value: siteName },
      { label: 'Indirizzo', value: siteInfo?.address || 'N/D' },
      { label: 'Data inizio', value: siteInfo?.startDate ? format(new Date(siteInfo.startDate), 'dd-MM-yyyy') : 'N/D' }
    ],
    right: [
      { label: 'Giorni registrati', value: entries.length },
      { label: 'Ultimo aggiornamento', value: format(new Date(), 'dd-MM-yyyy', { locale: it }) }
    ]
  };

  // Generate PDF
  const fileName = `Giornale_dei_lavori_${siteName}`;
  await exportToPDF(data, columns, fileName, stats);
};