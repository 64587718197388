import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { useSubscription } from './SubscriptionContext';
import {
  AlertDialog,
  AlertDialogContent,
  AlertDialogHeader,
  AlertDialogTitle,
  AlertDialogDescription,
  AlertDialogFooter,
  AlertDialogCancel,
  AlertDialogAction,
} from "../../components/ui/alert-dialog";
import Layout from '../../components/admin/sharedComponent/Layout';

const SubscriptionProtectedRoute = ({ children, requiredPlans }) => {
  const { subscription, loading, isSubscriptionValid } = useSubscription();
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  useEffect(() => {
    if (!loading && subscription?.status !== 'trialing') {
      const hasValidSub = isSubscriptionValid(subscription, user);
      const currentPlan = subscription?.planType?.toLowerCase();
      const hasRequiredPlan = requiredPlans ?
        requiredPlans.some(plan => currentPlan?.includes(plan)) : true;

      console.log('Checking subscription access:', {
        isAdmin: user.role === 'admin',
        isInvited: user.isInvitedUser,
        subscriptionStatus: subscription?.status,
        hasValidSub,
        currentPlan,
        hasRequiredPlan,
        requiredPlans,
        subscription
      });

      if (!hasValidSub || !hasRequiredPlan) {
        setShowModal(true);
      }
    }
  }, [loading, subscription, user, isSubscriptionValid, requiredPlans]);

  const handleUpgrade = () => {
    if (user.role === 'admin') {
      navigate('/subscription');
    }
  };

  const handleClose = () => {
    navigate(-1);
  };

  const getModalMessage = () => {
    const plansList = requiredPlans?.map(plan => {
      switch (plan) {
        case 'basic': return 'Basic';
        case 'standard': return 'Standard';
        case 'professional': return 'Professional';
        case 'enterprise': return 'Enterprise';
        default: return plan;
      }
    }).join(', ');

    if (user.role === 'admin') {
      if (subscription?.status === 'canceled') {
        return "Il tuo abbonamento è stato cancellato ma è ancora attivo fino al termine del periodo corrente.";
      }
      return `Questa funzionalità è disponibile solo nei piani ${plansList}. Aggiorna il tuo piano per accedere.`;
    }

    if (user.isInvitedUser) {
      return `Per accedere a questa funzionalità, il tuo amministratore deve avere un abbonamento ${plansList} attivo.`;
    }

    return `Per accedere a questa funzionalità, è necessario un abbonamento ${plansList}. Contatta il tuo amministratore.`;
  };

  if (loading) {
    return null;
  }

  // Return children directly without Layout for trial users
  if (subscription?.status === 'trialing') {
    return children;
  }
  
  const hasValidSubscription = isSubscriptionValid(subscription, user);
  const currentPlan = subscription?.planType?.toLowerCase();
  const hasRequiredPlan = requiredPlans ?
    requiredPlans.some(plan => currentPlan?.includes(plan)) : true;
  
  if (hasValidSubscription && hasRequiredPlan) {
    return children;
  }

  // Only render modal and Layout if access is denied
  return (
    <>
      <AlertDialog open={showModal} onOpenChange={() => { }}>
        <AlertDialogContent onPointerDownOutside={(e) => e.preventDefault()}>
          <AlertDialogHeader>
            <AlertDialogTitle>Accesso Limitato</AlertDialogTitle>
            <AlertDialogDescription className="py-4">
              {getModalMessage()}
            </AlertDialogDescription>
          </AlertDialogHeader>
          <AlertDialogFooter>
            <AlertDialogCancel onClick={handleClose}>
              Indietro
            </AlertDialogCancel>
            {user.role === 'admin' && (
              <AlertDialogAction onClick={handleUpgrade}>
                {subscription?.status === 'canceled'
                  ? 'Gestisci Abbonamento'
                  : 'Aggiorna Piano'}
              </AlertDialogAction>
            )}
          </AlertDialogFooter>
        </AlertDialogContent>
      </AlertDialog>
    </>
  );
};

export default SubscriptionProtectedRoute;