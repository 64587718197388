import React, { useState, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
  getContabilita, getAccountingItems,
  addAccountingItem, updateAccountingItem,
  deleteAccountingItem, getConstructionSite
} from '../../../apis/ConstructionSite';
import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../ui/table";
import {
  Dialog,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
} from "../../ui/dialog";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../ui/select";
import { Loader2, Plus, Trash2, Edit, Filter, Archive } from "lucide-react";
import ConstructionSiteNavbar from './ConstructionSiteNavbar';

const categories = [
  { id: 'materiali', name: 'Materiali' },
  { id: 'manodopera', name: 'Manodopera' },
  { id: 'attrezzatura', name: 'Attrezzatura' }
];

const quantityRanges = [
  { label: '0-50', min: 0, max: 50 },
  { label: '51-100', min: 51, max: 100 },
  { label: '101-500', min: 101, max: 500 },
  { label: '500+', min: 501, max: Infinity }
];

const priceRanges = [
  { label: '€0-€100', min: 0, max: 100 },
  { label: '€101-€500', min: 101, max: 500 },
  { label: '€501-€1000', min: 501, max: 1000 },
  { label: '€1000+', min: 1001, max: Infinity }
];

const Accounting = () => {
  const { id } = useParams();
  const [isLoading, setIsLoading] = useState(true);
  const [remainingAmount, setRemainingAmount] = useState(0);
  const [totalWorkCost, setTotalWorkCost] = useState(0);
  const [isAddModalOpen, setIsAddModalOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [isFilterDialogOpen, setIsFilterDialogOpen] = useState(false);
  const [items, setItems] = useState([]);
  const [filteredItems, setFilteredItems] = useState([]);
  const [editingItem, setEditingItem] = useState(null);
  const [itemToDelete, setItemToDelete] = useState(null);
  const [searchTerm, setSearchTerm] = useState('');
  const [filters, setFilters] = useState({
    category: 'all',
    quantity: 'all',
    price: 'all'
  });
  const [newItem, setNewItem] = useState({
    category: '',
    name: '',
    description: '',
    unit: '',
    quantity: 0,
    price: 0,
    total: 0
  });
  const [isArchived, setIsArchived] = useState(false);

  useEffect(() => {
    fetchData();
  }, [id]);

  useEffect(() => {
    applyFiltersAndSearch();
  }, [items, searchTerm, filters]);

  const fetchData = async () => {
    try {
      setIsLoading(true);
      const [contabilitaData, accountingData] = await Promise.all([
        getContabilita(id),
        getAccountingItems(id)
      ]);

      setRemainingAmount(contabilitaData.amountPaid || 0);
      setItems(accountingData.items || []);
      setTotalWorkCost(accountingData.workCost || 0);
    } catch (error) {
      console.error('Error fetching data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    const updatedItem = editingItem || newItem;
    const newValues = { ...updatedItem, [name]: value };

    if (name === 'quantity' || name === 'price') {
      newValues.total = newValues.quantity * newValues.price;
    }

    if (editingItem) {
      setEditingItem(newValues);
    } else {
      setNewItem(newValues);
    }
  };

  const formatCurrency = (amount) => {
    return new Intl.NumberFormat('it-IT', {
      style: 'currency',
      currency: 'EUR'
    }).format(amount);
  };

  const applyFiltersAndSearch = () => {
    let result = [...items];

    // Apply search
    if (searchTerm) {
      result = result.filter(item =>
        item.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
        item.description.toLowerCase().includes(searchTerm.toLowerCase())
      );
    }

    // Apply category filter
    if (filters.category !== 'all') {
      result = result.filter(item => item.category === filters.category);
    }

    // Apply quantity filter
    if (filters.quantity !== 'all') {
      const [min, max] = filters.quantity.split('-').map(Number);
      result = result.filter(item => {
        const quantity = Number(item.quantity);
        return quantity >= min && (max === Infinity ? true : quantity <= max);
      });
    }

    // Apply price filter
    if (filters.price !== 'all') {
      const [min, max] = filters.price.split('-').map(Number);
      result = result.filter(item => {
        const price = Number(item.price);
        return price >= min && (max === Infinity ? true : price <= max);
      });
    }

    setFilteredItems(result);
  };

  const handleFilterChange = (name, value) => {
    setFilters(prev => ({ ...prev, [name]: value }));
  };

  const resetFilters = () => {
    setFilters({
      category: 'all',
      quantity: 'all',
      price: 'all'
    });
    setSearchTerm('');
    setIsFilterDialogOpen(false);
  };

  // Update the areFiltersActive function
  const areFiltersActive = () => {
    return (
      filters.category !== 'all' ||
      filters.quantity !== 'all' ||
      filters.price !== 'all' ||
      searchTerm !== ''
    );
  };

  useEffect(() => {
    fetchSiteStatus();
    fetchData();
  }, [id]);

  const fetchSiteStatus = async () => {
    setIsLoading(true);
    try {
      const response = await getConstructionSite(id);
      setIsArchived(response.data.status === 'Archiviato');
    } catch (error) {
      console.error('Error fetching site status:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleAddItem = async () => {
    if (isArchived) return;
    try {
      await addAccountingItem(id, newItem);
      await fetchData();
      setNewItem({
        category: '',
        name: '',
        description: '',
        unit: '',
        quantity: 0,
        price: 0,
        total: 0
      });
      setIsAddModalOpen(false);
    } catch (error) {
      console.error('Error adding item:', error);
    }
  };

  const handleEditClick = (item) => {
    if (isArchived) return;
    setEditingItem(item);
    setIsEditModalOpen(true);
  };

  const handleEditSave = async () => {
    if (isArchived) return;
    try {
      await updateAccountingItem(id, editingItem._id, editingItem);
      await fetchData();
      setEditingItem(null);
      setIsEditModalOpen(false);
    } catch (error) {
      console.error('Error updating item:', error);
    }
  };

  const handleDeleteItem = async (itemId) => {
    if (isArchived) return;
    try {
      await deleteAccountingItem(id, itemId);
      await fetchData();
      setIsDeleteModalOpen(false);
      setItemToDelete(null);
    } catch (error) {
      console.error('Error deleting item:', error);
    }
  };


  return (
    <>
      <ConstructionSiteNavbar />
      {isArchived && (
        <div className="mx-6 mt-6">
          <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4">
            <div className="flex items-center">
              <Archive className="w-5 h-5 text-yellow-400 mr-2" />
              <p className="text-yellow-700">
                Questo cantiere è archiviato. È possibile solo visualizzare la contabilità.
              </p>
            </div>
          </div>
        </div>
      )}
      {isLoading ? (
        <div className="flex justify-center items-center h-screen">
          <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
        </div>
      ) : (
        <div className="p-6 space-y-6">
          {/* Summary Cards */}
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <Card>
              <CardHeader>
                <CardTitle>Importo Pagato</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold text-red-600">
                  {formatCurrency(remainingAmount)}
                </p>
              </CardContent>
            </Card>
            <Card>
              <CardHeader>
                <CardTitle>Costo dei Lavori</CardTitle>
              </CardHeader>
              <CardContent>
                <p className="text-2xl font-bold">
                  {formatCurrency(totalWorkCost)}
                </p>
              </CardContent>
            </Card>
          </div>

          {/* Items Table */}
          <div>
            <div className="flex justify-between items-center mb-4">
              <h2 className="text-lg font-semibold">Dettaglio Costi</h2>
              <div className="flex items-center gap-4">
                <div className="flex items-center gap-2">
                  <Input
                    type="text"
                    placeholder="Cerca per nome o descrizione..."
                    value={searchTerm}
                    onChange={(e) => setSearchTerm(e.target.value)}
                    className="w-64"
                  />
                  <Button
                    variant="outline"
                    onClick={() => setIsFilterDialogOpen(true)}
                    className="flex items-center gap-2"
                  >
                    <Filter className="h-4 w-4" />
                    Filtri
                    {areFiltersActive() && (
                      <span className="flex h-2 w-2 rounded-full bg-red-600"></span>
                    )}
                  </Button>
                </div>
                {!isArchived && (
                  <Button onClick={() => setIsAddModalOpen(true)}>
                    Aggiungi
                  </Button>
                )}
              </div>
            </div>

            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Categoria</TableHead>
                  <TableHead>Nome</TableHead>
                  <TableHead>Descrizione</TableHead>
                  <TableHead>U.M.</TableHead>
                  <TableHead className="text-right">Q.tà</TableHead>
                  <TableHead className="text-right">Prezzo</TableHead>
                  <TableHead className="text-right">Totale</TableHead>
                  {!isArchived && <TableHead className="text-center">Azioni</TableHead>}
                </TableRow>
              </TableHeader>
              <TableBody>
                {filteredItems.map((item) => (
                  <TableRow key={item._id}>
                    <TableCell>{categories.find(c => c.id === item.category)?.name}</TableCell>
                    <TableCell>{item.name}</TableCell>
                    <TableCell>{item.description}</TableCell>
                    <TableCell>{item.unit}</TableCell>
                    <TableCell className="text-right">{item.quantity}</TableCell>
                    <TableCell className="text-right">{formatCurrency(item.price)}</TableCell>
                    <TableCell className="text-right">{formatCurrency(item.total)}</TableCell>
                    {!isArchived && (
                      <TableCell className="text-center">
                        <div className="flex justify-center space-x-2">
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => handleEditClick(item)}
                          >
                            <Edit className="h-4 w-4" />
                          </Button>
                          <Button
                            variant="ghost"
                            size="sm"
                            onClick={() => {
                              setItemToDelete(item);
                              setIsDeleteModalOpen(true);
                            }}
                          >
                            <Trash2 className="h-4 w-4 text-red-500" />
                          </Button>
                        </div>
                      </TableCell>
                    )}
                  </TableRow>
                ))}
                {filteredItems.length === 0 && (
                  <TableRow>
                    <TableCell colSpan={isArchived ? 7 : 8} className="text-center py-4">
                      Nessun elemento trovato
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>

          {!isArchived && (
            <>
              {/* Add Item Modal */}
              <Dialog open={isAddModalOpen} onOpenChange={setIsAddModalOpen}>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Aggiungi Elemento</DialogTitle>
                    <DialogDescription>
                      Inserisci i dettagli del nuovo elemento.
                    </DialogDescription>
                  </DialogHeader>

                  <div className="grid gap-4 py-4">
                    <div className="grid gap-2">
                      <Label>Categoria</Label>
                      <Select
                        value={newItem.category}
                        onValueChange={(value) => handleInputChange({ target: { name: 'category', value } })}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Seleziona categoria" />
                        </SelectTrigger>
                        <SelectContent>
                          {categories.map((category) => (
                            <SelectItem key={category.id} value={category.id}>
                              {category.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="grid gap-2">
                      <Label>Nome</Label>
                      <Input
                        name="name"
                        value={newItem.name}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid gap-2">
                      <Label>Descrizione</Label>
                      <Input
                        name="description"
                        value={newItem.description}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid gap-2">
                      <Label>Unità di Misura</Label>
                      <Input
                        name="unit"
                        value={newItem.unit}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div className="grid gap-2">
                        <Label>Quantità</Label>
                        <Input
                          type="number"
                          name="quantity"
                          value={newItem.quantity}
                          onChange={handleInputChange}
                          min="0"
                          step="0.01"
                        />
                      </div>

                      <div className="grid gap-2">
                        <Label>Prezzo Unitario</Label>
                        <Input
                          type="number"
                          name="price"
                          value={newItem.price}
                          onChange={handleInputChange}
                          min="0"
                          step="0.01"
                        />
                      </div>
                    </div>

                    <div className="grid gap-2">
                      <Label>Totale</Label>
                      <Input
                        type="number"
                        value={newItem.quantity * newItem.price}
                        readOnly
                        disabled
                      />
                    </div>
                  </div>

                  <DialogFooter>
                    <Button variant="outline" onClick={() => setIsAddModalOpen(false)}>
                      Annulla
                    </Button>
                    <Button onClick={handleAddItem}>
                      Aggiungi
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>

              {/* Edit Item Modal */}
              <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Modifica Elemento</DialogTitle>
                    <DialogDescription>
                      Modifica i dettagli dell'elemento.
                    </DialogDescription>
                  </DialogHeader>

                  <div className="grid gap-4 py-4">
                    <div className="grid gap-2">
                      <Label>Categoria</Label>
                      <Select
                        value={editingItem?.category}
                        onValueChange={(value) => handleInputChange({ target: { name: 'category', value } })}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Seleziona categoria" />
                        </SelectTrigger>
                        <SelectContent>
                          {categories.map((category) => (
                            <SelectItem key={category.id} value={category.id}>
                              {category.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="grid gap-2">
                      <Label>Nome</Label>
                      <Input
                        name="name"
                        value={editingItem?.name || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid gap-2">
                      <Label>Descrizione</Label>
                      <Input
                        name="description"
                        value={editingItem?.description || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid gap-2">
                      <Label>Unità di Misura</Label>
                      <Input
                        name="unit"
                        value={editingItem?.unit || ''}
                        onChange={handleInputChange}
                      />
                    </div>

                    <div className="grid grid-cols-2 gap-4">
                      <div className="grid gap-2">
                        <Label>Quantità</Label>
                        <Input
                          type="number"
                          name="quantity"
                          value={editingItem?.quantity || 0}
                          onChange={handleInputChange}
                          min="0"
                          step="0.01"
                        />
                      </div>

                      <div className="grid gap-2">
                        <Label>Prezzo Unitario</Label>
                        <Input
                          type="number"
                          name="price"
                          value={editingItem?.price || 0}
                          onChange={handleInputChange}
                          min="0"
                          step="0.01"
                        />
                      </div>
                    </div>

                    <div className="grid gap-2">
                      <Label>Totale</Label>
                      <Input
                        type="number"
                        value={(editingItem?.quantity || 0) * (editingItem?.price || 0)}
                        readOnly
                        disabled
                      />
                    </div>
                  </div>

                  <DialogFooter>
                    <Button
                      variant="outline"
                      onClick={() => {
                        setIsEditModalOpen(false);
                        setEditingItem(null);
                      }}
                    >
                      Annulla
                    </Button>
                    <Button onClick={handleEditSave}>
                      Salva Modifiche
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>

              {/* Delete Confirmation Modal */}
              <Dialog open={isDeleteModalOpen} onOpenChange={setIsDeleteModalOpen}>
                <DialogContent>
                  <DialogHeader>
                    <DialogTitle>Conferma Eliminazione</DialogTitle>
                    <DialogDescription>
                      Sei sicuro di voler eliminare questo elemento? Questa azione non può essere annullata.
                    </DialogDescription>
                  </DialogHeader>

                  {itemToDelete && (
                    <div className="py-4">
                      <div className="grid grid-cols-2 gap-4">
                        <div>
                          <Label className="font-semibold">Categoria</Label>
                          <p>{categories.find(c => c.id === itemToDelete.category)?.name}</p>
                        </div>
                        <div>
                          <Label className="font-semibold">Nome</Label>
                          <p>{itemToDelete.name}</p>
                        </div>
                        <div>
                          <Label className="font-semibold">Prezzo</Label>
                          <p>{formatCurrency(itemToDelete.price)}</p>
                        </div>
                        <div>
                          <Label className="font-semibold">Totale</Label>
                          <p>{formatCurrency(itemToDelete.total)}</p>
                        </div>
                      </div>
                    </div>
                  )}

                  <DialogFooter>
                    <Button
                      variant="outline"
                      onClick={() => {
                        setIsDeleteModalOpen(false);
                        setItemToDelete(null);
                      }}
                    >
                      Annulla
                    </Button>
                    <Button
                      variant="destructive"
                      onClick={() => handleDeleteItem(itemToDelete._id)}
                    >
                      Elimina
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>

              {/* Filter Dialog */}
              <Dialog open={isFilterDialogOpen} onOpenChange={setIsFilterDialogOpen}>
                <DialogContent className="sm:max-w-[425px]">
                  <DialogHeader>
                    <DialogTitle>Filtri</DialogTitle>
                    <DialogDescription>
                      Applica i filtri per raffinare i risultati
                    </DialogDescription>
                  </DialogHeader>

                  <div className="grid gap-4 py-4">
                    <div className="grid gap-2">
                      <Label>Categoria</Label>
                      <Select
                        value={filters.category}
                        onValueChange={(value) => handleFilterChange('category', value)}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Tutte le categorie" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="all">Tutte le categorie</SelectItem>
                          {categories.map((category) => (
                            <SelectItem key={category.id} value={category.id}>
                              {category.name}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="grid gap-2">
                      <Label>Quantità</Label>
                      <Select
                        value={filters.quantity}
                        onValueChange={(value) => handleFilterChange('quantity', value)}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Tutte le quantità" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="all">Tutte le quantità</SelectItem>
                          {quantityRanges.map((range, index) => (
                            <SelectItem key={index} value={`${range.min}-${range.max}`}>
                              {range.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>

                    <div className="grid gap-2">
                      <Label>Prezzo</Label>
                      <Select
                        value={filters.price}
                        onValueChange={(value) => handleFilterChange('price', value)}
                      >
                        <SelectTrigger>
                          <SelectValue placeholder="Tutti i prezzi" />
                        </SelectTrigger>
                        <SelectContent>
                          <SelectItem value="all">Tutti i prezzi</SelectItem>
                          {priceRanges.map((range, index) => (
                            <SelectItem key={index} value={`${range.min}-${range.max}`}>
                              {range.label}
                            </SelectItem>
                          ))}
                        </SelectContent>
                      </Select>
                    </div>
                  </div>

                  <DialogFooter>
                    <Button variant="outline" onClick={resetFilters}>
                      Reset
                    </Button>
                    <Button onClick={() => setIsFilterDialogOpen(false)}>
                      Applica
                    </Button>
                  </DialogFooter>
                </DialogContent>
              </Dialog>
            </>
          )}
        </div>
      )}
    </>
  );
};

export default Accounting;