import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import ArchiveScreen from '../../../components/admin/archive/index'

const Archive = () => {
  return <>
    <Layout>
      <ArchiveScreen />
    </Layout>
  </>
}

export default Archive