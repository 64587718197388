import { createJsonTypeInstance } from './index';
const axios = createJsonTypeInstance();

export const fetchUtenti = async (companyId, currentPage, limit) => {
  try {
    const response = await axios.get(`/users/company/${companyId}?page=${currentPage}&limit=${limit}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const updateUser = async (id, data) => {
  try {
    const response = await axios.put(`/users/${id}`, data);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const deleteUser = async (id) => {
  try {
    const response = await axios.delete(`/users/${id}`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const updateUserPermissions = async (id, permissions) => {
  try {
    // Convert the plain object to key-value pairs that Mongoose can handle
    const permissionsData = {};
    Object.entries(permissions).forEach(([key, value]) => {
      if (typeof value === 'boolean') {
        permissionsData[key] = value;
      }
    });

    const response = await axios.put(`/users/${id}/permissions`, {
      permissions: permissionsData
    });
    return response;
  } catch (error) {
    console.error('Error in updateUserPermissions:', error);
    throw error.response || error;
  }
};

export const fetchUserPermissions = async (userId) => {
  try {
    const response = await axios.get(`/users/${userId}`);
    // Ensure we return the permissions object, with a default empty object if none exists
    return {
      data: {
        user: {
          permissions: response.data?.user?.permissions || {}
        }
      }
    };
  } catch (error) {
    console.error('Error fetching permissions:', error);
    throw error.response || error;
  }
};

export const inviteUser = async (userData) => {
  try {
    const response = await axios.post('/users/invite', userData);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const resendInvitation = async (userId) => {
  try {
    const response = await axios.post(`/users/${userId}/resend-invitation`);
    return response;
  } catch (error) {
    throw error.response;
  }
};

export const registerInvitedUser = async (registrationData) => {
  try {
    // Ensure all required fields are present
    if (!registrationData.username || !registrationData.password || !registrationData.invitationToken) {
      throw new Error('Username, password, and invitation token are required');
    }

    const response = await axios.post('/users/register', {
      username: registrationData.username,
      password: registrationData.password,
      invitationToken: registrationData.invitationToken
    });
    
    return response.data;
  } catch (error) {
    console.error('Registration error:', error);
    // Return a more structured error object
    throw {
      message: error.response?.data?.error || error.message || 'Registration failed',
      status: error.response?.status || 500,
      details: error.response?.data || error
    };
  }
};