import React, { useState, useEffect } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { useForm } from "react-hook-form";
import { Button } from '../../../../ui/button';
import { Card } from '../../../../ui/card';
import { Label } from '../../../../ui/label';
import { ScrollArea } from "../../../../ui/scroll-area";
import { Plus, Pencil } from 'lucide-react';
import { toast } from 'react-toastify';
import { getSingleSupplier, updateSupplier } from '../../../../../apis/supplier';
import { Input } from '../../../../ui/input';
import {
  Dialog,
  DialogContent,
  DialogHeader,
  DialogTitle,
  DialogFooter,
  DialogDescription
} from '../../../../ui/dialog';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from "../../../../ui/table";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "../../../../ui/select";

const AddSupplierForm = ({ register }) => {
  return (
    <div className="space-y-6 p-2">
      {/* Main Data */}
      <div className="space-y-4">
        <h3 className="font-semibold">Dati Principali</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="businessName">Ragione Sociale</Label>
            <Input
              {...register("businessName")}
              id="businessName"
              placeholder="Inserisci la ragione sociale"
              required
            />
          </div>
          <div>
            <Label htmlFor="internalCode">Codice Interno</Label>
            <Input
              {...register("internalCode")}
              id="internalCode"
              placeholder="Inserisci il codice interno"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="vatNumber">Partita IVA</Label>
            <Input
              {...register("vatNumber")}
              id="vatNumber"
              placeholder="Inserisci la partita IVA"
            />
          </div>
          <div>
            <Label htmlFor="taxId">Codice Fiscale</Label>
            <Input
              {...register("taxId")}
              id="taxId"
              placeholder="Inserisci il codice fiscale"
            />
          </div>
        </div>
      </div>

      {/* Position */}
      <div className="space-y-4">
        <h3 className="font-semibold">Posizione</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="address">Indirizzo</Label>
            <Input
              {...register("address")}
              id="address"
              placeholder="Inserisci l'indirizzo"
            />
          </div>
          <div>
            <Label htmlFor="city">Città</Label>
            <Input
              {...register("city")}
              id="city"
              placeholder="Inserisci la città"
            />
          </div>
        </div>
        <div className="grid grid-cols-3 gap-4">
          <div>
            <Label htmlFor="postalCode">CAP</Label>
            <Input
              {...register("postalCode")}
              id="postalCode"
              placeholder="Inserisci il CAP"
            />
          </div>
          <div>
            <Label htmlFor="province">Provincia</Label>
            <Input
              {...register("province")}
              id="province"
              placeholder="Inserisci la provincia"
            />
          </div>
          <div>
            <Label htmlFor="country">Nazione</Label>
            <Input
              {...register("country")}
              id="country"
              placeholder="Inserisci la nazione"
            />
          </div>
        </div>
      </div>

      {/* Contacts */}
      <div className="space-y-4">
        <h3 className="font-semibold">Contatti</h3>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="email">Email</Label>
            <Input
              {...register("email")}
              type="email"
              id="email"
              placeholder="Inserisci l'email"
            />
          </div>
          <div>
            <Label htmlFor="pec">PEC</Label>
            <Input
              {...register("pec")}
              type="email"
              id="pec"
              placeholder="Inserisci la PEC"
            />
          </div>
        </div>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label htmlFor="telephone">Telefono</Label>
            <Input
              {...register("telephone")}
              id="telephone"
              placeholder="Inserisci il telefono"
            />
          </div>
          <div>
            <Label htmlFor="website">Sito Web</Label>
            <Input
              {...register("website")}
              id="website"
              placeholder="Inserisci il sito web"
            />
          </div>
        </div>
      </div>

      {/* Extra */}
      <div className="space-y-4">
        <h3 className="font-semibold">Extra</h3>
        <div>
          <Label htmlFor="notes">Note</Label>
          <Input
            {...register("notes")}
            id="notes"
            placeholder="Inserisci eventuali note"
          />
        </div>
      </div>
    </div>
  );
};

const SupplierDetails = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const [supplier, setSupplier] = useState(null);
  const [isAddBalanceOpen, setIsAddBalanceOpen] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [balanceEntries, setBalanceEntries] = useState([]);
  const [newEntry, setNewEntry] = useState({
    date: new Date().toISOString().split('T')[0],
    description: '',
    type: 'debito',
    amount: ''
  });

  const { register, handleSubmit, reset } = useForm();

  useEffect(() => {
    const fetchSupplier = async () => {
      try {
        const response = await getSingleSupplier(id);
        setSupplier(response.data.supplier);
        setBalanceEntries([]);
      } catch (error) {
        toast.error('Errore nel recupero dei dati del fornitore');
        navigate('/suppliers');
      }
    };

    fetchSupplier();
  }, [id, navigate]);

  const handleEdit = async (data) => {
    try {
      const payload = {
        ...data,
        companyId: supplier.companyId,
      };

      const response = await updateSupplier(supplier._id, payload);
      if (response.status === 200) {
        toast.success("Fornitore aggiornato con successo!");
        setIsEditModalOpen(false);
        const updatedSupplier = await getSingleSupplier(id);
        setSupplier(updatedSupplier.data.supplier);
      }
    } catch (error) {
      console.error(error);
      toast.error("Errore nel salvataggio dei dati");
    }
  };

  const handleAddBalance = async () => {
    if (!newEntry.description || !newEntry.amount) {
      toast.error('Compila tutti i campi obbligatori');
      return;
    }

    try {
      const updatedEntries = [...balanceEntries, {
        ...newEntry,
        amount: parseFloat(newEntry.amount),
        id: Date.now()
      }];
      setBalanceEntries(updatedEntries);
      setIsAddBalanceOpen(false);
      setNewEntry({
        date: new Date().toISOString().split('T')[0],
        description: '',
        type: 'debito',
        amount: ''
      });
      toast.success('Movimento aggiunto con successo');
    } catch (error) {
      toast.error('Errore durante il salvataggio del movimento');
    }
  };

  const calculateBalance = (entries) => {
    return entries.reduce((acc, entry) => {
      const amount = parseFloat(entry.amount);
      return entry.type === 'debito' ? acc - amount : acc + amount;
    }, 0);
  };

  if (!supplier) {
    return <div>Caricamento...</div>;
  }

  return (
    <div className="p-6 space-y-6">
      <div className="flex justify-between items-center">
        <h1 className="text-2xl font-bold">{supplier.businessName}</h1>
        <Button onClick={() => {
          reset(supplier);
          setIsEditModalOpen(true);
        }}>
          <Pencil className="w-4 h-4 mr-2" />
          Modifica
        </Button>
      </div>

      {/* Main Data Section */}
      <Card className="p-4">
        <h2 className="text-lg font-semibold mb-4">Dati principali</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label>Ragione Sociale</Label>
            <div>{supplier.businessName}</div>
          </div>
          <div>
            <Label>Codice Interno</Label>
            <div>{supplier.internalCode}</div>
          </div>
          <div>
            <Label>Partita IVA</Label>
            <div>{supplier.vatNumber}</div>
          </div>
          <div>
            <Label>Codice Fiscale</Label>
            <div>{supplier.taxId}</div>
          </div>
        </div>
      </Card>

      {/* Position Section */}
      <Card className="p-4">
        <h2 className="text-lg font-semibold mb-4">Posizione</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label>Indirizzo</Label>
            <div>{supplier.address}</div>
          </div>
          <div>
            <Label>Città</Label>
            <div>{supplier.city}</div>
          </div>
          <div>
            <Label>CAP</Label>
            <div>{supplier.postalCode}</div>
          </div>
          <div>
            <Label>Provincia</Label>
            <div>{supplier.province}</div>
          </div>
          <div>
            <Label>Nazione</Label>
            <div>{supplier.nation}</div>
          </div>
        </div>
      </Card>

      {/* Contacts Section */}
      <Card className="p-4">
        <h2 className="text-lg font-semibold mb-4">Contatti</h2>
        <div className="grid grid-cols-2 gap-4">
          <div>
            <Label>Email</Label>
            <div>{supplier.email}</div>
          </div>
          <div>
            <Label>PEC</Label>
            <div>{supplier.pec}</div>
          </div>
          <div>
            <Label>Telefono</Label>
            <div>{supplier.telephone}</div>
          </div>
          <div>
            <Label>Sito Web</Label>
            <div>{supplier.website}</div>
          </div>
        </div>
      </Card>

      {/* Balance Sheet Section */}
      <Card className="p-4">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-lg font-semibold">Bilancio fornitore</h2>
          <Button onClick={() => setIsAddBalanceOpen(true)}>
            Aggiungi nuovo
          </Button>
        </div>
        <div className="rounded-md border">
          <Table>
            <TableHeader>
              <TableRow>
                <TableHead>Numero</TableHead>
                <TableHead>Data</TableHead>
                <TableHead>Descrizione</TableHead>
                <TableHead className="text-right">Debito</TableHead>
                <TableHead className="text-right">Credito</TableHead>
                <TableHead className="text-right">Saldo</TableHead>
              </TableRow>
            </TableHeader>
            <TableBody>
              {balanceEntries.map((entry, index) => {
                const runningBalance = calculateBalance(balanceEntries.slice(0, index + 1));
                return (
                  <TableRow key={entry.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{new Date(entry.date).toLocaleDateString()}</TableCell>
                    <TableCell>{entry.description}</TableCell>
                    <TableCell className="text-right">
                      {entry.type === 'debito' ? `€${entry.amount.toFixed(2)}` : ''}
                    </TableCell>
                    <TableCell className="text-right">
                      {entry.type === 'credito' ? `€${entry.amount.toFixed(2)}` : ''}
                    </TableCell>
                    <TableCell
                      className={`text-right ${runningBalance > 0
                          ? 'text-green-600'
                          : runningBalance < 0
                            ? 'text-red-600'
                            : ''
                        }`}
                    >
                      {runningBalance > 0 ? '+' : ''}
                      {`€${runningBalance.toFixed(2)}`}
                    </TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </Card>

      {/* Add Balance Entry Modal */}
      <Dialog open={isAddBalanceOpen} onOpenChange={setIsAddBalanceOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle>Aggiungi Movimento</DialogTitle>
          </DialogHeader>
          <div className="space-y-4">
            <div>
              <Label>Data</Label>
              <Input
                type="date"
                value={newEntry.date}
                onChange={(e) => setNewEntry({ ...newEntry, date: e.target.value })}
              />
            </div>
            <div>
              <Label>Descrizione</Label>
              <Input
                value={newEntry.description}
                onChange={(e) => setNewEntry({ ...newEntry, description: e.target.value })}
                placeholder="Inserisci descrizione"
              />
            </div>
            <div>
              <Label>Tipo</Label>
              <Select
                value={newEntry.type}
                onValueChange={(value) => setNewEntry({ ...newEntry, type: value })}
              >
                <SelectTrigger className="w-full">
                  <SelectValue placeholder="Seleziona tipo" />
                </SelectTrigger>
                <SelectContent>
                  <SelectItem value="debito">Debito</SelectItem>
                  <SelectItem value="debito">Debito</SelectItem>
                  <SelectItem value="credito">Credito</SelectItem>
                </SelectContent>
              </Select>
            </div>
            <div>
              <Label>Importo</Label>
              <Input
                type="number"
                step="0.01"
                value={newEntry.amount}
                onChange={(e) => setNewEntry({ ...newEntry, amount: e.target.value })}
                placeholder="0.00"
              />
            </div>
          </div>
          <DialogFooter>
            <Button variant="outline" onClick={() => setIsAddBalanceOpen(false)}>
              Annulla
            </Button>
            <Button onClick={handleAddBalance}>
              Salva
            </Button>
          </DialogFooter>
        </DialogContent>
      </Dialog>

      {/* Edit Supplier Modal */}
      <Dialog open={isEditModalOpen} onOpenChange={setIsEditModalOpen}>
        <DialogContent className="sm:max-w-4xl max-h-[95vh]">
          <DialogHeader>
            <DialogTitle>Modifica Fornitore</DialogTitle>
            <DialogDescription>
              Modifica i dettagli del fornitore qui sotto.
            </DialogDescription>
          </DialogHeader>
          <form onSubmit={handleSubmit(handleEdit)}>
            <ScrollArea className="h-[calc(85vh-200px)] pr-4">
              <AddSupplierForm register={register} />
            </ScrollArea>
            <DialogFooter className="mt-6 border-t pt-4">
              <Button
                type="button"
                variant="outline"
                onClick={() => {
                  setIsEditModalOpen(false);
                  reset();
                }}
              >
                Annulla
              </Button>
              <Button type="submit">Aggiorna</Button>
            </DialogFooter>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default SupplierDetails;