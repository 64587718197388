import React, { useEffect, useState, useMemo } from "react";
import { useForm, Controller } from "react-hook-form";
import { Country, State, City } from "country-state-city";
import { useNavigate, useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { Pencil, Save } from "lucide-react";
import { fetchCompany, updateCompany } from "../../../../apis/CompanyDetail";
import { Button } from "../../../ui/button";
import { Card, CardContent, CardHeader, CardTitle } from "../../../ui/card";
import { Input } from "../../../ui/input";
import { Label } from "../../../ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../../ui/select";
import { Textarea } from "../../../ui/textarea";
import ImageController from "../../sharedComponent/ImageController";
import Spinner from "../../../Spinner";

const capitalizeWords = (str) => {
  return str
    .replace(/([A-Z0-9])/g, " $1")
    .replace(/^./, (str) => str.toUpperCase())
    .replace(/(inail|iban|inps)/gi, (match) => match.toUpperCase())
    .trim();
};

const getFieldConfig = (key) => {
  if (key === "country") return { type: "select", options: "countries" };
  if (key === "state") return { type: "select", options: "states" };
  if (key === "city") return { type: "select", options: "cities" };
  if (key.includes("description") || key.includes("comments")) return { type: "textarea" };
  if (key.includes("date")) return { type: "date" };
  if (key.includes("email")) return { type: "email" };
  if (key.includes("number") || key.includes("id")) return { type: "number" };
  return { type: "text" };
};

const translateName = (key) => {
  const italianLanguage = {
    companyName: "Ragione Sociale",
    address: "Indirizzo",
    state: "Regione",
    postalCode: "CAP",
    addressNote: "Note indirizzo",
    fax: "Fax",
    website: "Sito web",
    iban: "IBAN",
    nickName: "Soprannome",
    country: "Nazione",
    city: "Città",
    province: "Provincia",
    taxIdCode: "Partita IVA",
    businessEmail: "Email principale",
    secondaryEmail: "Email secondaria",
    pec: "PEC",
    telephoneNumber: "Telefono",
    mobileNumber: "Cellulare",
    typeOfBusiness: "Tipologia azienda",
    inpsPosition: "Posizione INPS",
    inailPosition: "Posizione INAIL",
    edilcassaPosition: "Posizione Cassa Edile / Edilcassa",
    bank: "Banca",
  };
  return italianLanguage[key] || capitalizeWords(key);
};

const Edit = () => {
  const navigate = useNavigate();
  const { id } = useParams();
  const [companyInfo, setCompanyInfo] = useState(null);
  const { control, handleSubmit, reset, setValue, watch } = useForm();
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const selectedCountry = watch("country");
  const selectedState = watch("state");

  useEffect(() => {
    const fetchCompanyInfo = async () => {
      try {
        const response = await fetchCompany(id);
        if (response.status === 200) {
          setCompanyInfo(response.data.company);
          reset(response.data.company);
        }
      } catch (error) {
        console.error("Errore nel recupero delle informazioni aziendali:", error);
        toast.error("Impossibile recuperare le informazioni aziendali");
      }
    };

    fetchCompanyInfo();
    setCountries(Country.getAllCountries());
  }, [id, reset]);

  useEffect(() => {
    if (selectedCountry) {
      setStates(State.getStatesOfCountry(selectedCountry));
      setCities([]);
      setValue("state", "");
      setValue("city", "");
    }
  }, [selectedCountry, setValue]);

  useEffect(() => {
    if (selectedState) {
      setCities(City.getCitiesOfState(selectedCountry, selectedState));
      setValue("city", "");
    }
  }, [selectedState, selectedCountry, setValue]);

  const onSubmit = async (data) => {
    try {
      const response = await updateCompany(companyInfo?._id, data);
      if (response.status === 200) {
        toast.success("Informazioni aggiornate con successo");
        navigate(-1);
      }
    } catch (error) {
      console.error("Errore nell'aggiornamento delle informazioni aziendali:", error);
      toast.error("Impossibile aggiornare le informazioni aziendali");
    }
  };

  const renderField = useMemo(() => (key) => {
    const { type, options } = getFieldConfig(key);
    const label = translateName(key);

    switch (type) {
      case "select":
        return (
          <Controller
            name={key}
            control={control}
            render={({ field }) => (
              <Select onValueChange={field.onChange} value={field.value}>
                <SelectTrigger className="w-full">
                  <SelectValue placeholder={`Seleziona ${label}`} />
                </SelectTrigger>
                <SelectContent>
                  {options === "countries" && countries.map((country) => (
                    <SelectItem key={country.isoCode} value={country.isoCode}>{country.name}</SelectItem>
                  ))}
                  {options === "states" && states.map((state) => (
                    <SelectItem key={state.isoCode} value={state.isoCode}>{state.name}</SelectItem>
                  ))}
                  {options === "cities" && cities.map((city) => (
                    <SelectItem key={city.name} value={city.name}>{city.name}</SelectItem>
                  ))}
                </SelectContent>
              </Select>
            )}
          />
        );
      case "textarea":
        return (
          <Controller
            name={key}
            control={control}
            render={({ field }) => (
              <Textarea {...field} placeholder={`Inserisci ${label}`} className="w-full" />
            )}
          />
        );
      default:
        return (
          <Controller
            name={key}
            control={control}
            render={({ field }) => (
              <Input {...field} type={type} placeholder={`Inserisci ${label}`} className="w-full" />
            )}
          />
        );
    }
  }, [control, countries, states, cities]);

  if (!companyInfo) {
    return <Spinner/>;
  }

  // Define the desired field order
  const fieldOrder = [
    "companyName",
    "address",
    "state",
    "postalCode",
    "addressNote",
    "fax",
    "website",
    "iban",
    "nickName",
    "country",
    "city",
    "province",
    "taxIdCode",
    "businessEmail",
    "secondaryEmail",
    "pec",
    "telephoneNumber",
    "mobileNumber",
    "typeOfBusiness",
    "inpsPosition",
    "inailPosition",
    "edilcassaPosition", // Position is now correct: after inailPosition and before bank
    "bank"
  ];

  return (
    <div className="container mx-auto px-4 py-8 bg-gray-50 min-h-screen">
      <Card className="w-full mx-auto">
        <CardHeader className="bg-gray-100 border-b">
          <CardTitle className="text-2xl font-bold flex items-center">
            <Pencil className="mr-2" size={24} /> Modifica Informazioni Aziendali
          </CardTitle>
        </CardHeader>
        <CardContent className="p-6">
          <form onSubmit={handleSubmit(onSubmit)} className="space-y-8">
            <div className="flex flex-col sm:flex-row gap-6 justify-center items-center mb-8">
              <ImageController
                label="Logo 1"
                name="logo1"
                control={control}
                rounded={false}
                defaultValue={companyInfo.logo1 ? `${process.env.REACT_APP_BACKEND_URL}/${companyInfo.logo1}` : ""}
              />
              <ImageController
                label="Logo 2"
                name="logo2"
                control={control}
                rounded={false}
                defaultValue={companyInfo.logo2 ? `${process.env.REACT_APP_BACKEND_URL}/${companyInfo.logo2}` : ""}
              />
            </div>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
              {fieldOrder
                .filter(key => key in companyInfo && !["_id", "__v", "adminId", "logo1", "logo2"].includes(key))
                .map((key) => (
                  <div key={key} className="w-full">
                    <Label htmlFor={key} className="mb-2 block">{translateName(key)}</Label>
                    {renderField(key)}
                  </div>
                ))}
            </div>
            <div className="flex justify-end mt-8">
              <Button type="submit" className="w-full sm:w-auto">
                <Save className="mr-2" size={16} /> Salva Modifiche
              </Button>
            </div>
          </form>
        </CardContent>
      </Card>
    </div>
  );
};

export default Edit;