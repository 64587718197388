import React, { useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { toast } from 'react-toastify';

export const usePermissionCheck = () => {
  const user = JSON.parse(localStorage.getItem('user') || '{}');
  const navigate = useNavigate();

  const checkPermission = (path) => {
    if (user.role === 'admin') return true;

    const route = path.split('/')[1]; // Get first part of path
    if (!route) return true; // Allow access to root

    if (!user.permissions?.[route]) {
      toast.error(`You don't have permission to access this section`);
      navigate('/dashboard');
      return false;
    }

    return true;
  };

  return { checkPermission };
};