import React, { useState } from 'react';
import { Sheet, SheetContent, SheetHeader, SheetTitle, SheetTrigger } from "../../ui/sheet";
import { Button } from "../../ui/button";
import { CircleHelp } from "lucide-react";

const helpContent = {
  "/": {
    title: "Dashboard",
    content: "Benvenuto nella dashboard principale. Qui puoi visualizzare una panoramica delle statistiche del progetto, le attività recenti e accedere rapidamente alle funzionalità chiave."
  },
  "/dashboard": {
    title: "Dashboard",
    content: "Questa è la dashboard principale. Offre una vista d'insieme delle tue attività, progetti in corso e statistiche importanti."
  },
  "/calendar": {
    title: "Calendario",
    content: `Questa area ti permette di gestire e visualizzare i tuoi impegni in modo semplice e organizzato. Grazie a diverse opzioni di visualizzazione e funzionalità intuitive, puoi pianificare e monitorare le attività aziendali in modo efficiente. Ecco cosa puoi fare:

1. Navigare nel Calendario.
Puoi esplorare il calendario attraverso il navigatore e visualizzare i tuoi impegni in base a diversi periodi: Mese, Settimana o Giorno. Hai sempre la flessibilità di scegliere la vista più adatta alle tue necessità.

2. Consultare la tua Agenda.
Nella sezione Agenda puoi visualizzare in dettaglio tutti gli eventi e gli impegni programmati. Uno strumento utile per avere una panoramica chiara delle tue attività.

3. Aggiungere Eventi.
Cliccando su un giorno specifico nel calendario, potrai creare un nuovo evento inserendo:

• Nome dell'evento
• Data di inizio e di fine
• Descrizione dell'evento
• Colore per organizzare visivamente i tuoi impegni.`
  },
  "/employees": {
    title: "Dipendenti",
    content: `Qui puoi gestire in modo completo ed efficiente il personale della tua azienda. Dalla creazione di nuovi dipendenti al monitoraggio di presenze, assenze, retribuzioni e formazione, questa sezione ti offre gli strumenti per mantenere tutto sotto controllo. Ecco cosa puoi fare:

1. Tutti i Dipendenti.
In questa tab puoi vedere la lista completa di tutti i dipendenti aziendali, con informazioni essenziali a portata di mano. Clicca sul pulsante Aggiungi per creare manualmente nuovi dipendenti o importare una lista. Usa il pulsante Esporta per esportare l'elenco dei dipendenti e la barra di ricerca per trovare rapidamente chi ti serve.

2. Foglio Presenze.
Qui puoi gestire le presenze dei dipendenti, selezionando chi ha lavorato e inserendo le presenze. Usa i pulsanti per esportare il foglio presenze in PDF e mantenere i dati sempre aggiornati.

3. Formazione.
Monitora gli attestati di formazione dei dipendenti. Puoi aggiungere certificati cliccando su Aggiungi certificato e utilizzare la barra di ricerca per trovare facilmente i documenti.

4. Assenze.
Registra e gestisci le assenze dei dipendenti, aggiungendo nuovi dati tramite il pulsante Aggiungi assenza. Utilizza la barra di ricerca per trovare rapidamente i dettagli.

5. Retribuzione.
Gestisci gli stipendi mensili dei tuoi dipendenti. Usa il navigatore dei mesi per selezionare il periodo e calcola gli stipendi in modo automatico con il pulsante Calcola stipendio.

6. Pianificazione.
Organizza e pianifica i turni di lavoro dei dipendenti. Naviga tra i mesi e gestisci i turni settimanali o mensili, tutto facilmente consultabile tramite il navigatore e la barra di ricerca.`
  },
  "/clients": {
    title: "Clienti",
    content: `Qui puoi gestire in modo completo i tuoi clienti, organizzando e filtrando le informazioni per migliorare l'efficienza nella gestione delle relazioni commerciali. Ecco cosa puoi fare in questa sezione:

1. Aggiungere Nuovi Clienti.
Clicca sul pulsante Aggiungi per creare nuovi profili cliente. Puoi inserire tutte le informazioni necessarie per aziende o privati, così da avere un database aggiornato e organizzato.

2. Importare o Esportare Clienti.
Con il pulsante Importa puoi caricare rapidamente una lista di clienti già esistente, mentre con Esporta hai la possibilità di salvare la lista in Excel o PDF, per consultarla o condividerla in modo pratico.

3. Filtrare i Clienti.
Usa il pulsante Filtri per selezionare solo i clienti che ti interessano, separando le aziende dai privati. Questo ti consente di trovare rapidamente i dati più rilevanti per le tue esigenze.

4. Ricerca Rapida.
La barra di ricerca ti permette di cercare un cliente specifico in modo rapido e preciso, senza dover scorrere lunghi elenchi.`
  },
  "/suppliers": {
    title: "Fornitori",
    content: `In questa area puoi gestire tutti i tuoi fornitori in maniera semplice ed efficiente, assicurandoti di avere sempre sotto controllo le informazioni necessarie per ottimizzare le relazioni con i tuoi partner commerciali. Ecco cosa puoi fare:

1. Aggiungere Nuovi Fornitori.
Clicca sul pulsante Aggiungi per inserire manualmente nuovi fornitori nel sistema, con tutte le informazioni utili per una gestione completa e accurata.

2. Importare o Esportare Fornitori.
Utilizza il pulsante Importa per caricare una lista preesistente di fornitori, oppure esporta l'elenco corrente in Excel o PDF cliccando su Esporta, così da avere un registro sempre aggiornato a portata di mano.

3. Ricerca Rapida.
Grazie alla barra di ricerca, puoi individuare rapidamente un fornitore specifico, senza dover scorrere l'intera lista.`
  },
  "/computi-metrici": {
    title: "Computi Metrici",
    content: `In questa area puoi gestire i computi metrici in modo semplice e veloce. Che tu debba creare un nuovo computo o consultare quelli esistenti, qui troverai tutto ciò di cui hai bisogno. Ecco come puoi utilizzare questa sezione:

1. Creare un nuovo Computo Metrico.
Cliccando sul pulsante Aggiungi, puoi inserire un nuovo computo metrico. Dovrai specificare il committente e aggiungere una descrizione dettagliata del computo. Una volta salvato, il documento sarà sempre disponibile per future consultazioni o modifiche.

2. Ricerca dei Computi Metrici.
Se hai bisogno di consultare un computo metrico già esistente, puoi utilizzare la barra di ricerca per trovare velocemente il documento che ti serve. Puoi cercare in base al committente, alla descrizione o altri dettagli rilevanti.`
  },
  "/flotta": {
    title: "Flotta",
    content: `Gestisci i veicoli della tua azienda in maniera semplice ed efficace. Qui puoi tenere traccia di tutti i mezzi a tua disposizione e avere sempre sotto controllo le informazioni essenziali. Ecco cosa puoi fare:

1. Aggiungere Nuovi Veicoli.
Clicca sul pulsante Aggiungi per inserire un nuovo veicolo nella flotta, specificando tutte le informazioni rilevanti come marca, modello, anno di immatricolazione, e altri dettagli necessari per una gestione completa.

2. Ricerca Veloce.
Utilizza la barra di ricerca per trovare velocemente un veicolo specifico all'interno della tua flotta. Ti basta inserire il nome o altri dettagli del veicolo per trovarlo istantaneamente.`
  },
  "/profilio": {
    title: "Profilo",
    content: "Visualizza e modifica le informazioni del tuo profilo personale, incluse le impostazioni dell'account e le preferenze."
  },
  "/utenti": {
    title: "Utenti",
    content: "Gestisci gli utenti del sistema. Puoi aggiungere nuovi utenti, modificare i permessi e gestire gli accessi."
  },
  "/magazzino": {
    title: "Magazzino",
    content: `Qui puoi gestire l'inventario dei prodotti e i luoghi di deposito della tua azienda in modo efficiente. Organizza e tieni traccia di tutto ciò che hai nei magazzini, semplificando il monitoraggio delle risorse aziendali. La sezione si divide in due tab principali:

1. Inventario.
In questa tab trovi la lista di tutti i prodotti presenti nel magazzino. Ecco cosa puoi fare:

• Aggiungi nuovi prodotti cliccando sul pulsante Aggiungi, potrai inserire rapidamente i dettagli dei nuovi articoli da monitorare.
• Ricerca Veloce: Utilizza la barra di ricerca per trovare i prodotti specifici in pochi secondi.
• Filtra per Quantità e Luogo di Deposito: Grazie al pulsante Filtri, puoi visualizzare solo i prodotti che ti interessano, in base alla loro quantità disponibile o al luogo di deposito.

2. Luoghi di Deposito.
Se la tua azienda gestisce più magazzini o luoghi di deposito, qui potrai monitorarli facilmente. Trovi la lista completa dei luoghi, e puoi accedere alle informazioni specifiche di ogni magazzino per una gestione precisa e cEntratalizzata.
`
  },
  "/movimenti": {
    title: "Movimenti",
    content: `Questa area ti offre tutti gli strumenti necessari per monitorare e gestire in modo dettagliato e personalizzato i movimenti bancari della tua impresa. Grazie a funzionalità semplici ma potenti, puoi tenere sotto controllo le Entratate e le uscite, assicurandoti una gestione finanziaria precisa e accurata. Ecco come puoi sfruttare al meglio questa sezione:

1. Aggiungere Movimenti.
Cliccando il pulsante Aggiungi puoi inserire manualmente ogni singolo movimento bancario, specificando l'importo e la natura (Entrata o uscita). Questo ti consente di mantenere un tracciamento accurato dei flussi finanziari.

2. Importare Movimenti.
Se preferisci automatizzare il processo, puoi importare direttamente un file contenente i movimenti bancari (ad esempio, in formato CSV). Per fare ciò, devi cliccare il pulsante Importa e scegliere il file desiderato. Questo velocizza l'inserimento dei dati, soprattutto quando gestisci grandi volumi di transazioni.

3. Cerca e Filtra.
Hai bisogno di trovare una voce specifica? Usa le funzioni di ricerca o i filtri per cercare movimenti per data, importo o descrizione, permettendoti di accedere rapidamente alle informazioni di cui hai bisogno.

4. Sintesi Finanziaria.
In questa sezione puoi visualizzare i totali delle Entratate e delle uscite, l'utile di impresa e l'indice di redditività (%), dandoti una chiara panoramica delle prestazioni finanziarie della tua attività.`
  },
  "/chat": {
    title: "Chat",
    content: "Comunica in tempo reale con il tuo team, i clienti o i fornitori attraverso questa funzionalità di chat integrata."
  },
  "/assistenza": {
    title: "Assistenza",
    content: "Qui puoi richiedere supporto, visualizzare le FAQ o contattare il nostro team di assistenza per qualsiasi problema o domanda."
  },
  "/impostazioni": {
    title: "Impostazioni",
    content: "Configura le impostazioni generali dell'applicazione, incluse le preferenze di notifica, la sicurezza e altre opzioni personalizzabili."
  },
  "/ddt": {
    title: "DDT",
    content: `Questa area ti consente di gestire i documenti di trasporto (DDT) in modo semplice ed efficace. Che tu debba creare un nuovo documento o cercare quelli esistenti, qui troverai tutti gli strumenti necessari per mantenere la tua documentazione sempre in ordine. Ecco come puoi utilizzare questa sezione:

1. Creare un nuovo Documento di Trasporto.
Cliccando sul pulsante Aggiungi, puoi redigere un nuovo DDT inserendo tutte le informazioni necessarie, come i dettagli del mittente, del destinatario, la descrizione dei beni e le date di consegna. Una volta completato, il documento sarà salvato e potrà essere consultato o modificato in qualsiasi momento.

2. Ricerca e Gestione dei DDT.
Se hai bisogno di consultare un DDT già esistente, puoi utilizzare la barra di ricerca per trovare rapidamente il documento che stai cercando. Cerca i risultati per numero di documento, data o altri parametri per un accesso immediato e preciso.`
  },
  "/preventivi": {
    title: "Preventivi",
    content: `Qui puoi gestire facilmente i tuoi preventivi e mantenere traccia di tutti i documenti rilevanti. Con pochi clic puoi creare, cercare e filtrare i preventivi per avere sempre sotto controllo le tue offerte commerciali. Ecco come usare al meglio questa sezione:

1. Creare un nuovo Preventivo.
Cliccando sul pulsante Aggiungi, accederai direttamente alla schermata per la creazione di un nuovo preventivo. Compila i dettagli richiesti e salva il documento per mantenerlo disponibile nelle tue future consultazioni.

2. Ricerca e Filtraggio dei Preventivi.
Hai bisogno di trovare un preventivo specifico? Usa la barra di ricerca per cercare per parole chiave o utilizza il pulsante Filtri per affinare la ricerca in base a data, cliente o numero del preventivo.`
  },
  "/cantieri": {
    title: "Cantieri",
    content: `Questa area ti permette di gestire tutti i tuoi cantieri in modo strutturato e completo. Puoi tenere traccia di ogni aspetto del progetto, dai documenti alla contabilità, fino alla produzione e ai rapportini. Ecco come usare al meglio questa sezione:

1. Creare un nuovo Cantiere.
Cliccando sul pulsante Aggiungi, accederai a una schermata suddivisa in diverse tab, che ti permetteranno di gestire ogni dettaglio del cantiere. Le sezioni principali sono:

• Informazioni: per inserire i dati generali del cantiere.
• Documenti: per caricare e gestire i documenti relativi al progetto.
• Contabilità: per monitorare gli stati di avanzamento dei lavori.
• Produzione: per compilare il giornale dei lavori.
• Rapportini: per gestire i rapportini giornalieri del cantiere.
• Media: per caricare e visualizzare foto e video del cantiere.

2. Ricerca dei Cantieri.
Usa la barra di ricerca per trovare rapidamente un cantiere specifico in base ai criteri che preferisci.`
  },
  "/prezzari": {
    title: "Prezzari",
    content: "Accedi e gestisci i prezzari regionali. Questa sezione ti permette di consultare e utilizzare i prezzi standard per vari materiali e servizi."
  },
  "/capitoli": {
    title: "Capitoli",
    content: "Organizza e gestisci i capitoli dei tuoi progetti o dei prezzari. Questa struttura ti aiuta a categorizzare e trovare facilmente le informazioni necessarie."
  },
  "/categoria": {
    title: "Categoria",
    content: "Gestisci le categorie utilizzate per organizzare materiali, servizi o altre risorse all'interno del sistema."
  },
  "/description": {
    title: "Descrizione",
    content: "Qui puoi visualizzare o modificare le descrizioni dettagliate di vari elementi del sistema, come materiali, servizi o processi."
  },
  "/famiglia": {
    title: "Famiglia",
    content: "Organizza e gestisci le famiglie di prodotti o servizi. Questa categorizzazione ti aiuta a strutturare meglio il tuo inventario o offerta."
  },
  "/regioni": {
    title: "Regioni",
    content: "Gestisci le informazioni relative alle diverse regioni. Utile per organizzare progetti, prezzari o altre informazioni su base geografica."
  },
  "/subscription": {
    title: "Abbonamento",
    content: "Visualizza e gestisci i dettagli del tuo abbonamento. Puoi verificare il piano attuale, aggiornare o modificare le opzioni di abbonamento."
  },
  "/agency": {
    title: "Gestione Azienda",
    content: `In quest'area puoi gestire tutte le informazioni fondamentali della tua azienda. Dalla modifica dei dettagli aziendali alla gestione dei documenti legali, fino alla struttura organizzativa e alla proprietà, tutto è a portata di mano. Ecco cosa puoi fare:

1. Modificare le Informazioni Aziendali.
Cliccando sul pulsante Modifica, potrai inserire o aggiornare i dati principali della tua azienda. Mantieni sempre aggiornate queste informazioni per una gestione efficace.

2. Gestire i Documenti Legali.
Nella tab Documenti, puoi caricare e organizzare tutti i documenti legali dell'azienda, garantendo che siano sempre facilmente accessibili e archiviati correttamente.

3. Creare l'Organigramma Aziendale.
Utilizza la tab Struttura per creare e modificare l'organigramma aziendale. Definisci ruoli e responsabilità per mantenere una chiara organizzazione interna.

4. Gestire la Proprietà dell'Azienda.
Nella tab Proprietà, puoi inserire i dettagli relativi all'amministratore, ai soci e al consiglio di amministrazione. Assicurati di mantenere aggiornate le informazioni sulla governance aziendale.`
  },
};

const HelpSheet = ({ route }) => {
  const [open, setOpen] = useState(false);
  const { title, content } = helpContent[route] || {
    title: "Fornitori",
    content: `In questa area puoi gestire tutti i tuoi fornitori in maniera semplice ed efficiente, assicurandoti di avere sempre sotto controllo le informazioni necessarie per ottimizzare le relazioni con i tuoi partner commerciali. Ecco cosa puoi fare:

1. Aggiungere Nuovi Fornitori.
Clicca sul pulsante Aggiungi per inserire manualmente nuovi fornitori nel sistema, con tutte le informazioni utili per una gestione completa e accurata.

2. Importare o Esportare Fornitori.
Utilizza il pulsante Importa per caricare una lista preesistente di fornitori, oppure esporta l'elenco corrente in Excel o PDF cliccando su Esporta, così da avere un registro sempre aggiornato a portata di mano.

3. Ricerca Rapida.
Grazie alla barra di ricerca, puoi individuare rapidamente un fornitore specifico, senza dover scorrere l'intera lista.`
  }

  return (
    <Sheet open={open} onOpenChange={setOpen}>
      <SheetTrigger asChild>
        <Button variant="ghost" size="icon">
          <CircleHelp className="h-5 w-5" />
        </Button>
      </SheetTrigger>
      <SheetContent side="right" className="w-[300px] sm:w-[400px] flex flex-col">
        <SheetHeader className=" border-b pb-2">
          <SheetTitle className="text-xl font-semibold">{title}</SheetTitle>
        </SheetHeader>
        <div className="flex-grow overflow-y-auto pr-6">
          <p className="whitespace-pre-wrap text-sm leading-relaxed">
            {content.split('\n\n').map((paragraph, index) => (
              <React.Fragment key={index}>
                {paragraph.match(/^\d+\./) ? (
                  <>
                    <span className="block mt-2">
                      {paragraph.split('. ')[0]}. <strong>{paragraph.split('. ')[1].split('.')[0]}.</strong>
                    </span>
                    <span className="block mb-4">
                      {paragraph.split('. ').slice(1).join('. ').split('.').slice(1).join('.').trim()}
                    </span>
                  </>
                ) : paragraph.includes('•') ? (
                  <ul className="list-disc pl-5 mb-4">
                    {paragraph.split('•').filter(item => item.trim()).map((item, i) => (
                      <li key={i} className="mb-1">{item.trim()}</li>
                    ))}
                  </ul>
                ) : (
                  <span className="block mb-4">{paragraph}</span>
                )}
              </React.Fragment>
            ))}
          </p>
        </div>
        <div className="mt-auto pt-4 border-t space-y-2">
          <Button
            onClick={() => setOpen(false)}
            className="w-full"
          >
            Ho capito
          </Button>
          <Button
            variant="outline"
            className="w-full"
            onClick={() => window.open('https://glds.it/docs', '_blank')}
          >
            Guida utente
          </Button>
        </div>
      </SheetContent>
    </Sheet>
  );
};

export default HelpSheet;