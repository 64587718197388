import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { 
  Card,
  CardContent,
  CardDescription,
  CardHeader,
  CardTitle 
} from '../../ui/card';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableHeader,
  TableRow,
} from '../../ui/table';
import { Input } from '../../ui/input';
import { Button } from '../../ui/button';
import { toast } from 'react-toastify';
import { 
  Archive, 
  Search, 
  Building, 
  Eye, 
  ArchiveRestore, 
  CalendarDays 
} from 'lucide-react';
import { 
  getConstructionSites, 
  unarchiveConstructionSite 
} from '../../../apis/ConstructionSite';
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "../../ui/tooltip";
import Pagination from '../sharedComponent/Pgination';

const ArchiveScreen = () => {
  const [searchTerm, setSearchTerm] = useState('');
  const [sites, setSites] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [currentPage, setCurrentPage] = useState(1);
  const [totalPages, setTotalPages] = useState(1);
  const [limit, setLimit] = useState(10);
  const [totalItems, setTotalItems] = useState(0);

  useEffect(() => {
    fetchArchivedSites();
  }, [currentPage, limit, searchTerm]);

  const fetchArchivedSites = async () => {
    try {
      setIsLoading(true);
      const user = JSON.parse(localStorage.getItem('user'));
      const response = await getConstructionSites(user.companyId);
      
      // Filter archived sites and apply search
      const filteredSites = response.data.sites
        .filter(site => site.status === 'Archiviato')
        .filter(site => 
          !searchTerm || 
          site.name.toLowerCase().includes(searchTerm.toLowerCase()) ||
          site.client?.fullName?.toLowerCase().includes(searchTerm.toLowerCase()) ||
          site.address?.toLowerCase().includes(searchTerm.toLowerCase())
        );

      setSites(filteredSites);
      setTotalItems(filteredSites.length);
      setTotalPages(Math.ceil(filteredSites.length / limit));
    } catch (error) {
      console.error('Error fetching archived sites:', error);
      toast.error('Errore nel caricamento dei cantieri archiviati');
    } finally {
      setIsLoading(false);
    }
  };

  const handleSearch = (value) => {
    setSearchTerm(value);
    setCurrentPage(1);
  };

  const handleUnarchive = async (siteId) => {
    try {
      await unarchiveConstructionSite(siteId);
      toast.success('Cantiere ripristinato con successo');
      fetchArchivedSites();
    } catch (error) {
      toast.error(error.message || 'Errore durante il ripristino del cantiere');
    }
  };

  const formatDate = (dateString) => {
    return new Date(dateString).toLocaleDateString('it-IT', {
      year: 'numeric',
      month: 'short',
      day: 'numeric'
    });
  };

  // Calculate the paginated sites
  const getPaginatedSites = () => {
    const startIndex = (currentPage - 1) * limit;
    const endIndex = startIndex + limit;
    return sites.slice(startIndex, endIndex);
  };

  return (
    <div className="">
      <Card className='border-0'>
        <CardHeader>
          <CardTitle className="text-2xl flex items-center gap-2">
            <Archive className="w-6 h-6" />
            Cantieri Archiviati
          </CardTitle>
          <CardDescription>
            Visualizza e gestisci i cantieri archiviati
          </CardDescription>
        </CardHeader>
        <CardContent>
          <div className="mb-6">
            <div className="relative">
              <Search className="absolute left-2 top-3 h-4 w-4 text-gray-400" />
              <Input
                placeholder="Cerca per nome cantiere, committente o indirizzo..."
                value={searchTerm}
                onChange={(e) => handleSearch(e.target.value)}
                className="pl-8"
              />
            </div>
          </div>

          <div className="border rounded-lg">
            <Table>
              <TableHeader>
                <TableRow>
                  <TableHead>Nome Cantiere</TableHead>
                  <TableHead>Committente</TableHead>
                  <TableHead>Indirizzo</TableHead>
                  <TableHead>Data Archiviazione</TableHead>
                  <TableHead>Motivo</TableHead>
                  <TableHead className="text-right">Azioni</TableHead>
                </TableRow>
              </TableHeader>
              <TableBody>
                {isLoading ? (
                  <TableRow>
                    <TableCell colSpan={6} className="text-center py-8">
                      Caricamento...
                    </TableCell>
                  </TableRow>
                ) : getPaginatedSites().length > 0 ? (
                  getPaginatedSites().map((site) => (
                    <TableRow key={site._id}>
                      <TableCell className="font-medium">
                        <div className="flex items-center gap-2">
                          <Building className="w-4 h-4 text-gray-500" />
                          {site.name}
                        </div>
                      </TableCell>
                      <TableCell>{site.client?.fullName || site.client?.companyName}</TableCell>
                      <TableCell>{site.address}</TableCell>
                      <TableCell>
                        <div className="flex items-center gap-2">
                          <CalendarDays className="w-4 h-4 text-gray-500" />
                          {formatDate(site.archivedAt)}
                        </div>
                      </TableCell>
                      <TableCell>
                        <div className="max-w-xs truncate" title={site.archiveReason}>
                          {site.archiveReason || 'Nessun motivo specificato'}
                        </div>
                      </TableCell>
                      <TableCell className="text-right">
                        <div className="flex justify-end gap-2">
                          <TooltipProvider>
                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Link to={`/cantieri/${site._id}/informazioni`}>
                                  <Button variant="outline" size="icon">
                                    <Eye className="w-4 h-4" />
                                  </Button>
                                </Link>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Visualizza dettagli</p>
                              </TooltipContent>
                            </Tooltip>

                            <Tooltip>
                              <TooltipTrigger asChild>
                                <Button
                                  variant="outline"
                                  size="icon"
                                  onClick={() => handleUnarchive(site._id)}
                                >
                                  <ArchiveRestore className="w-4 h-4 text-green-500" />
                                </Button>
                              </TooltipTrigger>
                              <TooltipContent>
                                <p>Ripristina cantiere</p>
                              </TooltipContent>
                            </Tooltip>
                          </TooltipProvider>
                        </div>
                      </TableCell>
                    </TableRow>
                  ))
                ) : (
                  <TableRow>
                    <TableCell colSpan={6} className="text-center py-8">
                      Nessun cantiere archiviato trovato
                    </TableCell>
                  </TableRow>
                )}
              </TableBody>
            </Table>
          </div>

          {sites.length > 0 && (
            <div className="mt-4">
              <Pagination
                totalPages={totalPages}
                totalItems={totalItems}
                currentPage={currentPage}
                setCurrentPage={setCurrentPage}
                limit={limit}
                setLimit={setLimit}
                onPageChange={(page) => setCurrentPage(page)}
              />
            </div>
          )}
        </CardContent>
      </Card>
    </div>
  );
};

export default ArchiveScreen;