import React, { useState, useEffect, useCallback } from "react";
import { Calendar, momentLocalizer, Views } from "react-big-calendar";
import moment from "moment";
import "moment/locale/it";
import { ChevronLeft, ChevronRight, Calendar as CalendarIcon } from "lucide-react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import { AddEvent, getEvents, getEvent, updateEvent, deleteEvent } from "../../../apis/Event";
import { vehicleService } from "../../../services/vehicleService";
import { getConstructionSites } from "../../../apis/ConstructionSite";
import { fetchTrainings } from "../../../apis/training";
import EventModal from "./EventModels";
import { toast } from "react-toastify";
import { Card, CardContent } from "../../ui/card";
import { Button } from "../../ui/button";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "../../ui/select";
import HelpSheet from "../sharedComponent/HelpSheet";
import { getMedicalVisits } from "../../../apis/MedicalVisits";

import {
  fetchDocuments,
} from "../../../apis/Document";

moment.locale('it');
const localizer = momentLocalizer(moment);

const CalendarComponent = () => {
  const [modalIsOpen, setModalIsOpen] = useState(false);
  const [selectedEventId, setSelectedEventId] = useState(null);
  const [selectedEvent, setSelectedEvent] = useState({ color: "", description: "", start: null, end: null });
  const [events, setEvents] = useState([]);
  const [currentView, setCurrentView] = useState(Views.MONTH);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [companyId, setCompanyId] = useState(null);

  useEffect(() => {
    const user = JSON.parse(localStorage.getItem("user"));
    if (user?.companyId) {
      setCompanyId(user.companyId);
    }
  }, []);

  useEffect(() => {
    const params = new URLSearchParams(window.location.search);
    const viewParam = params.get('view');
    if (viewParam && Views[viewParam.toUpperCase()]) {
      setCurrentView(Views[viewParam.toUpperCase()]);
    }
  }, []);

  const fetchEventsAndDates = useCallback(async () => {
    if (!companyId) return;

    try {
      // Fetch regular events
      const eventResponse = await getEvents();
      const regularEvents = eventResponse.data.events?.map((event) => ({
        ...event,
        start: new Date(event.start),
        end: new Date(event.end),
        type: 'regular'
      })) || [];

      // Fetch construction sites
      const sitesResponse = await getConstructionSites();
      const constructionSiteEvents = sitesResponse.data.sites?.reduce((acc, site) => {
        const events = [];

        if (site.openingDate) {
          events.push({
            _id: `open_${site._id}`,
            title: `🏗️ Apertura: ${site.name}`,
            start: new Date(site.openingDate),
            end: new Date(site.openingDate),
            color: '#22c55e',
            description: `Apertura cantiere: ${site.name}`,
            type: 'construction',
            allDay: true
          });
        }

        if (site.closingDate) {
          events.push({
            _id: `close_${site._id}`,
            title: `🏁 Chiusura: ${site.name}`,
            start: new Date(site.closingDate),
            end: new Date(site.closingDate),
            color: '#ef4444',
            description: `Chiusura cantiere: ${site.name}`,
            type: 'construction',
            allDay: true
          });
        }

        return [...acc, ...events];
      }, []) || [];

      // Fetch vehicles
      const vehicles = await vehicleService.getAllVehicles(companyId);
      const vehicleEvents = vehicles.reduce((acc, vehicle) => {
        const events = [];

        if (vehicle.insuranceExpiryDate) {
          const expiryDate = new Date(vehicle.insuranceExpiryDate);
          const warningDate = new Date(expiryDate);
          warningDate.setDate(warningDate.getDate() - 30);

          events.push({
            _id: `insurance_warning_${vehicle._id}`,
            title: `⚠️ Scadenza Assicurazione (30gg): ${vehicle.licensePlate}`,
            start: warningDate,
            end: warningDate,
            color: '#eab308',
            description: `Scadenza assicurazione tra 30 giorni per ${vehicle.make} ${vehicle.model} (${vehicle.licensePlate})`,
            type: 'vehicle',
            allDay: true
          });

          events.push({
            _id: `insurance_${vehicle._id}`,
            title: `🚫 Scadenza Assicurazione: ${vehicle.licensePlate}`,
            start: expiryDate,
            end: expiryDate,
            color: '#dc2626',
            description: `Scadenza assicurazione per ${vehicle.make} ${vehicle.model} (${vehicle.licensePlate})`,
            type: 'vehicle',
            allDay: true
          });
        }

        if (vehicle.nextInspectionDate) {
          const inspectionDate = new Date(vehicle.nextInspectionDate);
          const warningDate = new Date(inspectionDate);
          warningDate.setDate(warningDate.getDate() - 30);

          events.push({
            _id: `inspection_warning_${vehicle._id}`,
            title: `⚠️ Scadenza Revisione (30gg): ${vehicle.licensePlate}`,
            start: warningDate,
            end: warningDate,
            color: '#eab308',
            description: `Scadenza revisione tra 30 giorni per ${vehicle.make} ${vehicle.model} (${vehicle.licensePlate})`,
            type: 'vehicle',
            allDay: true
          });

          events.push({
            _id: `inspection_${vehicle._id}`,
            title: `🚫 Scadenza Revisione: ${vehicle.licensePlate}`,
            start: inspectionDate,
            end: inspectionDate,
            color: '#dc2626',
            description: `Scadenza revisione per ${vehicle.make} ${vehicle.model} (${vehicle.licensePlate})`,
            type: 'vehicle',
            allDay: true
          });
        }

        return [...acc, ...events];
      }, []);

      // Fetch training certificates
      const trainingResponse = await fetchTrainings(companyId, 1, 1000, '');
      const trainingEvents = trainingResponse.data.trainingCertificates?.reduce((acc, certificate) => {
        const expiryDate = new Date(certificate.expiration);
        const warningDate = new Date(expiryDate);
        warningDate.setDate(warningDate.getDate() - 30);

        return [...acc,
        {
          _id: `training_warning_${certificate._id}`,
          title: `⚠️ Scadenza Certificato (30gg): ${certificate.attestedName}`,
          start: warningDate,
          end: warningDate,
          color: '#eab308',
          description: `Scadenza certificato tra 30 giorni per ${certificate.employee?.firstName} ${certificate.employee?.sureName} - ${certificate.attestedName}`,
          type: 'training',
          allDay: true
        },
        {
          _id: `training_${certificate._id}`,
          title: `📄 Scadenza Certificato: ${certificate.attestedName}`,
          start: expiryDate,
          end: expiryDate,
          color: '#dc2626',
          description: `Scadenza certificato per ${certificate.employee?.firstName} ${certificate.employee?.sureName} - ${certificate.attestedName}`,
          type: 'training',
          allDay: true
        }
        ];
      }, []) || [];

      // Fetch documents
      const documentsResponse = await fetchDocuments(companyId, 1, 1000);
      const documentEvents = documentsResponse.documents?.reduce((acc, document) => {
        const expiryDate = new Date(document.expireDate);
        const warningDate = new Date(expiryDate);
        warningDate.setDate(warningDate.getDate() - 30);

        return [...acc,
        {
          _id: `doc_warning_${document._id}`,
          title: `⚠️ Scadenza Documento (30gg): ${document.title}`,
          start: warningDate,
          end: warningDate,
          color: '#eab308',
          description: `Il documento "${document.title}" scadrà tra 30 giorni`,
          type: 'document',
          allDay: true
        },
        {
          _id: `doc_expiry_${document._id}`,
          title: `📄 Scadenza Documento: ${document.title}`,
          start: expiryDate,
          end: expiryDate,
          color: '#dc2626',
          description: `Scadenza del documento "${document.title}"`,
          type: 'document',
          allDay: true
        }
        ];
      }, []) || [];

      // Fetch medical visits
      const medicalVisitsResponse = await getMedicalVisits(companyId, 1, 1000);
      const medicalVisitEvents = medicalVisitsResponse.data.visits?.reduce((acc, visit) => {
        const expiryDate = new Date(visit.expiryDate);
        const warningDate = new Date(expiryDate);
        warningDate.setDate(warningDate.getDate() - 30);

        return [...acc,
        {
          _id: `medical_warning_${visit._id}`,
          title: `⚠️ Scadenza Visita Medica (30gg): ${visit.employeeId.firstName} ${visit.employeeId.sureName}`,
          start: warningDate,
          end: warningDate,
          color: '#eab308', // warning yellow
          description: `Visita medica in scadenza tra 30 giorni per ${visit.employeeId.firstName} ${visit.employeeId.sureName}`,
          type: 'medical',
          allDay: true
        },
        {
          _id: `medical_${visit._id}`,
          title: `🏥 Scadenza Visita Medica: ${visit.employeeId.firstName} ${visit.employeeId.sureName}`,
          start: expiryDate,
          end: expiryDate,
          color: '#dc2626', // error red
          description: `Scadenza visita medica per ${visit.employeeId.firstName} ${visit.employeeId.sureName}`,
          type: 'medical',
          allDay: true
        }
        ];
      }, []) || [];

      // Combine all events including medical visits
      setEvents([
        ...regularEvents,
        ...constructionSiteEvents,
        ...vehicleEvents,
        ...trainingEvents,
        ...documentEvents,
        ...medicalVisitEvents // Add medical visit events
      ]);

    } catch (error) {
      console.error("Error fetching data:", error);
      toast.error("Impossibile recuperare gli eventi");
    }
  }, [companyId]);

  useEffect(() => {
    fetchEventsAndDates();
  }, [fetchEventsAndDates, modalIsOpen]);

  const handleSelectEvent = async (event) => {
    if (event.type === 'construction' || event.type === 'vehicle' ||
      event.type === 'training' || event.type === 'document' ||
      event.type === 'medical') { // Add medical type
      toast.info(event.description);
      return;
    }

    try {
      setSelectedEventId(event._id);
      const response = await getEvent(event._id);
      if (response.status === 200) {
        setSelectedEvent({
          ...response.data?.event,
          start: new Date(response.data.start),
          end: new Date(response.data.end),
        });
        setModalIsOpen(true);
      }
    } catch (error) {
      console.error("Error fetching event details:", error);
      toast.error("Impossibile recuperare i dettagli dell'evento");
    }
  };

  const handleSelectSlot = (slotInfo) => {
    if (moment().isAfter(slotInfo.start, "day")) {
      toast.warn("Non è possibile creare eventi nel passato");
      return;
    }
    setSelectedEvent({
      title: "",
      start: slotInfo.start,
      end: moment(slotInfo.start).add(1, "hours").toDate(),
      description: "",
      color: "#1A2B3C",
    });
    setModalIsOpen(true);
  };

  const handleEventSubmit = async () => {
    try {
      if (selectedEventId) {
        const response = await updateEvent(selectedEventId, selectedEvent);
        if (response.status === 200) {
          toast.success("Evento aggiornato con successo");
        }
      } else {
        const response = await AddEvent(selectedEvent);
        if (response.status === 201) {
          toast.success("Evento creato con successo");
        }
      }
      closeModal();
      fetchEventsAndDates();
    } catch (error) {
      console.error("Error submitting event:", error);
      toast.error("Errore durante il salvataggio dell'evento");
    }
  };

  const handleDeleteEvent = async () => {
    try {
      const response = await deleteEvent(selectedEventId);
      if (response.status === 200) {
        toast.success("Evento eliminato con successo");
        closeModal();
        fetchEventsAndDates();
      }
    } catch (error) {
      console.error("Error deleting event:", error);
      toast.error("Errore durante l'eliminazione dell'evento");
    }
  };

  const closeModal = () => {
    setModalIsOpen(false);
    setSelectedEventId(null);
    setSelectedEvent({ color: "", description: "", start: null, end: null });
  };

  const eventStyleGetter = (event) => {
    let style = {
      backgroundColor: event?.color,
      borderRadius: "0.375rem",
      opacity: 0.9,
      color: "white",
      border: "0px",
      cursor: "pointer",
      marginBottom: "2px",
    };
  
    if (event.type === 'vehicle' || event.type === 'construction' || 
        event.type === 'training' || event.type === 'document' ||
        event.type === 'medical') { // Add medical type
      style = {
        ...style,
        fontWeight: 'bold',
        border: '2px solid rgba(0,0,0,0.2)'
      };
    }
  
    if (currentView === Views.MONTH) {
      style = {
        ...style,
        height: event.type === 'vehicle' || event.type === 'construction' || 
               event.type === 'training' || event.type === 'document' ||
               event.type === 'medical' ? '20px' : '10px', // Add medical type
        fontSize: event.type === 'vehicle' || event.type === 'construction' || 
                  event.type === 'training' || event.type === 'document' ||
                  event.type === 'medical' ? '10px' : '6px', // Add medical type
        display: 'flex',
        alignItems: 'center'
      };
    }
  
    return { style };
  };



  const CustomToolbar = ({ label, onNavigate, onView }) => (
    <div className="flex justify-between items-center mb-4 flex-wrap gap-4">
      <div className="flex space-x-2">
        <Button variant="outline" size="icon" onClick={() => onNavigate("PREV")}>
          <ChevronLeft className="h-4 w-4" />
        </Button>
        <Button variant="outline" onClick={() => onNavigate("TODAY")}>Oggi</Button>
        <Button variant="outline" size="icon" onClick={() => onNavigate("NEXT")}>
          <ChevronRight className="h-4 w-4" />
        </Button>
      </div>
      <div className="text-lg font-bold flex items-center">
        <CalendarIcon className="mr-2" /> {label}
      </div>
      <div className="md:w-1/6 flex justify-center items-center gap-x-2">
        <Select value={currentView} onValueChange={(value) => onView(value)}>
          <SelectTrigger className="w-[180px]">
            <SelectValue placeholder="Seleziona vista" />
          </SelectTrigger>
          <SelectContent>
            <SelectItem value={Views.MONTH}>Mese</SelectItem>
            <SelectItem value={Views.WEEK}>Settimana</SelectItem>
            <SelectItem value={Views.DAY}>Giorno</SelectItem>
            <SelectItem value={Views.AGENDA}>Agenda</SelectItem>
          </SelectContent>
        </Select>
        <HelpSheet route="/events" />
      </div>
    </div>
  );

  return (
    <Card className="w-full mx-auto">
      <CardContent className="p-6">
        <Calendar
          localizer={localizer}
          events={events}
          startAccessor="start"
          endAccessor="end"
          style={{ height: "75vh" }}
          selectable
          onSelectEvent={handleSelectEvent}
          onSelectSlot={handleSelectSlot}
          eventPropGetter={eventStyleGetter}
          views={Object.keys(Views).map((key) => Views[key])}
          view={currentView}
          onView={setCurrentView}
          date={currentDate}
          onNavigate={date => setCurrentDate(date)}
          components={{
            toolbar: CustomToolbar,
          }}
          dayPropGetter={date => ({
            className: 'rbc-day-bg',
            style: {
              ...(date.getDay() === 1 && { borderLeft: '2px solid #3b82f6' })
            }
          })}
          messages={{
            allDay: 'Tutto il giorno',
            previous: '<',
            next: '>',
            today: 'Oggi',
            month: 'Mese',
            week: 'Settimana',
            day: 'Giorno',
            agenda: 'Agenda',
            date: 'Data',
            time: 'Ora',
            event: 'Evento',
            noEventsInRange: 'Non ci sono eventi in questo intervallo.',
            showMore: total => `+ altri (${total})`
          }}
        />
      </CardContent>
      <EventModal
        modalIsOpen={modalIsOpen}
        closeModal={closeModal}
        selectedEvent={selectedEvent}
        setSelectedEvent={setSelectedEvent}
        handleEventSubmit={handleEventSubmit}
        handleDeleteEvent={handleDeleteEvent}
        selectedEventId={selectedEventId}
      />
    </Card>
  );
};

export default CalendarComponent;