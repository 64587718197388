import React from 'react'
import Layout from '../../../components/admin/sharedComponent/Layout'
import RateizziManagement from '../../../components/admin/installment/index'

const Installment = () => {
  return <>
  <Layout>
    <RateizziManagement />
  </Layout>
  </>
}

export default Installment