// ChatList.jsx
import React from 'react';
import { format } from 'date-fns';
import { MoreVertical, Trash2, Loader2, Plus } from 'lucide-react';
import { ScrollArea } from "../../../ui/scroll-area";
import { Avatar, AvatarFallback, AvatarImage } from "../../../ui/avatar";
import { Button } from "../../../ui/button";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "../../../ui/dropdown-menu";

const ChatList = ({
  chats,
  currentChat,
  user,
  handleChatClick,
  setChatToDelete,
  loading,
  onNewChat // Changed from setShowModal for clarity
}) => {
  return (
    <ScrollArea className="h-[calc(100vh-12rem)]">
      {loading.chats ? (
        <div className="flex justify-center p-4">
          <Loader2 className="animate-spin h-6 w-6" />
        </div>
      ) : chats.length === 0 ? (
        <div className="flex flex-col items-center justify-center h-full text-center p-4">
          <p className="text-muted-foreground">No conversations yet</p>
          <Button
            variant="outline"
            className="mt-2"
            onClick={onNewChat} // Use the new prop name
          >
            <Plus className="h-4 w-4 mr-2" />
            Start a new chat
          </Button>
        </div>
      ) : (
        chats.map(chat => {
          const otherParticipant = chat.participants.find(p =>
            p.user._id !== user._id
          )?.user;

          const lastMessageTime = chat.lastMessage ? new Date(chat.lastMessage) : new Date();

          return (
            <div
              key={chat._id}
              className={`flex items-center p-4 cursor-pointer hover:bg-accent/50 transition-colors ${
                currentChat?._id === chat._id ? 'bg-accent' : ''
              }`}
              onClick={() => handleChatClick(chat)}
            >
              <Avatar className="h-10 w-10 flex-shrink-0">
                <AvatarImage src={otherParticipant?.avatar} />
                <AvatarFallback>
                  {(otherParticipant?.username || otherParticipant?.name || '?')[0].toUpperCase()}
                </AvatarFallback>
              </Avatar>
              <div className="ml-3 flex-grow min-w-0">
                <h6 className="font-medium truncate">
                  {otherParticipant?.username || otherParticipant?.name || 'Unknown User'}
                </h6>
                <p className="text-sm text-muted-foreground truncate">
                  {format(lastMessageTime, 'MMM d, HH:mm')}
                </p>
              </div>
              <DropdownMenu>
                <DropdownMenuTrigger asChild>
                  <Button
                    variant="ghost"
                    size="icon"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <MoreVertical className="h-4 w-4" />
                  </Button>
                </DropdownMenuTrigger>
                <DropdownMenuContent align="end">
                  <DropdownMenuItem
                    onClick={(e) => {
                      e.stopPropagation();
                      setChatToDelete(chat._id);
                    }}
                    className="text-destructive focus:text-destructive"
                  >
                    <Trash2 className="mr-2 h-4 w-4" />
                    Delete Chat
                  </DropdownMenuItem>
                </DropdownMenuContent>
              </DropdownMenu>
            </div>
          );
        })
      )}
    </ScrollArea>
  );
};

export default ChatList;