import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Button } from "../ui/button";
import { X } from 'lucide-react';

const PermissionProtectedRoute = ({ children, requiredRoute }) => {
  const [showModal, setShowModal] = useState(false);
  const navigate = useNavigate();
  const user = JSON.parse(localStorage.getItem('user') || '{}');

  useEffect(() => {
    const hasPermission = checkPermission();
    setShowModal(!hasPermission);
  }, [requiredRoute]);

  const checkPermission = () => {
    if (user.role === 'admin') return true;
    if (!requiredRoute) return true;
    return user.permissions?.[requiredRoute] || false;
  };

  const handleClose = () => {
    setShowModal(false);
    navigate('/dashboard');
  };

  const getModalMessage = () => {
    if (user.isInvitedUser) {
      return "Non hai i permessi necessari per accedere a questa sezione. Contatta il tuo amministratore per richiedere l'accesso.";
    }
    return "Non hai i permessi necessari per accedere a questa sezione.";
  };

  const renderModal = () => (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-md mx-4 sm:mx-auto">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Accesso Negato</h2>
          <Button variant="ghost" size="icon" onClick={handleClose}>
            <X className="h-4 w-4" />
          </Button>
        </div>
        <p className="mb-4">{getModalMessage()}</p>
        <div className="flex justify-end">
          <Button onClick={handleClose}>
            Torna alla Dashboard
          </Button>
        </div>
      </div>
    </div>
  );

  const hasPermission = checkPermission();

  return (
    <>
      {children}
      {showModal && !hasPermission && renderModal()}
    </>
  );
};

export default PermissionProtectedRoute;