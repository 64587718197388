import React, { useState, useEffect } from 'react';
import { FaDesktop, FaWallet, FaGlobe, FaFile, FaShoppingCart, FaCircle, FaUser, FaAngleLeft, FaAngleRight, FaChartBar, FaMoneyBillWave, FaHardHat, FaList, FaComments } from "react-icons/fa";
import { fetchClients } from '../../../apis/client';
import { fetchSuppliers } from '../../../apis/supplier';
import { fetchMonthlyMovimenti, sumEntra, sumUscita } from '../../../apis/Movimenti';
import { getConstructionSites, getContabilita } from '../../../apis/ConstructionSite';
import { fetchPreventivi } from '../../../apis/Preventivi';

import { Card, CardContent, CardHeader, CardTitle } from "../../ui/card";
import { Button } from "../../ui/button";
import { Progress } from "../../ui/progress";
import { Area, AreaChart, Pie, PieChart, Cell, ResponsiveContainer, Tooltip, XAxis, YAxis, Legend, LineChart, Line } from "recharts";
import { Separator } from '../../ui/separator';
import Spinner from '../../Spinner';

const formatNumber = (num) => {
    return typeof num === 'number'
        ? new Intl.NumberFormat('de-DE', { minimumFractionDigits: 2, maximumFractionDigits: 2 }).format(num)
        : '0,00';
};

const DashBoard = () => {
    const initialData = {
        cashFlow: 0,
        totalClients: 0,
        totalSuppliers: 0,
        totalConstructionSites: 0,
        sentQuotes: 0,
        stats: {
            week: { quotes: 0, inProgress: 0, sent: 0 },
            month: { quotes: 0, inProgress: 0, sent: 0 },
            day: { quotes: 0, inProgress: 0, sent: 0 }
        },
        siteTypes: [],
        projectStatus: [],
        movimentiData: [],
        preventiviData: []
    };

    const [data, setData] = useState(initialData);
    const [error, setError] = useState(null);
    const [user, setUser] = useState(null);
    const [isLoading, setIsLoading] = useState(true);

    useEffect(() => {
        const fetchData = async () => {
            setIsLoading(true);
            try {
                const userString = localStorage.getItem('user');
                if (!userString) {
                    throw new Error('User not found in local storage');
                }
                const userData = JSON.parse(userString);
                setUser(userData);
                const companyId = userData.companyId;

                if (!companyId) {
                    throw new Error('Company ID not found in user data');
                }

                const [clientsResponse, suppliersResponse, constructionSitesResponse, movimentiData, preventiviResponse] = await Promise.all([
                    fetchClients(companyId, 1, 10, '', ''),
                    fetchSuppliers(companyId),
                    getConstructionSites(companyId),
                    fetchMonthlyMovimenti(companyId).catch(err => {
                        console.warn('Error fetching movimenti data:', err);
                        return [];
                    }),
                    fetchPreventivi(companyId, 1, 1000)
                ]);

                const preventiviData = processPreventiviData(preventiviResponse?.data?.preventivis || []);
                const suppliers = suppliersResponse?.data?.suppliers || [];
                const sites = constructionSitesResponse?.data?.sites || [];

                // Fetch contabilità data for each site
                const sitesWithContabilita = await Promise.all(
                    sites.map(async (site) => {
                        try {
                            const contabilitaData = await getContabilita(site._id);
                            return {
                                name: site.name || 'Unnamed Project',
                                description: site.description || '',
                                progress: contabilitaData.percentagePaid || 0,
                                totalAmount: contabilitaData.totalAmount || 0,
                                amountPaid: contabilitaData.amountPaid || 0
                            };
                        } catch (error) {
                            console.error(`Error fetching contabilità for site ${site._id}:`, error);
                            return {
                                name: site.name || 'Unnamed Project',
                                description: site.description || '',
                                progress: 0,
                                totalAmount: 0,
                                amountPaid: 0
                            };
                        }
                    })
                );

                // Calculate cash flow from movimenti data
                const cashFlow = calculateCashFlowFromMovimenti(movimentiData);

                setData(prevData => ({
                    ...prevData,
                    cashFlow,
                    totalClients: clientsResponse?.data?.totalClients || 0,
                    totalSuppliers: suppliers.length,
                    totalConstructionSites: sites.length,
                    projectStatus: sitesWithContabilita,
                    siteTypes: processWorkTypes(sites),
                    movimentiData: Array.isArray(movimentiData) ? processMovimentiData(movimentiData) : [],
                    preventiviData
                }));
            } catch (error) {
                console.error("Error fetching data:", error);
                setError(`An error occurred while loading the dashboard: ${error.message}`);
            } finally {
                setIsLoading(false);
            }
        };

        fetchData();
    }, []);

    const calculateCashFlowFromMovimenti = (movimentiData) => {
        if (!Array.isArray(movimentiData)) return 0;

        return movimentiData.reduce((total, movimento) => {
            const Entra = movimento.Entra?.total || 0;
            const uscita = movimento.Uscita?.total || 0;
            return total + (Entra - uscita);
        }, 0);
    };

    const processMovimentiData = (movimenti) => {
        if (!Array.isArray(movimenti)) return [];

        return movimenti.map(movimento => ({
            month: movimento.date || '',
            Entra: movimento.Entra?.total || 0,
            Uscita: movimento.Uscita?.total || 0
        })).sort((a, b) => a.month.localeCompare(b.month));
    };

    const processWorkTypes = (sites) => {
        const typeCount = sites.reduce((acc, site) => {
            const workType = site.workType || 'Other';
            acc[workType] = (acc[workType] || 0) + 1;
            return acc;
        }, {});

        return Object.entries(typeCount).map(([name, value]) => ({ name, value }));
    };

    const processPreventiviData = (preventivi) => {
        if (!Array.isArray(preventivi)) return [];

        const monthlyData = {};
        preventivi.forEach(preventivo => {
            if (!preventivo?.dataa) return;

            const date = new Date(preventivo.dataa);
            const monthYear = `${date.getFullYear()}-${String(date.getMonth() + 1).padStart(2, '0')}`;
            if (!monthlyData[monthYear]) {
                monthlyData[monthYear] = { month: monthYear, count: 0 };
            }
            monthlyData[monthYear].count++;
        });
        return Object.values(monthlyData).sort((a, b) => a.month.localeCompare(b.month));
    };

    const CustomTooltip = ({ active, payload, label }) => {
        if (active && payload && payload.length) {
            return (
                <div className="bg-white p-2 border border-gray-300 rounded shadow">
                    <p className="font-semibold">{`${label}`}</p>
                    {payload.map((entry, index) => (
                        <p key={index} style={{ color: entry.color }}>
                            {`${entry.name}: ${entry.value}`}
                        </p>
                    ))}
                </div>
            );
        }
        return null;
    };

    const EmptyState = ({ message }) => (
        <div className="flex items-center justify-center h-full">
            <p className="text-gray-500">{message}</p>
        </div>
    );

    if (isLoading) {
        return <Spinner />;
    }

    if (error) {
        return <div className="p-6 text-red-500">{error}</div>;
    }

    const COLORS = ['#4A5568', '#6B46C1', '#4299E1', '#48BB78', '#ED8936', '#ECC94B'];

    return (
        <div id="admin_content" className="p-6 bg-gray-50">
            {/* Greeting Section */}
            <div className="mb-4 rounded-lg py-6">
                <h1 className="text-xl font-bold mb-2">Ciao, {user?.username || 'Utente'}! felice di rivederti.</h1>
                <p className="text-lg">Rivedi le tue attività, aggiorna le informazioni e mantieni tutto organizzato al meglio.</p>
            </div>

            {/* Statistics Cards */}
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-x-10 max-sm:gap-y-2 mb-10">
                <Card className="transition-all duration-300">
                    <CardContent className="p-6">
                        <div className="flex justify-between items-center mb-2">
                            <CardTitle className="text-md font-semibold">Flusso di cassa</CardTitle>
                            <FaMoneyBillWave className="text-blue-500" size={24} />
                        </div>
                        <div className="text-xl font-bold">€{data.cashFlow.toLocaleString('it-IT', { minimumFractionDigits: 2, maximumFractionDigits: 2 })}</div>
                    </CardContent>
                </Card>
                <Card className="transition-all duration-300">
                    <CardContent className="p-6">
                        <div className="flex justify-between items-center mb-2">
                            <CardTitle className="text-md font-semibold">Clienti</CardTitle>
                            <FaUser className="text-green-500" size={24} />
                        </div>
                        <div className="text-xl font-bold">{data.totalClients}</div>
                    </CardContent>
                </Card>
                <Card className="transition-all duration-300">
                    <CardContent className="p-6">
                        <div className="flex justify-between items-center mb-2">
                            <CardTitle className="text-md font-semibold">Fornitori</CardTitle>
                            <FaShoppingCart className="text-purple-500" size={24} />
                        </div>
                        <div className="text-xl font-bold">{data.totalSuppliers}</div>
                    </CardContent>
                </Card>
                <Card className="transition-all duration-300">
                    <CardContent className="p-6">
                        <div className="flex justify-between items-center mb-2">
                            <CardTitle className="text-md font-semibold">Cantieri</CardTitle>
                            <FaHardHat className="text-yellow-500" size={24} />
                        </div>
                        <div className="text-xl font-bold">{data.totalConstructionSites}</div>
                    </CardContent>
                </Card>
            </div>

            {/* Le tue finanze section */}
            <div className="grid grid-cols-1 lg:grid-cols-2 gap-x-12 mb-4">

                {/* Le tue finanze section */}
                <div>
                    <h2 className="text-xl font-bold mb-6">Le tue finanze</h2>
                    <Separator className="mb-6" />
                    <p className="text-sm text-gray-600 mb-6">
                        Visualizza l'andamento delle entrate e delle uscite dei tuoi conti bancari
                    </p>
                    <Card className="border-0">
                        <CardContent className="p-0">
                            {data.movimentiData.length > 0 ? (
                                <ResponsiveContainer width="100%" height={300}>
                                    <AreaChart data={data.movimentiData}>
                                        <XAxis dataKey="month" tick={{ fontSize: 10 }} />
                                        <YAxis tick={{ fontSize: 10 }} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Area
                                            type="monotone"
                                            dataKey="Entra"
                                            stroke="#4A5568"
                                            fill="#EDF2F7"
                                            stackId="1"
                                        />
                                        <Area
                                            type="monotone"
                                            dataKey="Uscita"
                                            stroke="#6B46C1"
                                            fill="#E9D8FD"
                                            stackId="1"
                                        />
                                    </AreaChart>
                                </ResponsiveContainer>
                            ) : (
                                <EmptyState message="Nessun dato disponibile per i movimenti del conto" />
                            )}
                        </CardContent>
                    </Card>
                </div>

                {/* Preventivi section */}
                <div>
                    <h2 className="text-xl font-bold mb-6">Preventivi</h2>
                    <Separator className="mb-6" />
                    <p className="text-sm text-gray-600 mb-6">
                        Monitora l'andamento mensile dei preventivi emessi
                    </p>
                    <Card className="border-0">
                        <CardContent className="p-0">
                            {data.preventiviData.length > 0 ? (
                                <ResponsiveContainer width="100%" height={300}>
                                    <LineChart data={data.preventiviData}>
                                        <XAxis dataKey="month" tick={{ fontSize: 10 }} />
                                        <YAxis tick={{ fontSize: 10 }} />
                                        <Tooltip content={<CustomTooltip />} />
                                        <Line type="monotone" dataKey="count" stroke="#8884d8" name="Preventivi" />
                                    </LineChart>
                                </ResponsiveContainer>
                            ) : (
                                <EmptyState message="Nessun dato disponibile per i preventivi mensili" />
                            )}
                        </CardContent>
                    </Card>
                </div>

            </div>



            <div>
                <h2 className="text-xl font-bold mb-6">I tuoi cantieri</h2>
                <Separator />

                {/* I tuoi cantieri section */}
                <div className="grid grid-cols-1 lg:grid-cols-4 gap-6 mb-6">
                    <Card className="lg:col-span-2 border-0">
                        <CardHeader className="bg-gray-50 border-gray-200">
                            <div className="flex items-center justify-between">
                                <div className=""></div>
                                <div className="flex space-x-2">
                                    <Button id="prevBtn" variant="outline" size="sm">
                                        <FaAngleLeft className="h-4 w-4" />
                                    </Button>
                                    <Button id="nextBtn" variant="outline" size="sm">
                                        <FaAngleRight className="h-4 w-4" />
                                    </Button>
                                </div>
                            </div>
                            <p className="text-sm text-gray-600 mb-2">Visualizza lo stato di avanzamento dei cantieri in corso</p>
                        </CardHeader>
                        <CardContent className="p-2">
                            {data.projectStatus.map((project, index) => (
                                <div key={index} className="bg-gray-50 p-2 rounded-lg">
                                    <div className="flex justify-between items-center mb-2">
                                        <h5 className="text-md font-semibold">{project.name}</h5>
                                        <span className="text-sm font-medium text-gray-600">
                                            {formatNumber(project.progress)}%
                                        </span>
                                    </div>
                                    <p className="text-gray-600 mb-2">{project.description}</p>
                                    <Progress value={project.progress} className="w-full h-2" />
                                    <div className="text-sm text-gray-500 mt-2">
                                        €{formatNumber(project.amountPaid)} / €{formatNumber(project.totalAmount)}
                                    </div>
                                </div>
                            ))}
                        </CardContent>
                    </Card>

                    <Card className="border-0">
                        <CardHeader className="bg-gray-50 border-gray-200">
                        </CardHeader>
                        <CardContent className="p-2" style={{ height: '300px' }}>
                            {data.siteTypes.length > 0 ? (
                                <ResponsiveContainer width="100%" height="100%">
                                    <PieChart>
                                        <Pie
                                            data={data.siteTypes}
                                            cx="50%"
                                            cy="50%"
                                            innerRadius={60}
                                            outerRadius={80}
                                            fill="#E9D8FD"
                                            paddingAngle={5}
                                            dataKey="value"
                                        >
                                            {data.siteTypes.map((entry, index) => (
                                                <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
                                            ))}
                                        </Pie>
                                        <Tooltip />
                                        <Legend />
                                    </PieChart>
                                </ResponsiveContainer>
                            ) : (
                                <EmptyState message="Nessun dato disponibile per la tipologia dei cantieri" />
                            )}
                        </CardContent>
                    </Card>
                </div>

                {/* Chat section */}
                <Card className="border-0 mb-6">
                    <CardHeader className="bg-gray-50 border-gray-200">
                    </CardHeader>
                    <CardContent className="p-6">
                        <EmptyState message="Funzionalità chat non ancora implementata" />
                    </CardContent>
                </Card>
            </div>

        </div>
    );
}

export default DashBoard;