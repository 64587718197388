import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import { getConstructionSite, updateConstructionSite, getConstructionSites } from '../../../apis/ConstructionSite';
import ConstructionSiteNavbar from './ConstructionSiteNavbar';
import { Button } from "../../ui/button";
import { Input } from "../../ui/input";
import { Label } from "../../ui/label";
import { Textarea } from "../../ui/textarea";
import {
    Select,
    SelectContent,
    SelectItem,
    SelectTrigger,
    SelectValue,
} from "../../ui/select";
import { Card, CardContent } from "../../ui/card";
import { Skeleton } from "../../ui/skeleton";
import Spinner from '../../Spinner';
import { toast } from 'react-toastify';
import { Archive } from 'lucide-react';
import placeholderImage from '../../../assest/cantieri.png';

function Informazioni() {
    const [site, setSite] = useState({
        name: '',
        client: '',
        address: '',
        amount: '',
        workType: '',
        description: '',
        city: '',
        postalCode: '',
        province: '',
        country: '',
        featuredImage: null,
        openingDate: '',
        closingDate: '',
        status: 'In pianificazione',
        archivedAt: null,
        archiveReason: null
    });
    const [isLoading, setIsLoading] = useState(true);
    const [isUpdating, setIsUpdating] = useState(false);
    const [isArchived, setIsArchived] = useState(false);
    const { id } = useParams();
    const navigate = useNavigate();
    const [clients, setClients] = useState([]);

    useEffect(() => {
        fetchSite();
    }, [id]);

    const getUserFromLocalStorage = () => {
        const userString = localStorage.getItem('user');
        if (!userString) return null;
        const user = JSON.parse(userString);
        if (!user.companyId) {
            console.warn('User does not have a companyId');
            user.companyId = 'default';
        }
        return user;
    };

    const fetchClients = async () => {
        try {
            const user = getUserFromLocalStorage();
            if (!user) {
                console.error('User information not found');
                return;
            }
            const response = await getConstructionSites(user.companyId, 1, 10);
            setClients(response.data.clients);
        } catch (error) {
            console.error('Error fetching clients:', error);
            toast.error('Errore nel recupero dei clienti');
        }
    };

    const fetchSite = async () => {
        setIsLoading(true);
        try {
            const response = await getConstructionSite(id);
            setSite(response.data);
            setIsArchived(response.data.status === 'Archiviato');
        } catch (error) {
            console.error('Errore nel recupero del cantiere:', error);
            toast.error('Errore nel recupero del cantiere');
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        fetchClients();
    }, []);

    const handleInputChange = (e) => {
        if (isArchived) return;
        
        const { name, value } = e.target;

        if (name === 'closingDate') {
            const openingDate = new Date(site.openingDate);
            const selectedClosingDate = new Date(value);

            if (selectedClosingDate < openingDate) {
                toast.error("La data di chiusura non può essere precedente alla data di apertura");
                return;
            }
        }

        setSite({ ...site, [name]: value });
    };

    const handleSelectChange = (name, value) => {
        if (isArchived) return;
        
        setSite(prevSite => ({
            ...prevSite,
            [name]: value
        }));
    };

    const handleFileChange = (e) => {
        if (isArchived) return;
        
        if (e.target.files && e.target.files[0]) {
            setSite(prev => ({
                ...prev,
                featuredImage: e.target.files[0]
            }));
        }
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        if (isArchived) return;
        
        setIsUpdating(true);

        try {
            const formData = new FormData();

            Object.keys(site).forEach(key => {
                if (key === 'featuredImage') {
                    if (site[key] instanceof File) {
                        formData.append('featuredImage', site[key]);
                    }
                } else if (key !== 'documents' && key !== 'progressStatuses' && 
                          key !== 'reports' && key !== 'mediaFolders' && 
                          key !== 'media' && site[key] !== null && 
                          site[key] !== undefined) {
                    formData.append(key, site[key]);
                }
            });

            await updateConstructionSite(id, formData);
            await fetchSite();
            navigate('/cantieri');
            toast.success('Cantiere aggiornato con successo');
        } catch (error) {
            console.error('Errore nell\'aggiornamento del cantiere:', error);
            toast.error('Errore nell\'aggiornamento del cantiere');
        } finally {
            setIsUpdating(false);
        }
    };

    if (isLoading) {
        return <Spinner />;
    }

    return (
        <div>
            <ConstructionSiteNavbar />

            {isArchived && (
                <div className="bg-yellow-50 border-l-4 border-yellow-400 p-4 my-4 mx-6">
                    <div className="flex items-center">
                        <Archive className="w-5 h-5 text-yellow-400 mr-2" />
                        <p className="text-yellow-700">
                            Questo cantiere è archiviato. 
                            {site.archiveReason && (
                                <span className="ml-2">
                                    Motivo: {site.archiveReason}
                                </span>
                            )}
                            <span className="ml-2">
                                Data archiviazione: {new Date(site.archivedAt).toLocaleDateString('it-IT')}
                            </span>
                        </p>
                    </div>
                </div>
            )}

            <form onSubmit={handleSubmit} className="space-y-6">
                <Card className="border-0">
                    <CardContent className="p-6">
                        <div className="space-y-4">
                            <div>
                                <h2 className="text-lg font-medium">Immagine in evidenza</h2>
                                <div className="md:flex justify-between items-start mt-2">
                                    <div>
                                        <img
                                            src={
                                                site.featuredImage
                                                    ? `${process.env.REACT_APP_BACKEND_URL}/${site.featuredImage}`
                                                    : placeholderImage
                                            }
                                            alt={site.name}
                                            className="w-32 h-32 rounded-full object-cover"
                                        />
                                        {!isArchived && (
                                            <div className="w-full sm:w-auto">
                                                <Input
                                                    type="file"
                                                    onChange={handleFileChange}
                                                    className="max-w-[250px] text-sm mt-2"
                                                    accept="image/*"
                                                />
                                            </div>
                                        )}
                                    </div>

                                    <div className="gap-4 md:flex max-sm:mt-4">
                                        <div className="space-y-2">
                                            <Label htmlFor="openingDate">Apertura cantiere</Label>
                                            <Input
                                                type="date"
                                                id="openingDate"
                                                name="openingDate"
                                                value={site.openingDate ? new Date(site.openingDate).toISOString().split('T')[0] : ''}
                                                onChange={handleInputChange}
                                                required
                                                disabled={isArchived}
                                                className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="closingDate">Chiusura cantiere</Label>
                                            <Input
                                                type="date"
                                                id="closingDate"
                                                name="closingDate"
                                                min={site.openingDate ? new Date(site.openingDate).toISOString().split('T')[0] : ''}
                                                value={site.closingDate ? new Date(site.closingDate).toISOString().split('T')[0] : ''}
                                                onChange={handleInputChange}
                                                disabled={isArchived}
                                                className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}
                                            />
                                        </div>
                                        <div className="space-y-2">
                                            <Label htmlFor="status">Stato del cantiere</Label>
                                            <Select
                                                name="status"
                                                value={site.status}
                                                onValueChange={(value) => handleSelectChange('status', value)}
                                                disabled={isArchived}
                                            >
                                                <SelectTrigger className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}>
                                                    <SelectValue placeholder="Seleziona stato" />
                                                </SelectTrigger>
                                                <SelectContent>
                                                    <SelectItem value="In pianificazione">In pianificazione</SelectItem>
                                                    <SelectItem value="In corso">In corso</SelectItem>
                                                    <SelectItem value="In revisione">In revisione</SelectItem>
                                                    <SelectItem value="Sospeso">Sospeso</SelectItem>
                                                    <SelectItem value="In collaudo">In collaudo</SelectItem>
                                                    <SelectItem value="Terminato">Terminato</SelectItem>
                                                </SelectContent>
                                            </Select>
                                        </div>
                                    </div>
                                </div>
                            </div>

                            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
                                <div className="space-y-2">
                                    <Label htmlFor="name">Nome Cantiere</Label>
                                    <Input
                                        id="name"
                                        name="name"
                                        value={site.name}
                                        onChange={handleInputChange}
                                        required
                                        disabled={isArchived}
                                        className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="amount">Importo €</Label>
                                    <Input
                                        id="amount"
                                        name="amount"
                                        type="number"
                                        step="0.01"
                                        min="0"
                                        value={site.amount}
                                        onChange={handleInputChange}
                                        required
                                        disabled={isArchived}
                                        className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="client">Committente</Label>
                                    <Select
                                        name="client"
                                        value={site.client}
                                        onValueChange={(value) => handleSelectChange('client', value)}
                                        disabled={isArchived}
                                    >
                                        <SelectTrigger className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}>
                                            <SelectValue placeholder="Seleziona committente" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            {clients.map((client) => (
                                                <SelectItem key={client._id} value={client._id}>
                                                    {client.typology === 'Private' ? client.fullName : client.companyName}
                                                </SelectItem>
                                            ))}
                                        </SelectContent>
                                    </Select>
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="workType">Tipologia di lavoro</Label>
                                    <Select
                                        name="workType"
                                        value={site.workType}
                                        onValueChange={(value) => handleSelectChange('workType', value)}
                                        disabled={isArchived}
                                    >
                                        <SelectTrigger className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}>
                                            <SelectValue placeholder="Seleziona tipologia" />
                                        </SelectTrigger>
                                        <SelectContent>
                                            <SelectItem value="Manutenzione ordinaria">Manutenzione ordinaria</SelectItem>
                                            <SelectItem value="Manutenzione straordinaria">Manutenzione straordinaria</SelectItem>
                                            <SelectItem value="Restauro">Restauro</SelectItem>
                                            <SelectItem value="Risanamento conservativo">Risanamento conservativo</SelectItem>
                                            <SelectItem value="Ristrutturazione edilizia">Ristrutturazione edilizia</SelectItem>
                                            <SelectItem value="Nuova costruzione">Nuova costruzione</SelectItem>
                                            <SelectItem value="Ristrutturazione urbanistica">Ristrutturazione urbanistica</SelectItem>
                                            <SelectItem value="Lavori stradali">Lavori stradali</SelectItem>
                                            <SelectItem value="Impianti fognari">Impianti fognari</SelectItem>
                                            <SelectItem value="Movimento terra">Movimento terra</SelectItem>
                                            <SelectItem value="Impiantistica">Impiantistica</SelectItem>
                                            <SelectItem value="Bonifica">Bonifica</SelectItem>
                                            <SelectItem value="Demolizione">Demolizione</SelectItem>
                                            <SelectItem value="Edilizia industrializzata">Edilizia industrializzata</SelectItem>
                                            <SelectItem value="Opere idrauliche">Opere idrauliche</SelectItem>
                                            <SelectItem value="Impianti idroelettrici">Impianti idroelettrici</SelectItem>
                                            <SelectItem value="Opere marittime">Opere marittime</SelectItem>
                                            <SelectItem value="Lavori ferroviari">Lavori ferroviari</SelectItem>
                                            <SelectItem value="Lavori di linee e condotte">Lavori di linee e condotte</SelectItem>
                                            <SelectItem value="Lavori al sottosuolo">Lavori al sottosuolo</SelectItem>
                                            <SelectItem value="Lavori speciali">Lavori speciali</SelectItem>
                                            <SelectItem value="Altro">Altro</SelectItem>
                                        </SelectContent>
                                    </Select>
                                </div>
                            </div>

                            <div className="space-y-2">
                                <Label htmlFor="description">Descrizione dei lavori</Label>
                                <Textarea
                                    id="description"
                                    name="description"
                                    value={site.description}
                                    onChange={handleInputChange}
                                    disabled={isArchived}
                                    className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}
                                    rows={3}
                                />
                            </div>

                            <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 gap-4">
                                <div className="space-y-2">
                                    <Label htmlFor="country">Nazione</Label>
                                    <Input
                                        id="country"
                                        name="country"
                                        value={site.country}
                                        onChange={handleInputChange}
                                        required
                                        disabled={isArchived}
                                        className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="city">Città</Label>
                                    <Input
                                        id="city"
                                        name="city"
                                        value={site.city}
                                        onChange={handleInputChange}
                                        required
                                        disabled={isArchived}
                                        className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="province">Provincia</Label>
                                    <Input
                                        id="province"
                                        name="province"
                                        value={site.province}
                                        onChange={handleInputChange}
                                        required
                                        disabled={isArchived}
                                        className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}
                                    />
                                </div>

                                <div className="space-y-2">
                                    <Label htmlFor="postalCode">CAP</Label>
                                    <Input
                                        id="postalCode"
                                        name="postalCode"
                                        value={site.postalCode}
                                        onChange={handleInputChange}
                                        required
                                        disabled={isArchived}
                                        className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}
                                    />
                                </div>

                                <div className="space-y-2 md:col-span-4">
                                    <Label htmlFor="address">Indirizzo</Label>
                                    <Input
                                        id="address"
                                        name="address"
                                        value={site.address}
                                        onChange={handleInputChange}
                                        required
                                        disabled={isArchived}
                                        className={isArchived ? "bg-gray-100 cursor-not-allowed" : ""}
                                    />
                                </div>
                            </div>

                            {!isArchived && (
                                <div className="flex justify-end space-x-2 mt-4">
                                    <Button
                                        type="button"
                                        onClick={() => navigate('/cantieri')}
                                        variant="outline"
                                    >
                                        Annulla
                                    </Button>
                                    <Button
                                        type="submit"
                                        disabled={isUpdating}
                                    >
                                        {isUpdating ? 'Aggiornamento...' : 'Salva'}
                                    </Button>
                                </div>
                            )}
                            {isArchived && (
                                <div className="flex justify-end mt-4">
                                    <Button
                                        type="button"
                                        onClick={() => navigate('/cantieri')}
                                        variant="outline"
                                    >
                                        Torna indietro
                                    </Button>
                                </div>
                            )}
                        </div>
                    </CardContent>
                </Card>
            </form>
        </div>
    );
}

export default Informazioni;