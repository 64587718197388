import React from "react";
import { useNavigate } from "react-router-dom";
import { useSubscription } from "../../../screens/auth/SubscriptionContext";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuGroup,
  DropdownMenuItem,
  DropdownMenuLabel,
  DropdownMenuSeparator,
  DropdownMenuTrigger,
} from "../../ui/dropdown-menu";
import {
  Avatar,
  AvatarFallback,
  AvatarImage,
} from "../../ui/avatar";
import { Button } from "../../ui/button";
import { ChevronDown, User, Settings, LogOut, Users, HelpCircle, Book, CreditCard, Calendar, Home, FileText, List } from "lucide-react";

const UserDropdown = ({ logoutHandle }) => {
  const navigate = useNavigate();
  const { subscription } = useSubscription();
  const userData = JSON.parse(localStorage.getItem('user') || '{}');
  
  // Determine if user should see subscription options
  const isAdmin = userData.role === 'admin';
  const isSubscriptionVisible = isAdmin;

  const openInNewTab = (url) => {
    window.open(url, '_blank', 'noopener,noreferrer');
  };

  return (
    <DropdownMenu>
      <DropdownMenuTrigger asChild>
        <Button variant="ghost" className="relative h-8 flex items-center space-x-2 hover:bg-gray-100 rounded-full">
          <Avatar className="h-8 w-8">
            <AvatarImage src="https://cdn.dribbble.com/users/3305260/screenshots/6628643/order-_fo59bc14fe01.jpg" alt={`${userData?.username} avatar`} />
            <AvatarFallback>{userData?.username?.charAt(0).toUpperCase()}</AvatarFallback>
          </Avatar>
          <span className="text-sm font-medium hidden md:flex">{userData?.username}</span>
          <ChevronDown className="h-4 w-4 text-gray-500" />
        </Button>
      </DropdownMenuTrigger>
      <DropdownMenuContent className="w-56" align="end" forceMount>
        <DropdownMenuLabel className="font-normal">
          <div className="flex flex-col space-y-1">
            <p className="text-sm font-medium leading-none">{userData?.username}</p>
            <p className="text-xs leading-none text-muted-foreground">{userData?.email}</p>
          </div>
        </DropdownMenuLabel>
        <DropdownMenuSeparator />
        <DropdownMenuGroup>
          <DropdownMenuItem onClick={() => navigate("/dashboard")}>
            <Home className="mr-2 h-4 w-4" />
            <span>Dashboard</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => navigate("/profilio")}>
            <User className="mr-2 h-4 w-4" />
            <span>Il mio profilo</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => navigate("/utenti")}>
            <Users className="mr-2 h-4 w-4" />
            <span>Utenti</span>
          </DropdownMenuItem>
          <DropdownMenuItem onClick={() => navigate("/assistenza")}>
            <HelpCircle className="mr-2 h-4 w-4" />
            <span>Assistenza</span>
          </DropdownMenuItem>
          {isSubscriptionVisible && (
            <DropdownMenuItem onClick={() => navigate("/subscription")}>
              <CreditCard className="mr-2 h-4 w-4" />
              <span>Abbonamento</span>
            </DropdownMenuItem>
          )}
          <DropdownMenuItem onClick={() => navigate("/impostazioni")}>
            <Settings className="mr-2 h-4 w-4" />
            <span>Impostazioni</span>
          </DropdownMenuItem>
        </DropdownMenuGroup>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => navigate("/calendar")}>
          <Calendar className="mr-2 h-4 w-4" />
          <span>Calendario</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => navigate("/calendar?view=agenda")}>
          <List className="mr-2 h-4 w-4" />
          <span>Agenda</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={() => openInNewTab("https://glds.it/docs")}>
          <Book className="mr-2 h-4 w-4" />
          <span>Guida Utente</span>
        </DropdownMenuItem>
        <DropdownMenuItem onClick={() => openInNewTab("https://glds.it/release-notes")}>
          <FileText className="mr-2 h-4 w-4" />
          <span>Release Notes</span>
        </DropdownMenuItem>
        <DropdownMenuSeparator />
        <DropdownMenuItem onClick={logoutHandle}>
          <LogOut className="mr-2 h-4 w-4" />
          <span>Esci</span>
        </DropdownMenuItem>
      </DropdownMenuContent>
    </DropdownMenu>
  );
};

export default UserDropdown;